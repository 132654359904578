import {Component, OnInit} from '@angular/core';
import {ApplicationService, Page} from '../services/application.service';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.scss']
})
export class CompleteComponent implements OnInit {
  currentPage: Page;
  firstPage: Page;
  text: string;
  title: string;
  loading = true;
  dataLoadCount = 0;

  constructor(public application: ApplicationService, public route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.application.pages.pipe(
      tap(pages => {
        this.currentPage = pages.find(x => x.item === this.route.routeConfig.path);
        this.application.activePage.next(this.currentPage.item);
        this.firstPage = pages.find(p => p.order === 1);
      })
    ).subscribe();

    //     this.application.pages = [];
    //
    //
    // Restangular.one('help/'+ this.application.applicationType +'/complete_header').get().then(function (response) {
    //     this.title = $sce.trustAsHtml(response.help_label);
    //     this.text = $sce.trustAsHtml(response.help_text);
    // });
  }


  updateApplication() {
    return
    //     this.loading = true;
    //     Restangular.one(this.currentPage.route, this.application.attributes.globalid).get().then(function (response) {
    //         this.application.isNew = false;
    //         this.application.loadPages(this.application.applicationType);
    //         this.application.attributes = response.result;
    //         // this.application.meta = response.meta;
    //         graphicsService.clearAll();
    //         // $state.go(this.application.pages[0].state);
    //
    //     }).finally(function () {
    //         this.loading = false;
    //     })
  };

  print() {
    // todo: fix
    // document.getElementById('output').contentWindow.print();
  }
  dataLoaded(e) {
    this.dataLoadCount += 1;
    if (this.dataLoadCount >= 2) {
      this.loading = false;
    }
  }
}
