import {EventEmitter, Inject, Injectable, Output} from '@angular/core';
import {BaseService, ErrorHandlingService} from "shared";
import {HttpClient} from "@angular/common/http";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {forkJoin, from, iif, Observable, of, ReplaySubject, Subject} from "rxjs";
import {LoadingComponent} from "../modals/loading/loading.component";
import {Router} from "@angular/router";
import {StandbyApplicationComponent} from "../modals/standby-application/standby-application.component";
import {finalize, switchMap, tap} from "rxjs/operators";
import {ResumeComponent} from '../modals/resume/resume.component';
import {LoginComponent} from '../modals/login/login.component';

export interface Page {
  name: string;
  item: string;
  // route: string;
  service: BaseService;
  order: number;
  display_fields?: any;
  additional_types?: string[];
  stateParams?: any;
  post_validation?: () => {},
  optional?: boolean;
  onItemChange?: (item: any, page: Page) => void
  getPrintUrl?: (id: string) => {}
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  applicationType: string;
  attributes: any = {};
  meta: any = {};
  pages: ReplaySubject<Page[]> = new ReplaySubject<Page[]>();
  activePage: ReplaySubject<string> = new ReplaySubject<string>();
  title: ReplaySubject<string> = new ReplaySubject<string>();
  isNew: boolean;
  standbyService: BaseService
  standbyAnnualService: BaseService

  modalComponents = {
    'standby': StandbyApplicationComponent,
    'standbyannualreport': LoginComponent
  }

  constructor(private http: HttpClient, private modalService: NgbModal, private errorHandling: ErrorHandlingService,
              private router: Router, @Inject('environment') private environment
  ) {
    this.standbyService = new BaseService(`${environment.baseUrl}/applications/standby`, this.http);
    this.standbyAnnualService = new BaseService(`${environment.baseUrl}/applications/standby/annualreports`, this.http);
  }

  loadPages(type) {
    const _pages: Page[] = []
    this.applicationType = type;
    if (type === 'standby') {
      _pages.push(
        {
          name: 'Generators',
          item: 'generators',
          service: new BaseService(`${this.environment.baseUrl}/applications/standby/generators`, this.http),
          order: 3,
          display_fields: {
            common: [
              'generator_make', 'generator_model', 'generator_serial_number', 'generator_plate',
              'date_of_manufacture', 'service_start_date', 'unit_identification', 'description',
              'fuel_type', 'fuel_consumption',
              'brake_horsepower', 'brake_kilowatts',
              'shape',
              'engine_plate', 'engine_make', 'engine_model', 'engine_serial_number',
              'engine_hours', 'picture',
              'tank_location', 'tank_type',
              'useAP42',
              'certificate',
              'document'
            ],
            total_unburned_hydrocarbons: ['total_unburned_hydrocarbons', 'toc_units'],
            benzene: ['benzene', 'benzene_units'],
            particulate_matter: ['particulate_matter', 'pm_units'],
            sulfur_dioxide: ['sulfur_dioxide', 'so2_units'],
            carbon_monoxide: ['carbon_monoxide', 'co_units'],
            nitrogen_oxides: ['nitrogen_oxide', 'no_units'],
            tank_size_internal: ['internal_tank_size'],
            tank_size_external: ['external_tank_size'],
            // nitrous_oxide: ['nitrous_oxide', 'nitrous_oxide_units'], //nitrous oxide (NO2) is not a regulated substance
            carbon_dioxide: ['carbon_dioxide', 'carbon_dioxide_units'],
            methane: ['methane', 'methane_units']
          },
          onItemChange: (item: any, page?: Page) => {
            // start with the common fields and add or remove based on choices made in the form
            item._meta.display_fields = page.display_fields['common']

            // add the appropriate tank size fields based on tank location
            if (item.tank_location.includes('external')) {
              item._meta.display_fields = item._meta.display_fields.toSpliced(
                item._meta.display_fields.indexOf('tank_location') + 1,
                0,
                page.display_fields['tank_size_external']
              )
            }
            if (item.tank_location.includes('internal') || item.tank_location == undefined) {
              item._meta.display_fields = item._meta.display_fields.toSpliced(
                item._meta.display_fields.indexOf('tank_location') + 1,
                0,
                page.display_fields['tank_size_internal']
              )
            }

            // add/remove emission factors
            if (item.useAP42) {
              // AP42 default values will be used, don't add any emissions factor fields
              // remove document from display fields since it's not required if using AP42
              item._meta.display_fields = item._meta.display_fields.filter(item => item !== 'document')
            } else if (!item.useAP42) {
              // Not using AP42, add necessary emissions factors based on fuel/etc
              // fuel type is undefined by default, but diesel is selected by default, include undefined to ensure correct fields are shown for diesel
              if (['gasoline', 'diesel', undefined].includes(item.fuel_type)) {
                item._meta.display_fields = item._meta.display_fields.concat(
                  page.display_fields['total_unburned_hydrocarbons'],
                  page.display_fields['carbon_monoxide']
                )
                if (item.fuel_type == 'diesel' && item.brake_horsepower >= 600) {
                  item._meta.display_fields = item._meta.display_fields.concat(
                    page.display_fields['benzene']
                  )
                }
              } else if (['propane', 'butane'].includes(item.fuel_type)) {
                item._meta.display_fields = item._meta.display_fields.concat(
                  page.display_fields['total_unburned_hydrocarbons'],
                  page.display_fields['carbon_dioxide'],
                  page.display_fields['methane'],
                  page.display_fields['nitrogen_oxides']
                )
              }
            }
          }
        },
        {
          name: 'Owner/Contact Info',
          item: 'contacts',
          service: new BaseService(`${this.environment.baseUrl}/applications/standby/contacts`, this.http),
          order: 1,
          display_fields: {
            individual: [
              'relationship_type', 'contact_contact_type', 'contact_first_name', 'contact_last_name', 'contact_title',
              'contact_division_name', 'contact_phone_number', 'contact_alternate_phone',
              'contact_email_address'
            ],
            organization: [
              'relationship_type', 'contact_contact_type', 'contact_org_name',
              'contact_phone_number', 'contact_alternate_phone', 'contact_email_address'
            ]
          },
          onItemChange: (item: any, page?: Page) => {
            if (item.contact_contact_type) {
              // copy the page display_fields to the item _meta so each item can display different fields
              item._meta.display_fields = page.display_fields[item.contact_contact_type];
            }
          },
          additional_types: ['additional']
        },
        {
          name: 'Facility Info',
          item: 'site',
          service: new BaseService(`${this.environment.baseUrl}/applications/facilities`, this.http),
          order: 2,
          display_fields: [
            'name', 'streetaddress', 'village', 'location_description', 'classification', 'attachment',
            'shape'
          ]
        },
        {
          name: 'Insignificant Sources',
          item: 'insignificant_sources',
          service: new BaseService(`${this.environment.baseUrl}/applications/standby/insignificant_sources`, this.http),
          order: 4,
          display_fields: [
            'id', 'type', 'description'
          ]
        },
        {
          name: 'Review',
          item: 'review',
          service: this.standbyService,
          order: 5
        },
        {
          name: 'Complete',
          item: 'complete',
          service: this.standbyService,
          getPrintUrl: (id) => `${this.environment.baseUrl}/applications/standby/${id}/print_application/`,
          order: 6
        }
      );
      this.meta.fields.site.fields.shape.label_field = 'name';
      this.meta.fields.generators.child.fields.shape.label_field = 'unit_identification';
      this.title.next('Standby Generator Permit Application');
    } else if (type === 'standbyannualreport') {
      this.title.next('Standby Generator Annual Reporting');
      _pages.push({
          name: 'Generators',
          order: 1,
          item: 'generators',
          service: new BaseService(`${this.environment.baseUrl}/applications/standby/generatorannualreports`, this.http),
          // display_fields: [
          //       'generator_make', 'generator_model', 'generator_serial_number', 'generator_plate',
          //       'date_of_manufacture', 'service_start_date', 'unit_identification', 'description',
          //       'fuel_type', 'fuel_consumption',
          //       'brake_horsepower', 'brake_kilowatts',
          //       'shape',
          //       'engine_plate', 'engine_make', 'engine_model', 'engine_serial_number',
          //       'engine_hours', 'picture',
          //       'tank_location', 'tank_type',
          //       'certificate',
          //       'document'
          //     ],
          display_fields: ['date_of_manufacture', 'unit_identification', 'description',
            'generator_make', 'generator_model', 'generator_serial_number',
            'engine_make', 'engine_model', 'engine_serial_number', 'previous_engine_hours', 'engine_hours', 'engine_hours_picture'],
          onItemChange: (item: any, page: Page) => {
            item._meta.display_fields = page.display_fields;
          }
        },
        {
          name: 'Review',
          item: 'review',
          service: this.standbyAnnualService,
          order: 2
        },
        {
          name: 'Complete',
          item: 'complete',
          service: new BaseService(`${this.environment.baseUrl}/applications/standby/annualreports`, this.http),
          order: 3,
          getPrintUrl: (id) => `${this.environment.baseUrl}/applications/standby/annualreports/${id}/print_application/`,

        });
    }
    // else if (type === 'pesticides_noa') {
    //   this.title = 'Pesticides Notice of Arrival';
    //   this.pages.push({
    //       name: 'Facility Info',
    //       state: 'root.facility',
    //       item: 'facility',
    //       route: 'facilities',
    //       service: new BaseService(`${this.environment.baseUrl}/applications/facilities`, this.http),
    //       order: 1,
    //       display_fields: [
    //         'name', 'streetaddress', 'village', 'location_description', 'classification', 'attachment',
    //         'shape'
    //       ]
    //     },
    //     {
    //       name: 'Owner/Contact Info',
    //       state: 'root.contacts',
    //       item: 'contacts',
    //       route: 'pesticides/noa_contacts',
    //       service: new BaseService(`${this.environment.baseUrl}/applications/standby/annualreports`, this.http),
    //       order: 2,
    //       display_fields: [
    //         'relationship_type', 'contact_first_name', 'contact_last_name', 'contact_title', 'contact_contact_type',
    //         'contact_division_name', 'contact_phone_number', 'contact_alternate_phone',
    //         'contact_email_address'
    //       ],
    //       additional_types: ['other']
    //     },
    //     {
    //       name: 'Notice of Arrival',
    //       state: 'root.noa',
    //       item: 'noa',
    //       route: 'pesticides/noa',
    //       order: 3,
    //       display_fields: ['products_file', 'origin', 'port_of_entry',
    //         'carrier', 'entry_number', 'entry_date', 'entry_date_estimated', 'marks', 'location', 'invoice_number', 'invoice_date', 'invoice_place',
    //         'confidential', 'importer_remarks', 'action', 'action_other',
    //         'vessel_id', 'vessel_eta'],
    //       post_validation: () => {
    //         return Restangular.all('pesticides/noa_products').getList().then(function (response) {
    //           this.attributes.noa_products = response;
    //         });
    //       }
    //     },
    //     {
    //       name: 'Notice of Arrival Products',
    //       state: 'root.noa_products',
    //       item: 'noa_products',
    //       route: 'pesticides/noa_products',
    //       order: 4,
    //       display_fields: ['epa_registration_number', 'product_name', 'epa_status', 'container', 'sku', 'epa_establishment_number',
    //         'ingredients', 'unit_size', 'quantity', 'cases', 'remarks']
    //     },
    //     {
    //       name: 'Review',
    //       state: 'root.review',
    //       route: 'pesticides/noaapplications',
    //       order: 1000
    //     },
    //     {
    //       name: 'Complete',
    //       state: 'root.complete',
    //       route: 'pesticides/noaapplications',
    //       order: 1001
    //     }
    //   );
    // } else if (type === 'onestop') {
    //   this.title = 'One Stop Permitting';
    //   this.pages.push({
    //       name: 'One Stop Permit Info',
    //       state: 'root.one_stop',
    //       item: 'permit',
    //       route: 'onestop/applications',
    //       display_fields: [
    //         'type', 'project_description', 'dpw_permit_number',
    //         'type_of_sewage'
    //       ],
    //       order: 3
    //     }, {
    //       name: 'Owner/Contact Info',
    //       state: 'root.contacts',
    //       item: 'contacts',
    //       route: 'onestop/contacts',
    //       order: 2,
    //       display_fields: [
    //         'relationship_type', 'contact_first_name', 'contact_last_name', 'contact_title', 'contact_contact_type',
    //         'contact_division_name', 'contact_phone_number', 'contact_alternate_phone',
    //         'contact_email_address'
    //       ],
    //       additional_types: ['additional']
    //     },
    //     {
    //       name: 'Facility Info',
    //       state: 'root.facility',
    //       item: 'facility',
    //       route: 'facility',
    //       order: 1,
    //       display_fields: [
    //         'name', 'streetaddress', 'village', 'location_description', 'classification', 'shape'
    //       ]
    //     },
    //     {
    //       name: 'Review',
    //       state: 'root.review',
    //       route: 'onestop/applications',
    //       order: 1000
    //     });
    // }

    this.pages.next(_pages.sort((a: Page, b: Page) => a.order - b.order));
  }

  getPages() {
    return this.pages;
  }

  startNew(type, id?) {
    this.applicationType = type;
    let promises = [];
    // promises.push(Restangular.one('facility', 'metadata').get().then(function (meta) {
    //     this.meta.facility = meta;
    //     this.attributes.facility = {shape: {}};
    // }));
    // promises.push(Restangular.one('contact', 'metadata').get().then(function (meta) {
    //     this.meta.contacts = meta;
    //     this.attributes.contacts = [{}];
    // }));
    // promises.push(Restangular.one('organization', 'metadata').get().then(function (meta) {
    //     this.meta.organizations = meta;
    //     this.attributes.organizations = [{}];
    // }));
    if (type === 'standby') {
      let modalInstance = this.modalService.open(StandbyApplicationComponent, {backdrop: 'static'});

      promises.push(modalInstance.result);

      promises.push(this.standbyService.get<any>('start').toPromise().then(meta => {
        this.meta = meta;
        this.meta.fields.site.fields.shape.label_field = 'name';
        this.meta.fields.generators.child.fields.shape.label_field = 'unit_identification';
        this.attributes = {
          site: {coordinates: {}, shape: {}},
          contacts: meta.fields.contacts.child.fields.relationship_type.choices
            .filter(function (type) {
              return type.value !== 'additional';
            }).map(function (type) {
              return {relationship_type: type.value};
            }),
          generators: [{shape: {}, coordinates: {}}],
          insignificant_sources: []
        };
      }));

      modalInstance.result.then(startInfo => {
        // $window.open('/help?item=' + type + '/load', 'help', 'height=900,width=450');
        this.isNew = true;
        // LoadingModal.open('Loading Application');
        this.attributes.type = startInfo.newOrRenewal;
        this.attributes.old_permit_number = startInfo.old_permit_number ? startInfo.old_permit_number : '';
      });

      // this.pages.push(
      //     {
      //         name: 'Generators',
      //         state: 'root.generators',
      //         item: 'generators',
      //         route: 'standby/generators',
      //         order: 4,
      //         display_fields: [
      //             'date_of_manufacture', 'unit_identification', 'description', 'total_unburned_hydrocarbons',
      //             'toc_units', 'particulate_matter', 'pm_units', 'sulfur_dioxide', 'so2_units',
      //             'carbon_monoxide', 'co_units', 'nitrogen_oxide', 'no_units', 'fuel_type', 'output_number',
      //             'output_unit', 'generator_make', 'generator_model', 'generator_serial_number',
      //             'engine_make', 'engine_model', 'engine_serial_number', 'engine_hours', 'picture', 'document'
      //         ]
      //     },
      //     {
      //         name: 'Tanks',
      //         state: 'root.tanks',
      //         item: 'tanks',
      //         route: 'standby/tanks',
      //         optional: true,
      //         order: 5,
      //         display_fields: [
      //             'name', 'number', 'capacity', 'capacity_units', 'diameter', 'height', 'length', 'width',
      //             'tank_shape', 'tank_shape_description', 'material', 'material_description', 'paint', 'status', 'type'
      //         ]
      //     });
      // this.title = 'Standby Generator Permit Application';
    } else if (type === 'standbyannualreport') {

      let loadingDeferred = new Promise((resolve, reject) => {
        this.standbyService.getList().toPromise().then((response) => {
          this.meta = response.meta;

          let modalInstance = this.modalService.open({
            templateUrl: '/static/modals/standbyAnnualYearChoices.html',
            controller: function ($uibModalInstance, $scope, $filter) {
              $scope.annual_reports = response;
              $scope.confirm = function () {
                this.attributes = $filter('filter')(response, {reporting_year: $scope.selected_year})[0];
                resolve();
                modalInstance.close();
              };
            },
            backdrop: 'static'
          });
        });
      });
      promises.push(loadingDeferred);

    }
    // else if (type === 'pesticides_noa') {
    // var loadingDeferred = new Promise((resolve, reject) => {
    //   this.http.get<any>(`${this.environment.baseUrl}/applications/pesticides/noaapplications/start`).toPromise()
    //     .then(function (meta) {
    //       this.meta = meta;
    //       this.attributes = {
    //         facility: {coordinates: {}, shape: {}},
    //         noa: {},
    //         noa_products: [{}],
    //         contacts: meta.fields.contacts.child.fields.relationship_type.choices
    //           .filter(function (type) {
    //             return type.value !== 'other';
    //           }).map(function (type) {
    //             return {relationship_type: type.value};
    //           })
    //       };
    //       this.isNew = true;
    //       var deferred = $q.defer();
    //       this.meta.fields.noa_products.child.fields.epa_registration_number.choices = [];
    //       this.meta.fields.noa_products.child.fields.epa_registration_number.get_choices = function (search) {
    //         if (!angular.equals({}, search)) {
    //           // used to cancel previous request
    //           deferred.resolve([]);
    //           // and start a new one
    //           deferred = $q.defer();
    //
    //           $http.get('https://ofmpub.epa.gov/apex/pesticides/ppls/' + search, {timeout: deferred.promise})
    //             .then(function (response) {
    //               var choices = response.data.items.map(function (item) {
    //                 return {
    //                   display_name: item.productname + ' (' + item.eparegno + ')',
    //                   value: item.eparegno,
    //                   orig_item: item
    //                 };
    //               });
    //               deferred.resolve(choices);
    //             });
    //         } else {
    //           deferred.resolve([]);
    //         }
    //         return deferred.promise;
    //       };
    //       this.meta.fields.noa_products.child.fields.epa_registration_number.select_choice = function (selected, item) {
    //         item.epa_registration_number = selected.orig_item.eparegno;
    //         item.product_name = selected.orig_item.productname;
    //         item.epa_status = selected.orig_item.product_status;
    //         item.ingredients = selected.orig_item.active_ingredients.map(function (ingredient) {
    //           return ingredient.active_ing + ' (' + ingredient.cas_number + ')';
    //         }).join(', ');
    //         var t = '';
    //         var p = '';
    //         for (var pdf in selected.orig_item.pdffiles) {
    //           var test_date = new Date(selected.orig_item.pdffiles[pdf].pdffile_accepted_date);
    //           if (t === '' || t < test_date) {
    //             t = test_date;
    //             p = selected.orig_item.pdffiles[pdf].pdffile;
    //           }
    //         }
    //         item.pdf_file = 'https://www3.epa.gov/pesticides/chem_search/ppls/' + p;
    //       };
    //
    //       loadingDeferred.resolve();
    //       // var modalInstance = $uibModal.open({
    //       //   templateUrl: '/static/modals/standbyAnnualYearChoices.html',
    //       //   controller: function ($uibModalInstance, $scope, $filter) {
    //       //     $scope.annual_reports = response;
    //       //     $scope.confirm = function () {
    //       //       this.attributes = $filter('filter')(response, {reporting_year: $scope.selected_year})[0];
    //       //       loadingDeferred.resolve();
    //       //       modalInstance.close();
    //       //     };
    //       //   },
    //       //   backdrop: 'static'
    //       // });
    //
    //
    //     });
    // })
    // promises.push(loadingDeferred);

    // } else if (type === 'onestop') {
    //   var loadingDeferred = $q.defer();
    //   promises.push(loadingDeferred.promise);
    //   Restangular.one('onestop/applications', 'start').get().then(function (meta) {
    //     this.meta = meta;
    //     this.attributes = {
    //       facility: {coordinates: {}, shape: {}},
    //       permit: {},
    //       contacts: meta.fields.contacts.child.fields.relationship_type.choices
    //         .map(function (type) {
    //           return {relationship_type: type.value};
    //         })
    //     };
    //     this.isNew = true;
    //
    //     loadingDeferred.resolve();
    //
    //   });
    // }
    // let loadingModalInstance;
    return forkJoin(promises).pipe(
      tap(() => {
        const loadingModalInstance = this.modalService.open(LoadingComponent, {backdrop: 'static'});
        loadingModalInstance.componentInstance.message = 'Loading Application';
        let _activePage = this.pages[0].item;
        this.loadPages(type);

        this.activePage.next(_activePage);
        this.router.navigate(['application', _activePage]);
      }),
      //   finalize(() => {
      //   loadingModalInstance.close(1500);
      // })
    ).subscribe();
  }

  updateApplication(type, id) {
    return new Promise((resolve, reject) => {
      let route;
      let service;
      if (type === 'standby') service = new BaseService(`${this.environment.baseUrl}/applications/standby`, this.http);
      // else if (type === 'pesticides_noa') route = 'pesticides/noaapplications';

      service.get(id).toPromise().then(response => {
        if (!response) {
          reject('Please check application number and access code.');
        } else {
          this.isNew = false;
          this.attributes = response.result;
          this.meta = response.meta;
          this.loadPages(type);

          let _activePage = this.pages[0].item;
          this.activePage.next(_activePage);
          resolve(this.activePage);
        }
      }).catch(function () {
        reject('Please check application number and access code.');
      });
    });
  }

  submit(currentPage) {
    return new Promise((resolve, reject) => {
      var attributes: any = {};
      // var currentAttributes = this.attributes.restangularized === undefined ? this.attributes : this.attributes.plain();
      Object.keys(this.attributes).forEach(key => {
        if (Array.isArray(this.attributes[key])) {
          attributes[key] = this.attributes[key].map(function (obj) {
            return obj.globalid
          });
        } else if (this.attributes[key].hasOwnProperty('globalid')) {
          attributes[key] = this.attributes[key].globalid;
        } else {
          attributes[key] = this.attributes[key];
        }
      });
      // var attributes = {
      //     facility: this.attributes.facility.globalid,
      //     contacts: this.attributes.contacts.map(function (obj) {
      //         return obj.globalid;
      //     }),
      //     generators: this.attributes.generators.map(function (obj) {
      //         return obj.globalid;
      //     }),
      //     organizations: this.attributes.organizations.globalid,
      //     type: this.attributes.type
      // };
      if (this.attributes.globalid !== undefined) {
        attributes.globalid = this.attributes.globalid;
      }

      // not using restangular now
      // if (attributes.restangularized === undefined) {
      //   attributes = Restangular.restangularizeElement(undefined, attributes, currentPage.route);
      //   attributes.fromServer = (!this.isNew && attributes.globalid !== undefined);
      // }

      // only submit global ids of everything that was already saved

      currentPage.service.save(attributes).then((item) => {
        this.attributes = item;
        resolve();
      }).catch(function (error) {
        reject();
        // errorHandling.applicationError(error, this.attributes);
      });
    })
    // errorHandling.clearError(this.attributes);
    // this.attributes.facility.contacts = this.attributes.contacts;
    // this.attributes.facility.organizations = this.attributes.organizations;


    // var results = [];
    // // Restangular.all('facility/standby_application').post(serviceObj);
    // var facilityDeferred = $q.defer(), generatorDeferred = $q.defer();
    // var promises = [facilityDeferred.promise, generatorDeferred.promise];
    // // this.facility = Restangular.restangularizeElement(undefined, this.facility, 'facility');
    // Restangular.all('facility').post(this.facility).then(function (response) {
    //      results.push(response);
    //     var org_promises = [];
    //      angular.forEach(this.organizations, function (org) {
    //          org.site = response.globalid;
    //          org_promises.push(Restangular.all('organization').post(org).then(function (org_response){
    //              results.push(org_response)
    //          }));
    //      });
    //     $q.all(org_promises).then(function () {
    //         var contact_promises = [];
    //         angular.forEach(this.contacts, function (contact) {
    //             contact.site = response.globalid;
    //             contact_promises.push(Restangular.all('contact').post(contact).then(function (contact_response) {
    //                 results.push(contact_response);
    //             }));
    //         });
    //         $q.all(contact_promises).then(function () {
    //             if (this.applicationType === 'standby') {
    //                 angular.forEach(this.generators, function (generator) {
    //                     generator.site = response.globalid;
    //                     Restangular.all('standby/generators').post(generator).then(function (gen_response) {
    //                         results.push(gen_response);
    //                         var fileupload = {
    //                             data: generator.attachment.base64,
    //                             content_type: generator.attachment.filetype,
    //                             file_name: generator.attachment.filename,
    //                             data_size: generator.attachment.filesize,
    //                             rel_globalid: gen_response.globalid
    //                         };
    //
    //                         promises.push(Restangular.all('standby/generatorsattach').post(fileupload));
    //                         var tank_promises = [];
    //                         angular.forEach(this.tanks, function (tank) {
    //                             tank.site = response.globalid;
    //                             tank_promises.push(Restangular.all('standby/tanks').post(tank).then(function (tank_response) {
    //                                 results.push(tank_response);
    //                             }));
    //                         });
    //                         $q.all(tank_promises).catch(function () {
    //                             angular.forEach(results, function (result) {
    //                                 result.remove();
    //                             })
    //                         })
    //                     }).catch(function () {
    //                         angular.forEach(results, function (result) {
    //                             result.remove();
    //                         })
    //                     });
    //                 });
    //
    //             }
    //         }).catch(function () {
    //             angular.forEach(results, function (result) {
    //                 result.remove();
    //             })
    //         })
    //     }).catch(function () {
    //         angular.forEach(results, function (result) {
    //             result.remove();
    //         })
    //     });
    //
    //
    //
    //     facilityDeferred.resolve();
    // }).catch(function () {
    //     facilityDeferred.reject();
    // });
    //
    // $q.all(promises).then(function () {
    //     console.log('done!');
    // }).catch(function () {
    //     angular.forEach(results, function (result) {
    //         result.remove();
    //     })
    // });
  }

  submitApplication(currentPage) {
    currentPage.service
  }

  validate(currentPage) {
    return new Promise((resolve, reject) => {
      let post_validation;
      if (currentPage.hasOwnProperty('post_validation')) {
        post_validation = currentPage.post_validation;
      } else {
        post_validation = function () {
          return new Promise((_resolve) => {
            _resolve();
          })
        };
      }

      if (Array.isArray(this.attributes[currentPage.item])) {
        let promises = [], scrollToHash;

        this.attributes[currentPage.item].forEach((item, index) => {
          // if (item.restangularized === undefined) {
          //   Restangular.restangularizeElement(null, item, currentPage.route);
          //   item.fromServer = (!this.isNew && item.globalid !== undefined);
          // }

          // clear errors
          this.errorHandling.clearError(this.attributes[currentPage.item][index]._meta)
          let _deferred = new Promise((_resolve, _reject) => {
            currentPage.service.save(item).toPromise().then((data) => {
              this.attributes[currentPage.item][index] = {...item, ...data};
              // item = {...item, ...data};
              // item.fromServer = true;
              _resolve(item);
            })
              .catch((error) => {
                _reject(error);
                // todo: fix with routing
                // if (scrollToHash === undefined) {
                //   scrollToHash = Object.keys(error.data)[0] + '_' + index;
                // }

                this.errorHandling.fieldError(error, this.attributes[currentPage.item][index]._meta);
              });
          })
          promises.push(_deferred);

        });
        forkJoin(promises).toPromise()
          .then(function () {
            resolve();
          }).catch(function (errors) {
          reject();
          setTimeout(function () {
            // todo: fix with routing since anchors are respected
            // $anchorScroll.yOffset = 10;
            // $anchorScroll(scrollToHash);
          }, 1000);
        });
      } else {
        // angular.forEach(this.meta.fields[currentPage.item].fields, function (obj, key) {
        //     if (obj.type === 'attachment') {
        //         this.attributes[currentPage.item][key] = this.attributes[currentPage.item][key].globalid
        //     }
        // });
        this.attributes[currentPage.item].errors = {};
        // todo: see if this is still needed can we just check for globalid presence and do post/put... baseservice already does this?
        // if (this.attributes[currentPage.item].restangularized === undefined) {
        //   Restangular.restangularizeElement(undefined, this.attributes[currentPage.item], currentPage.route);
        //   this.attributes[currentPage.item].fromServer = !this.isNew;
        // }
        currentPage.service.save(this.attributes[currentPage.item]).toPromise()
          .then((data) => {
            // this.attributes[currentPage.item] = data;
            this.attributes[currentPage.item] = {...this.attributes[currentPage.item], ...data};
            // this.attributes[currentPage.item].fromServer = true;
            post_validation().finally(function () {
              resolve();
            });
          })
          .catch((error) => {
            reject();
            setTimeout(() => {
              // todo: see anchorscroll above
              // $anchorScroll.yOffset = 10;
              // $anchorScroll(Object.keys(error.data)[0]);
            }, 1000);
            this.errorHandling.fieldError(error, this.meta.fields[currentPage.item]);
          });
      }
    })


  }

  showLoadingModal() {
    const loadingModalInstance = this.modalService.open(LoadingComponent, {backdrop: 'static'});
    loadingModalInstance.componentInstance.message = 'Loading Application';
    return loadingModalInstance;
  }

  async startApplication(program: string) {
    const modalComponent = this.modalComponents[program];
    let resume = false;
    if (document.cookie.indexOf('permittingtoken=') > -1) {
      await this.modalService.open(ResumeComponent).result.then(r => {
        if (r) {
          resume = true;
          this.loadApplication(program, true);
        }
      })
      if (resume) {
        return
      }
    }
    this.modalService.open(modalComponent).result.then(r => {
      this.loadApplication(program, false, r)
    });
  }

  loadApplication(program: string, resume = false, initValues = null) {
    let loadingModal: NgbModalRef = this.showLoadingModal();
    // todo: , resume = false, type: string add back here
    const promises = []
    if (program === 'standby') {
      promises.push(
        iif(
          () => resume,
          this.standbyService.get('self'),
          this.standbyService.post<any>({...initValues})
        ).pipe(tap(response => {
          this.meta = response.meta;
          this.meta.fields.site.fields.shape.label_field = 'name';
          this.meta.fields.generators.child.fields.shape.label_field = 'unit_identification';
          // if (!resume) {
          // this.attributes = {
          //   site: {coordinates: {}, shape: {}},
          //   contacts: response.meta.fields.contacts.child.fields.relationship_type.choices
          //     .filter(function (type) {
          //       return type.value !== 'additional';
          //     }).map(function (type) {
          //       return {relationship_type: type.value};
          //     }),
          //   generators: [{shape: {}, coordinates: {}}],
          //   insignificant_sources: []
          // };
          // } else {
          this.attributes = response.result
          // }
        })));
      //
      // modalInstance.result.then(startInfo => {
      //   // $window.open('/help?item=' + type + '/load', 'help', 'height=900,width=450');
      //   this.isNew = true;
      //   // LoadingModal.open('Loading Application');
      //   this.attributes.type = startInfo.newOrRenewal;
      //   this.attributes.old_permit_number = startInfo.old_permit_number ? startInfo.old_permit_number : '';
      // });

      // this.pages.push(
      //     {
      //         name: 'Generators',
      //         state: 'root.generators',
      //         item: 'generators',
      //         route: 'standby/generators',
      //         order: 4,
      //         display_fields: [
      //             'date_of_manufacture', 'unit_identification', 'description', 'total_unburned_hydrocarbons',
      //             'toc_units', 'particulate_matter', 'pm_units', 'sulfur_dioxide', 'so2_units',
      //             'carbon_monoxide', 'co_units', 'nitrogen_oxide', 'no_units', 'fuel_type', 'output_number',
      //             'output_unit', 'generator_make', 'generator_model', 'generator_serial_number',
      //             'engine_make', 'engine_model', 'engine_serial_number', 'engine_hours', 'picture', 'document'
      //         ]
      //     },
      //     {
      //         name: 'Tanks',
      //         state: 'root.tanks',
      //         item: 'tanks',
      //         route: 'standby/tanks',
      //         optional: true,
      //         order: 5,
      //         display_fields: [
      //             'name', 'number', 'capacity', 'capacity_units', 'diameter', 'height', 'length', 'width',
      //             'tank_shape', 'tank_shape_description', 'material', 'material_description', 'paint', 'status', 'type'
      //         ]
      //     });
      // this.title = 'Standby Generator Permit Application';
    } else if (program === 'standbyannualreport') {
      promises.push(
        iif(
          () => resume,
          this.standbyAnnualService.get('self'),
          this.standbyAnnualService.post<any>({...initValues})
        ).pipe(tap(response => {
            this.attributes = response.result
            this.meta = response.meta;
          })
        )
      );
    }
    // else if (type === 'pesticides_noa') {
    //   var loadingDeferred = new Promise((resolve, reject) => {
    //     this.http.get<any>(`${environment.base_url}/applications/pesticides/noaapplications/start`).toPromise()
    //       .then(function (meta) {
    //         this.meta = meta;
    //         this.attributes = {
    //           facility: {coordinates: {}, shape: {}},
    //           noa: {},
    //           noa_products: [{}],
    //           contacts: meta.fields.contacts.child.fields.relationship_type.choices
    //             .filter(function (type) {
    //               return type.value !== 'other';
    //             }).map(function (type) {
    //               return {relationship_type: type.value};
    //             })
    //         };
    //         this.isNew = true;
    //         var deferred = $q.defer();
    //         this.meta.fields.noa_products.child.fields.epa_registration_number.choices = [];
    //         this.meta.fields.noa_products.child.fields.epa_registration_number.get_choices = function (search) {
    //           if (!angular.equals({}, search)) {
    //             // used to cancel previous request
    //             deferred.resolve([]);
    //             // and start a new one
    //             deferred = $q.defer();
    //
    //             $http.get('https://ofmpub.epa.gov/apex/pesticides/ppls/' + search, {timeout: deferred.promise})
    //               .then(function (response) {
    //                 var choices = response.data.items.map(function (item) {
    //                   return {
    //                     display_name: item.productname + ' (' + item.eparegno + ')',
    //                     value: item.eparegno,
    //                     orig_item: item
    //                   };
    //                 });
    //                 deferred.resolve(choices);
    //               });
    //           } else {
    //             deferred.resolve([]);
    //           }
    //           return deferred.promise;
    //         };
    //         this.meta.fields.noa_products.child.fields.epa_registration_number.select_choice = function (selected, item) {
    //           item.epa_registration_number = selected.orig_item.eparegno;
    //           item.product_name = selected.orig_item.productname;
    //           item.epa_status = selected.orig_item.product_status;
    //           item.ingredients = selected.orig_item.active_ingredients.map(function (ingredient) {
    //             return ingredient.active_ing + ' (' + ingredient.cas_number + ')';
    //           }).join(', ');
    //           var t = '';
    //           var p = '';
    //           for (var pdf in selected.orig_item.pdffiles) {
    //             var test_date = new Date(selected.orig_item.pdffiles[pdf].pdffile_accepted_date);
    //             if (t === '' || t < test_date) {
    //               t = test_date;
    //               p = selected.orig_item.pdffiles[pdf].pdffile;
    //             }
    //           }
    //           item.pdf_file = 'https://www3.epa.gov/pesticides/chem_search/ppls/' + p;
    //         };
    //
    //         loadingDeferred.resolve();
    //         // var modalInstance = $uibModal.open({
    //         //   templateUrl: '/static/modals/standbyAnnualYearChoices.html',
    //         //   controller: function ($uibModalInstance, $scope, $filter) {
    //         //     $scope.annual_reports = response;
    //         //     $scope.confirm = function () {
    //         //       this.attributes = $filter('filter')(response, {reporting_year: $scope.selected_year})[0];
    //         //       loadingDeferred.resolve();
    //         //       modalInstance.close();
    //         //     };
    //         //   },
    //         //   backdrop: 'static'
    //         // });
    //
    //
    //       });
    //   })
    //   promises.push(loadingDeferred);
    // }
    // else if (type === 'onestop') {
    //   var loadingDeferred = $q.defer();
    //   promises.push(loadingDeferred.promise);
    //   Restangular.one('onestop/applications', 'start').get().then(function (meta) {
    //     this.meta = meta;
    //     this.attributes = {
    //       facility: {coordinates: {}, shape: {}},
    //       permit: {},
    //       contacts: meta.fields.contacts.child.fields.relationship_type.choices
    //         .map(function (type) {
    //           return {relationship_type: type.value};
    //         })
    //     };
    //     this.isNew = true;
    //
    //     loadingDeferred.resolve();
    //
    //   });
    // }
    return forkJoin(promises).pipe(
      tap(() => this.loadPages(program)),
      switchMap(() => this.pages),
      tap(pages => {
        let _activePage = pages[0].item;
        this.activePage.next(_activePage);
        loadingModal.close()
        this.router.navigate(['application', program, _activePage]);
      })).subscribe();
  }
}
