import {Component, Inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseService} from '../services/base.service';
import {HttpClient} from '@angular/common/http';
import {iif, Observable, ReplaySubject} from 'rxjs';

@Component({
  selector: 'app-status-input',
  templateUrl: './status-input.component.html',
  styleUrls: ['./status-input.component.css']
})
export class StatusInputComponent implements OnInit, OnChanges {
  @Input() viewMode: boolean;
  @Input() id: string;

  public status: Observable<any> = new Observable<any>();

  statusService: BaseService;

  constructor(http: HttpClient, @Inject('environment') private environment) {
    this.statusService = new BaseService(`${environment.baseUrl}/status`, http);
  }

  ngOnInit(): void {
    if (this.id !== 'new') {
      this.getStatus();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.viewMode) {
      if (changes.viewMode.currentValue !== changes.viewMode.previousValue) {
        if (changes.viewMode.currentValue === false && this.id !== 'new') {
          this.getStatus();
        }
      }
    }
  }

  private getStatus() {
    this.status = this.statusService.get(this.id);
  }

  // get status on load


  public updateStatus(status) {
    status.status = 'approved';
    iif(() => status.record,
      this.statusService.put(status.record, status),
      this.statusService.post(status)).subscribe();
  }


}
