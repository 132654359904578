import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {ErrorHandlingService} from '../../services/error-handling.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HistoryService} from '../../services/history.service';
import {BaseModalComponent} from '../base-modal/base-modal.component';
import {HttpClient} from '@angular/common/http';
import {ToastService} from '../../services/toast.service';
import {MapService} from '../../services/map.service';

@Component({
  selector: 'app-dynamic-modal',
  templateUrl: './dynamic-modal.component.html',
  styleUrls: ['./dynamic-modal.component.css']
})
export class DynamicModalComponent extends BaseModalComponent {
  constructor(http: HttpClient, toastService: ToastService, errorHandling: ErrorHandlingService,
              activeModal: NgbActiveModal, mapService: MapService, history: HistoryService,
              @Inject('environment') private e) {
    super(http, toastService, errorHandling, activeModal, mapService, history, e);
  }
}
