import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class FileUploadServiceService {
  files = {};
  filesUploading = 0;
  uploading = false;
  loadingPromises = [];

  constructor(private http: HttpClient,
    @Inject('environment') private environment) {
  }

  public uploadFile(file, type) {
    this.filesUploading += 1;
    this.uploading = true;
    // file = Restangular.restangularizeElement(undefined, file, type + '/attach');
    var fileDeferred = new Promise((resolve, reject) => {
      this.http.post<any>(`${this.environment.baseUrl}/applications/${type}/attach`, file).toPromise().then(response => {
        file.globalid = response.globalid;
        this.files[response.globalid] = file;
        resolve(response.globalid);
        // delete this.files.temp;
      }).catch(response => {
        var error;
        if (response.data.hasOwnProperty('non_field_errors')) {
          error = response.data.non_field_errors.join(', ');
        } else if (response.data.hasOwnProperty('general_errors')) {
          error = response.data.general_errors.join(', ');
          // todo: need to handle the detailed errors
        }
        reject(error);
      }).finally(() => {
        this.filesUploading -= 1;
        if (this.filesUploading === 0) {
          this.uploading = false;
        }
        resolve();
      });
    })
    this.loadingPromises.push(fileDeferred);


    return fileDeferred;
  }

  public deleteFile(globalid, type) {
    return this.http.delete(`${this.environment.baseUrl}/applications/${type}/attach/${globalid}`).toPromise()
      .then(() => {
        delete this.files[globalid];
      });
  }

  public getFile(globalid, type) {
    return this.http.get<any>(`${this.environment.baseUrl}/applications/${type}/attach/${globalid}`).toPromise()
      .then(response => {
      this.files[globalid] = response.result;
    });
  }
}
