import {Component, Inject, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AttachmentsBaseComponent} from '../attachments-base/attachments-base.component';


@Component({
  selector: 'app-attachments-inline',
  templateUrl: './attachments-inline.component.html',
  styleUrls: ['./attachments-inline.component.css']
})
export class AttachmentsInlineComponent extends AttachmentsBaseComponent{
  // public attachments: any[];
  // public attachmentsLoaded = false;
  // loading = false;
  // attachService: BaseService;
  @Input() displayFields: any;
  // @Input() type: string;
  // @Input() foreignKey: string;
  // @Input() disableEditing: boolean;
  // @Input() useVerboseName: boolean;
  // attachIsCollapsed = true;
  // meta: Observable<any>;

  constructor(http: HttpClient, modalService: NgbModal, @Inject('environment') environment) {
    super(http, modalService, environment)
  }
}
