import {Component, Inject, OnInit} from '@angular/core';
import {BaseModalComponent} from '../base-modal/base-modal.component';
import {HttpClient} from '@angular/common/http';
import {ToastService} from '../../services/toast.service';
import {ErrorHandlingService} from '../../services/error-handling.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HistoryService} from '../../services/history.service';
import {MapService} from '../../services/map.service';

@Component({
  selector: 'app-contacts-modal',
  templateUrl: './contacts-modal.component.html',
  styleUrls: ['./contacts-modal.component.css']
})
export class ContactsModalComponent extends BaseModalComponent implements OnInit {
  public prefix: string;
  public filteredFields: string;

  constructor(http: HttpClient, toastService: ToastService, errorHandling: ErrorHandlingService,
              activeModal: NgbActiveModal, mapService: MapService, history: HistoryService,
              @Inject('environment') private e) {
    super(http, toastService, errorHandling, activeModal, mapService, history, e);
  }

  ngOnInit() {
    super.ngOnInit();
    this.prefix = (this.meta.type === 'contactmanager' ? '' : 'contact_')
    this.filteredFields = this.meta.display_fields.filter(f => !f.startsWith(this.prefix))
  }

}
