import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ApplicationService, Page} from "../services/application.service";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnChanges {
  @Input() pages: Page[];
  @Input() activePage: string;
  @Input() pageTitle: string;

  constructor(
    private application: ApplicationService
  ) { }

  ngOnInit(): void {
    this.application.pages.subscribe((pages) => {
      this.pages = pages;
      console.log('nav application listener pages', pages);
    })

    this.application.activePage.subscribe(page => {
      this.activePage = page;
      this.pageTitle = this.pages.find(x => x.item === page).name;
      console.log('active page: ', this.activePage, this.pages)
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.pages = this.pages.sort((a, b) => a.order - b.order);
  }
}
