import {Component, Inject, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AttachmentsBaseComponent} from './attachments-base/attachments-base.component';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.css']
})
export class AttachmentsComponent extends AttachmentsBaseComponent{
  // public attachments: any[];
  // public attachmentsLoaded = false;
  // loading = false;
  // attachService: BaseService;
  // @Input() type: string;
  // @Input() foreignKey: string;
  // @Input() disableEditing: boolean;
  // @Input() useVerboseName: boolean;
  // attachIsCollapsed = true;
  // meta: Observable<any>;
  constructor(http: HttpClient, modalService: NgbModal, @Inject('environment') environment) {
    super(http, modalService, environment)
  }
}
