import { Pipe, PipeTransform } from '@angular/core';
import {first} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Pipe({
  name: 'first'
})
export class FirstPipe implements PipeTransform {

  transform(value: Observable<any>, ...args: unknown[]): unknown {
    return value.pipe(first());
  }

}
