import {Injectable} from '@angular/core';
import {of} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ErrorHandlingService {
  customErrorMessage = false;
  customSaveMessage = false;

  constructor() {
  }

  fieldError(error, meta) {
    Object.keys(error.error).forEach(key => {
      meta.fields[key].hasError = true;
      meta.fields[key].errorMessage = '';

      error.error[key].forEach((err, index) => {
        meta.fields[key].errorMessage += err + ' ';
      });
    });

    return of();
  }

  clearError(meta) {
    Object.keys(meta.fields).forEach(k => {
      if (meta.fields[k]) {
        meta.fields[k].hasError = false
      }
    });
  }

}
