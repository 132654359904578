import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SitesService} from '../services/sites.service';

@Component({
  selector: 'app-table-sort-order',
  templateUrl: './table-sort-order.component.html',
  styleUrls: ['./table-sort-order.component.css']
})
export class TableSortOrderComponent implements OnInit, OnChanges {
  sortStatus = null;
  sortOrder = null;
  @Input() field: string;
  @Input() ordering: string;

  constructor(public sites: SitesService) {
  }

  ngOnInit() {
    this.checkSortStatus();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ordering) {
      this.checkSortStatus();
    }
  }

  private checkSortStatus() {
    if (this.ordering.indexOf(this.field) <= -1) {
      this.sortStatus = null;
      this.sortOrder = null;
    } else if (this.sites.filters.indexOf('-' + this.field) > -1) {
      this.sortStatus = 'desc';
      if (this.sites.filters.length > 1) {
        this.sortOrder = this.sites.filters.indexOf('-' + this.field) + 1;
      } else {
        this.sortOrder = null;
      }
    } else {
      this.sortStatus = 'asc';
      if (this.sites.filters.length > 1) {
        this.sortOrder = this.sites.filters.indexOf(this.field) + 1;
      } else {
        this.sortOrder = null;
      }
    }
  }

  public changeSort() {
    if (this.sortStatus === null) {
      this.sites.setOrderingParams(this.field);
    } else if (this.sortStatus === 'asc') {
      this.sites.setOrderingParams('-' + this.field);
    } else {
      this.sites.setOrderingParams(this.field);
    }
  }

  public clearSort() {
    this.sites.removeOrderingParam(this.field);
  }

}
