import { Component } from '@angular/core';
import { ApplicationService} from "./services/application.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title: string = 'Guam EPA Online Permits Portal';

  constructor(private application: ApplicationService) { }

  ngOnInit (){
    this.application.title.subscribe(title => {
      this.title = title;
    })
  }
}
