import {Component, Inject, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BehaviorSubject, Observable} from 'rxjs';
import {finalize, map, switchMap, tap} from 'rxjs/operators';
import {PaymentsService} from '../../services/payments.service';
import {BaseService} from '../../services/base.service';
import {GenericModalComponent} from '../../modals/generic-modal/generic-modal.component';
import {merge} from 'lodash';

@Component({
  selector: 'app-payments-inline',
  templateUrl: './payments-inline.component.html',
  styleUrls: ['./payments-inline.component.css']
})
export class PaymentsInlineComponent implements OnInit {
  @Input() meta: any;
  @Input() contentType: number;
  @Input() parentItem: any;
  @Input() foreignKey;
  @Input() parentService: BaseService;

  public IsCollapsed = true;
  public items: any[];
  public loading = false;
  public _meta: Observable<any>;
  private searchObj: { record_id: any; content_type: number };

  private paymentsService: PaymentsService;

  constructor(http: HttpClient, private modalService: NgbModal, @Inject('environment') private environment) {
    this.paymentsService = new PaymentsService(http, modalService, environment);
  }

  ngOnInit() {
    this.meta.type = 'payments';
    this.IsCollapsed = true;
    this.searchObj = {
      content_type: this.contentType,
      record_id: this.parentItem.globalid
    };
    this._meta = this.paymentsService.meta;
  }


  getItems() {
    if (this.items === undefined) {
      this.loading = true;
      this.paymentsService.getList(this.searchObj).pipe(
        tap(response => {
          this.items = response.results;
          this.IsCollapsed = !this.IsCollapsed;
        }),
        finalize(() => {
          this.loading = false;
        })).subscribe();
    } else {
      this.IsCollapsed = !this.IsCollapsed;
    }
  }

  open(foreignKey, item, $event) {
    if (this.items === undefined) {
      this.getItems();
    } else if ($event !== undefined) {
      this.IsCollapsed = false;
      $event.stopPropagation();
    }

    // item.record_id = this.parentItem.globalid;
    // item.content_type = this.contentType;
    // item.fee_amount = this.parentItem.fee;
    // item.fee_balance = this.parentItem.fee_balance;
    // if (item.id !== undefined) {
    //   item.globalid = item.id;
    //   if (!this.meta.permissions.write) {
    //     this.meta.fields.reviewer.read_only = true;
    //     this.meta.fields.order.read_only = true;
    //   }
    // } else if (!this.meta.permissions.write) {
    //   this.meta.fields.reviewer.read_only = false;
    //   this.meta.fields.order.read_only = false;
    // }
    // var modal_meta = angular.copy(this.meta);
    // modal_meta.display_fields.unshift('fee_amount', 'fee_balance');
    //
    // modalInstance = $uibModal.open({
    //   templateUrl: 'appjs/modals/genericV4.html',
    //   controller: 'dynamicModal',
    //   backdrop: 'static',
    //   size: 'lg',
    //   resolve: {
    //     item: function () {
    //       return item;
    //     },
    //     meta: function () {
    //       return modal_meta;
    //     }
    //   }
    // });
    this.paymentsService.open_modal(item, this.parentItem, this.contentType, this.parentService).pipe(
      switchMap(() => this.paymentsService.getList(this.searchObj)),
      tap(response => this.items = response.results)
    ).subscribe();
  }

  delete(item, meta) {
    const modalInstance = this.modalService.open(GenericModalComponent);
    modalInstance.componentInstance.text = meta.deleteText;
    modalInstance.componentInstance.title = 'Please confirm';
    modalInstance.result.then(result => {
      this.paymentsService.delete(item.globalid).pipe(
        switchMap(() => this.parentService.get<any>(this.parentItem.globalid)),
        tap(response => {
          this.parentItem.fee_balance = response.result.fee_balance;
          const index = this.items.indexOf(item);
          this.items.splice(index, 1);
          meta.count--;
        })).subscribe();
    });
  }

}
