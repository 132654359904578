import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'toTrusted'
})
export class ToTrustedPipe implements PipeTransform {
  constructor(public sanitizer: DomSanitizer) {
  }

  transform(value: any, ...args: any[]): any {
    if (args[0] === 'url') {
      return this.sanitizer.bypassSecurityTrustResourceUrl(value);
    }
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

}
