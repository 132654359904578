import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {NavigationEnd, Router} from "@angular/router";
import {filter, tap} from "rxjs/operators";

@Component({
  selector: 'app-standby-application',
  templateUrl: './standby-application.component.html',
  styleUrls: ['./standby-application.component.scss']
})
export class StandbyApplicationComponent implements OnInit {
  @Input() application_type: string;

  public old_permit_number: string;

  constructor(private activeModal: NgbActiveModal, private router: Router) { }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      tap(e => this.activeModal.close())
    ).subscribe();
  }

  public close(startInfo) {
    this.activeModal.close(startInfo);
  }

}
