import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'isImage'
})
export class IsImagePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value === undefined) {
      return false;
    }
    return (value?.indexOf('jpeg') > -1 || value?.indexOf('png') > -1);
  }

}
