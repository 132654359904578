import {Injectable, TemplateRef} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ToastService {
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({textOrTpl, ...options});
  }

  warning(text) {
    this.show(text, {classname: 'bg-warning text-light', delay: 10000});
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  success(text) {
    this.show(text, {classname: 'bg-success text-light'});
  }

  error(text) {
    this.show(text, {classname: 'bg-danger text-light', delay: 10000});
  }
}
