import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.css']
})
export class NotificationModalComponent implements OnInit {
  @Input() notificationContent: string;
  @Input() notificationSubject: string;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  submit() {
    this.activeModal.close({content: this.notificationContent, subject: this.notificationSubject});
  }

}
