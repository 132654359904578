import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BaseService} from '../../services/base.service'
import {GenericModalComponent} from '../../modals/generic-modal/generic-modal.component';
import {DynamicModalComponent} from '../../modals/dynamic-modal/dynamic-modal.component';

@Component({
  selector: 'app-interest-inline',
  templateUrl: './interest-inline.component.html',
  styleUrls: ['./interest-inline.component.css']
})
export class InterestInlineComponent implements OnInit {
  @Input() id: string;
  @Input() foreignKeyField: string;
  @Input() deleteText: string;
  @Input() service: BaseService;

  public isCollapsed = true;
  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.service.loadData({search: this.id})
      .subscribe(() => this.loading.emit(true));
  }

  // $rootScope.$watch('allCollapsed', function (newValue, oldValue) {
  //                       this.IsCollapsed = newValue;
  //                   });

  public open(item, $event) {
    this.service.meta.display_fields.forEach(field => {
      if (this.service.meta.fields.hasOwnProperty(field) && this.service.meta.fields[field].hasOwnProperty('get_choices')) {
        this.service.meta.fields[field].get_choices(item).then(choices => {
          this.service.meta.fields[field].choices = choices;
        });
      }
    });
    if ($event !== undefined) {
      this.isCollapsed = false;
      $event.stopPropagation();
    }

    if (item === undefined) {
      item = {};
      item[this.foreignKeyField] = this.id;
    }
    const modalInstance = this.modalService.open(DynamicModalComponent, {size: 'lg', backdrop: 'static'});
    modalInstance.componentInstance.item = item;
    modalInstance.componentInstance.meta = this.service.meta;

    modalInstance.result.then(i => {
      if (i !== undefined) {
        this.service.item = i;
      }
    }).catch(() => {
      // do nothing
    });
  }

  public delete(item, $event) {
    if ($event !== undefined) {
      this.isCollapsed = false;
      $event.stopPropagation();
    }
    const confirmDialog = this.modalService.open(GenericModalComponent);
    confirmDialog.componentInstance.title = 'Please confirm';
    confirmDialog.componentInstance.text = this.deleteText;
    confirmDialog.result.then(() => {
      this.service.delete(item.globalid).subscribe( () => {
        this.service.item = {};
      });
    });
  }
}
