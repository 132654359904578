import {Component, OnInit} from '@angular/core';
import {ApplicationService, Page} from '../services/application.service';
import {filter, map, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {LoadingComponent} from '../modals/loading/loading.component';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {
  pages: Page[];
  currentPage: Page;
  previousPage: Page;

  constructor(public application: ApplicationService, public http: HttpClient, public route: ActivatedRoute,
              public router: Router, public modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.setPages();
  }

  setPages() {
    this.application.pages.pipe(
      tap(pages =>  {
        this.currentPage = pages.find(x => x.item === this.route.routeConfig.path);
        this.application.activePage.next(this.currentPage.item);
        this.previousPage = pages.find(p => p.order === this.currentPage.order - 1)
      }),
      map(pages => pages.filter(p => !['review', 'complete'].includes(p.item))),
      tap(pages => this.pages = pages)
    ).subscribe();
  }

  getFilteredFields(currentPage): string[] {
    let display_fields: string[] = [];
    if (Array.isArray(currentPage.display_fields)) {
      display_fields = currentPage.display_fields
    } else {
      Object.keys(currentPage.display_fields).forEach(k => {
        display_fields = [...display_fields, ...currentPage.display_fields[k]]
      })
    }
    // if (currentPage.item._meta?.display_fields) {
    //   display_fields = currentPage.item._meta.display_fields
    // }
    return display_fields.filter(x => x !== 'shape')
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  get_generator_display_fields(item): string[] {
    var display_fields = [
      'date_of_manufacture', 'service_start_date', 'unit_identification', 'description', 'useAP42', 'total_unburned_hydrocarbons',
      'toc_units', 'particulate_matter', 'pm_units', 'sulfur_dioxide', 'so2_units',
      'carbon_monoxide', 'co_units', 'nitrogen_oxide', 'no_units'];

    // if (item.fuel_type === 'diesel') {
    //     display_fields = display_fields.concat(['hazardous_air_pollutants', 'haps_units']);
    //     if (item.brake_horsepower > 600 || item.brake_kilowatts > 447.4) {
    //         display_fields = display_fields.concat(['benzene', 'benzene_units']);
    //     } else if ((item.brake_horsepower && item.brake_horsepower <= 600) || (item.brake_kilowatts && item.brake_kilowatts <= 447.4)) {
    //         display_fields = display_fields.concat(['formaldehyde', 'formaldehyde_units']);
    //     }
    // } else
    if (item.fuel_type === 'butane' || item.fuel_type === 'propane') {
      display_fields = display_fields.concat([
        'nitrous_oxide', 'nitrous_oxide_units', 'carbon_dioxide', 'carbon_dioxide_units', 'methane', 'methane_units'
      ]);
    }

    display_fields = display_fields.concat(['fuel_type', 'fuel_consumption',
      'tank_location', 'internal_tank_size', 'brake_horsepower',
      'brake_kilowatts', 'generator_make', 'generator_model', 'generator_serial_number', 'generator_plate',
      'engine_make', 'engine_model', 'engine_serial_number', 'engine_plate', 'engine_hours', 'picture', 'document',
      'certificate']);

    return display_fields;
  };

  submit() {
    const modalInstance = this.modalService.open(LoadingComponent, {backdrop: 'static'});
    modalInstance.componentInstance.message = 'Validating Current Page';

    this.http.post(`${this.currentPage.service.baseUrl}/${this.application.attributes.globalid}/submit/`, {}).toPromise()
      .then(() => {
      this.application.pages.subscribe(pages => {
        const currentPageIndex = pages.findIndex(x => x.name === this.currentPage.name);
        const nextPage = pages[currentPageIndex + 1];
        this.application.activePage.next(nextPage.item);
        this.router.navigate(['application', this.route.parent.snapshot.params.type, nextPage.item]);
      });
    }).catch(function () {
    }).finally(function () {
      setTimeout(() => modalInstance.close(), 750)
    });
  }
}
