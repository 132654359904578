import {Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ApplicationService, Page} from "../services/application.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LoadingComponent} from "../modals/loading/loading.component";
import {FileUploadServiceService} from "../services/file-upload-service.service";
import {cloneDeep} from "lodash";
import {filter, tap} from "rxjs/operators";

@Component({
  selector: 'app-dynamic-input-form',
  templateUrl: './dynamic-input-form.component.html',
  styleUrls: ['./dynamic-input-form.component.scss'],
})
export class DynamicInputFormComponent implements OnInit, OnChanges {
  currentPage: Page;
  previousPage: Page;
  nextPage: Page;
  currentItem: string;
  help: any;
  @Input() helpPromise: Promise<any>;
  // todo:
  // $scope.fileUploadService = FileUploadService;

  constructor(public application: ApplicationService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router: Router,
              private modalService: NgbModal,
              public fileUploadService: FileUploadServiceService,
              @Inject('environment') private environment
  ) { }

  ngOnInit(): void {
    this.route.params.pipe(
      // filter(e => e instanceof NavigationEnd),
      tap(params => {
        this.loadPage(params.route);
      })
    ).subscribe()
  }

  ngOnChanges(changes: SimpleChanges) {
    // todo: check if actual changes occurred then call page onItemChange
    if ('onItemChange' in this.currentPage) {
      this.currentPage.onItemChange(this.currentItem, this.currentPage)
    }
  }

  private loadPage(route) {
    this.application.pages.subscribe(pages => {
      this.currentPage = pages.find(x => x.item === route);
      this.currentItem = this.currentPage.item;
      this.application.activePage.next(this.currentItem);
      this.previousPage = pages.find(p => p.order === this.currentPage.order - 1)
      this.nextPage = pages.find(p => p.order === this.currentPage.order + 1);
      this.scrollToFirstInput();
    })

    // load help
    let type = '';
    if (this.currentPage.item !== 'facility') {
      type = 'help/' + this.application.applicationType;
    } else {
      type = 'help/facility';
    }
    // this.helpPromise = this.http.get<any[]>(`${this.environment.baseUrl}/applications/${type}/`);
    this.help = this.http.get<any[]>(`${this.environment.baseUrl}/applications/${type}/`).subscribe((help) => {
      console.log(help);
      // var fieldHelp = help.results.find(x => x.field === this.field + "_header", {field: this.field + "_header"});
      // console.log(fieldHelp);
    });
  }

  public validate(e) {
    // blur next button to prevent issue with page scrolling to button on route change
    e.currentTarget.blur();
    const modalInstance = this.modalService.open(LoadingComponent, {backdrop: 'static'});
    modalInstance.componentInstance.message = 'Validating Current Page';

    this.application.validate(this.currentPage).then(() => {
      this.router.navigate(['application', this.route.parent.snapshot.params.type, this.nextPage.item]);
    }).catch(function () {
    }).finally(function () {
      setTimeout(() => modalInstance.close(), 750)
    });
  };

  public addObject(array) {
    if (this.application.meta.fields[this.currentItem].child.fields.hasOwnProperty('shape')) {
      array.push({shape: {}, coordinates: {}});
    } else {
      array.push({});
    }
  };

  clearEmissionsFactor(item) {
    delete item.toc_units;
    delete item.pm_units;
    delete item.so2_units;
    delete item.co_units;
    delete item.no_units;
    // delete item.haps_units;
    delete item.benzene_units;
    // delete item.formaldehyde_units;
    delete item.carbon_dioxide_units;
    delete item.nitrous_oxide_units;
    delete item.methane_units;

    item.total_unburned_hydrocarbons = null;

    item.particulate_matter = null;
    // item.pm_units = undefined;
    item.sulfur_dioxide = null;
    // item.so1_units = undefined;
    item.carbon_monoxide = null;
    // item.co_units = undefined;
    item.nitrogen_oxide = null;
    // item.no_units = undefined;
    // item.hazardous_air_pollutants = null;
    // item.haps_units = undefined;
    item.benzene = null;
    // item.formaldehyde = null;
    item.carbon_dioxide = null;
    item.nitrous_oxide = null;
    item.methane = null;
  };

  public remove(items, index) {
    if (this.application.meta.fields[this.currentPage.item].child.children.shape !== undefined) {
      let labelField = this.application.meta.fields[this.currentPage.item].child.children.shape.label_field;
      let graphic = {attributes: {label: items[index][labelField]}};
      // todo:
      // graphicsService.remove(graphic);
    }
    this.application.attributes[this.currentPage.item].splice(index, 1)
  };

  public isArray(x): boolean {
    return Array.isArray(x);
  }

  public setMeta(item, meta) {
    if (item?._meta === undefined) {
      item._meta = cloneDeep(meta);
      // set up display_fields and add to item._meta for each item "form group" (ie each contact in the array)
      this.currentPage.onItemChange(item, this.currentPage);
    }
  }

  private scrollToFirstInput() {
    console.log('scrollToFirst', document.querySelectorAll('app-dynamic-input'));
  }
}
