import {Component, Inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-display-field',
  templateUrl: './display-field.component.html',
  styleUrls: ['./display-field.component.css']
})
export class DisplayFieldComponent implements OnInit, OnChanges {
  @Input() getChoices;
  @Input() value;
  @Input() type;
  @Input() choices;
  @Input() showLabel;
  @Input() allowLong;
  @Input() icons;

  constructor(@Inject('environment') private environment) {
  }

  ngOnInit() {
    if (this.getChoices) {
      this.getChoices(this.value).then(choices => {
        this.choices = choices;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('value')) {
      if (changes.value.currentValue !== changes.value.previousValue && this.getChoices) {
        this.getChoices(changes.value.currentValue).then(choices => {
          this.choices = choices;
        });
      }
    }
  }

  viewImage(attachment, e) {
    e.stopPropagation();
    const binary = atob(attachment.base64.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    const file = new Blob([view], {type: attachment.filetype});
    const fileURL = window.URL.createObjectURL(file);
    window.open(fileURL, '_blank');
  }
  public getDownloadUrl(attachment) {
    return `${this.environment.baseUrl}/payments/attach/${attachment.globalid}/download/`;
  }

}
