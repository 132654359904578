import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-header-panel',
  templateUrl: './header-panel.component.html',
  styleUrls: ['./header-panel.component.scss']
})
export class HeaderPanelComponent implements OnInit {
  @Input() field: string;
  @Input() helpPromise: Promise<any>;

  title: string;
  text: string;

  constructor() {
  }

  ngOnInit(): void {
    // this.helpPromise.then((help) => {
    //   var help = help.results.find(x => x.field === this.field + "_header", {field: this.field + "_header"});
    //   if (help !== undefined) {
    //     this.title = help.help_label;
    //     this.text = help.help_text;
    //   }
    // });
  }

}
