import {Component, Inject, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {merge} from 'lodash';
import {Observable, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {BaseService, Response} from "../services/base.service";

@Component({
  selector: 'app-summary-panel',
  templateUrl: './summary-panel.component.html',
  styleUrls: ['./summary-panel.component.css']
})
export class SummaryPanelComponent implements OnInit {
  @Input() public meta: any;
  @Input() searchObject: any;
  itemService: BaseService;
  public response: Observable<Response<any>> = new Observable<any>();
  public subItems: string[];
  public relatedItems: string[];
  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  ngOnInit() {
    this.itemService = new BaseService(`${this.environment.baseUrl}/${this.meta.type}`, this.http);

    this.response = this.itemService.getList(this.searchObject).pipe(
      tap(response => {
        response.meta = merge(response.meta, this.meta);
      })
    );
    this.subItems = this.meta.hasOwnProperty('subItems') ? this.meta.subItems : [];
    this.relatedItems = this.meta.hasOwnProperty('relatedItems') ? this.meta.relatedItems : [];
  }

  public _displayFields(item) {
    if (this.meta.display_fields !== undefined) {
      if (Array.isArray(this.meta.display_fields)) {
        return this.meta.display_fields;
      }
      return this.meta.display_fields(item);
    }
    return [];
  }

  getSearchObject(subItem: string, globalid: string) {
    let s = {}
    if (this.meta[subItem] && this.meta[subItem].searchObject) {
      s = this.meta[subItem].searchObject
    }
    return  {search: globalid, ...s}
  }
}
