import {Component, Inject, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CdkDragDrop, DragDropModule, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {merge} from 'lodash';
import {BehaviorSubject, forkJoin, from, Observable, of, pipe, throwError, timer} from 'rxjs';
import {catchError, concatMap, filter, finalize, flatMap, map, switchMap, take, tap} from 'rxjs/operators';
import {BaseService} from '../../services/base.service';
import {ToastService} from '../../services/toast.service';
import {ErrorHandlingService} from '../../services/error-handling.service';
import {DisplayPipe} from '../../pipes/display.pipe';
import {GenericModalComponent} from '../../modals/generic-modal/generic-modal.component';
import {DynamicModalComponent} from '../../modals/dynamic-modal/dynamic-modal.component';


@Component({
  selector: 'app-generic-inline',
  templateUrl: './generic.component.html',
  styleUrls: ['./generic.component.css'],
  providers: [DisplayPipe]
})
export class GenericInlineComponent implements OnInit {
  private genericService: BaseService;
  private surveySubmitService: BaseService;
  private taskService: BaseService;
  // @Input() items: { [key: string]: any; }[];
  @Input() parentItem: { [key: string]: any; };
  // @Input() meta: { [key: string]: any; }[];
  public _meta: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  @Input() items: { [key: string]: any; }[];
  @Input() hasPermission: string;
  @Input() loading: boolean;
  private saving = false;
  private showLocationEditButton: boolean;
  public IsCollapsed = true;
  private initialPageSize: number;
  public loadingMore: boolean;
  private currentPage: number;
  private hadError: boolean;
  private samplingGroups: any[];
  private task;
  private polling: Observable<any>;

  @Input() set meta(value) {
    this._meta.next(value);
  }

  get meta() {
    return this._meta.getValue();
  }

  // @Input() set items(value) {
  //   this._items.next(value);
  // }
  // get items() {
  //   return this._items.getValue();
  // }
  public foreignKeyField;
  public deleteText;
  public deleteFn;
  @Input() foreignKey: string;
  @Input() disableDelete: boolean;
  @Input() disableAdd: boolean;

  constructor(public http: HttpClient, public toastr: ToastService, private displayPipe: DisplayPipe,
              private errorHandling: ErrorHandlingService, private modalService: NgbModal,
              @Inject('environment') private environment) {
  }

  ngOnInit() {
    if (this.meta.hasOwnProperty('type')) {
      this.genericService = new BaseService(`${this.environment.baseUrl}/${this.meta.type}`, this.http);
      if (this.meta !== null) {
        if (!this.meta.isLoading) {
          this.meta.isLoading = true;
          this.genericService.loadMetadata().pipe(
            tap(metadata => {
              this.meta = merge(this.meta, metadata);
              this.meta.isLoading = false;
            })).subscribe();
        }
        if (this.meta.featureService !== undefined) {
          this.getItems();
        }
      }
    }
  }

  loadMore() {
    this.loadingMore = true;
    this.currentPage += 1;
    this.genericService.getList({
      search: this.foreignKey,
      page_size: this.initialPageSize,
      page: this.currentPage
    }).pipe(tap(response => {
        response.results.forEach(item => {
          this.items.push(item);
        });
      }),
      finalize(() => {
        this.loadingMore = false;
        this.loading = false;
      }));
  }

  getItems(force = false) {
    if (this.items === undefined || force) {
      this.loading = true;
      this.genericService.getList({search: this.foreignKey}).pipe(
        tap(response => {
          this.items = response.results;

          if (this.items.length > 0 && this.items[0].hasOwnProperty('shape')) {
            this.showLocationEditButton = true;
            // this.displayShapes(this.items);
          } else if (!force) {
            this.IsCollapsed = !this.IsCollapsed;
          }
          this.initialPageSize = this.items.length;

          this.meta = merge(this.meta, response.meta);

        }),
        finalize(() => this.loading = false)).subscribe();
    } else {
      this.IsCollapsed = !this.IsCollapsed;
    }
  }

  open(foreignKey, item, event?) {
    // this.meta.display_fields.forEach(field => {
    //   if (this.meta.fields.hasOwnProperty(field) && this.meta.fields[field].hasOwnProperty('get_choices')) {
    //     this.meta.fields[field].get_choices(item).then(choices => {
    //       this.meta.fields[field].choices = choices;
    //     });
    //   }
    // });

    const originalLocation = {...item.shape};
    if (this.items === undefined) {
      this.getItems();
    } else if (event !== undefined) {
      this.IsCollapsed = false;
      event.stopPropagation();
    }

    // let templateUrl;
    // if (this.meta.type.indexOf('/note') > -1) {
    //   templateUrl = '/appjs/modals/genericnotes.html';
    // } else if (this.meta.type.indexOf('/comments') > -1) {
    //   templateUrl = '/appjs/modals/genericnotes.html';
    // } else if (this.meta.type.indexOf('/contacts') > -1) {
    //   templateUrl = '/appjs/modals/contactmanager/newcontact.html';
    // } else {
    //   templateUrl = '/appjs/modals/genericV4.html';
    // }

    item[this.meta.foreign_key_field] = this.foreignKey;
    const modalInstance = this.modalService.open(DynamicModalComponent, {backdrop: 'static', size: 'lg'});
    modalInstance.componentInstance.item = item;
    modalInstance.componentInstance.meta = this.meta;

    modalInstance.result.then(response => {
      this.getItems(true);
      if (response !== undefined && response.newItem !== undefined) {

        // this.items.forEach((thisItem, i) => {
        //   if (thisItem.globalid === response.newItem.globalid) {
        //     this.items.splice(i, 1);
        //   }
        // });
        // this.items.unshift(response.newItem);
        const reusedItem = Object.assign({}, response.newItem);
        if (response.addAnother !== undefined && response.addAnother === true) {
          delete reusedItem.globalid;
          this.meta.add_another_reset.forEach(field => {
            delete reusedItem[field];
          });
          this.open(foreignKey, reusedItem);
        }
      }

      // if (stringify(originalLocation) !== stringify(item.shape)) {
      //   mapService.replaceGraphic(item.globalid, item.shape);
      // }
    }).catch(() => null);
  }

  delete(item) {
    const modalInstance = this.modalService.open(GenericModalComponent);
    modalInstance.componentInstance.text = this.meta.deleteText;
    modalInstance.componentInstance.title = 'Please confirm';
    modalInstance.result.then(result => {
      this.genericService.delete(item.globalid).subscribe(() => {
        const index = this.items.indexOf(item);
        this.items.splice(index, 1);
        this.meta.count--;
        if (this.meta.hasOwnProperty('refreshProperties')) {
          this.meta.refreshProperties(this.parentItem);
        }
      });
    });
  }
}

  //   if (response[0].includes ('error:')) {
  //     this.hadError = false;
  //     this.toastr.error('Error sending survey templates');
  //   } else {
  //     this.toastr.success('Successfully sent survey templates.');
  //     this.errorHandling.customErrorMessage = false;
  //   }
//       });
//   }
// }

/*
/!*global angular *!/
angular.module('app')
  .directive('genericInline', function() {
    'use strict';
    return {
      restrict: 'E',
      scope: {
        items: '=',
        meta: '=',
        hasPermission: '=',
        loading: '=',
        foreignKeyField: '@',
        deleteText: '@',
        deleteFn: '&?',
        foreignKey: '@',
        disableDelete: '=',
        disableAdd: '='
      },
      templateUrl: 'appjs/directives/inlines/generic.html',
      controller: ['$scope', '$uibModal', 'dialogs', 'Restangular', '$filter', '$rootScope', 'errorHandling', '$q', 'toastr',
        function($scope, $uibModal, dialogs, Restangular, $filter, $rootScope, errorHandling, $q, toastr) {
          $rootScope.$watch('allCollapsed', function(newValue, oldValue) {
            $scope.IsCollapsed = newValue;
          });

          $scope.open = function(item, $event) {
            angular.forEach($scope.meta.display_fields, function(field) {
              if ($scope.meta.fields.hasOwnProperty(field) && $scope.meta.fields[field].hasOwnProperty('get_choices')) {
                $scope.meta.fields[field].get_choices(item).then(function(choices) {
                  $scope.meta.fields[field].choices = choices;
                });
              }
            });

            if ($event !== undefined) {
              $scope.IsCollapsed = false;
              $event.stopPropagation();
            }

            let templateUrl;
            if ($scope.meta.type.indexOf('/contacts') > -1) {
              templateUrl = '/appjs/modals/contactmanager/newcontact.html';
            } else {
              templateUrl = '/appjs/modals/genericV4.html';
            }

            if (item === undefined) {
              item = {};
              item[$scope.foreignKeyField] = $scope.foreignKey;
            }
            let modalInstance = $uibModal.open({
              templateUrl,
              controller: 'dynamicModal',
              backdrop: 'static',
              size: 'lg',
              resolve: {
                item() {
                  return item;
                },
                meta() {
                  return $scope.meta;
                }
              }
            });
            modalInstance.result.then(function(response) {
              if (response.newItem !== undefined) {
                angular.forEach($scope.items, function(thisItem, i) {
                  if (thisItem.globalid === response.newItem.globalid) {
                    $scope.items.splice(i, 1);
                  }
                });
                $scope.items.unshift(response.newItem);
                let reused_item = Object.assign({}, response.newItem);
                if (response.addAnother !== undefined && response.addAnother === true) {
                  delete reused_item.globalid;
                  $scope.meta.add_another_reset.forEach(function(field) {
                    delete reused_item[field];
                  });
                  $scope.open(foreignKey, reused_item);
                }
              }
              if ($scope.meta.hasOwnProperty('refreshProperties')) {
                $scope.meta.refreshProperties();
              }

              if (!angular.equals(originalLocation, item.shape)) {
                mapService.replaceGraphic(item.globalid, item.shape);
              }
            }).catch(function() {
              // do nothing
            });
          };

          if ($scope.deleteFn === undefined) {
            $scope.delete = function(item) {
              $scope.meta.count--;
              let confirmDialog = dialogs.confirm('Please confirm', $scope.deleteText);
              confirmDialog.result.then(function() {
                item.remove().then(function() {
                  let index = $scope.items.indexOf(item);
                  $scope.items.splice(index, 1);
                });
              });
            };
          } else {
            $scope.delete = function(item) {
              $scope.meta.count--;
              $scope.deleteFn({item});
            };
          }

          let currentPage = 1;
          $scope.loadMore = function() {
            $scope.loadingMore = true;
            currentPage += 1;
            Restangular.all($scope.meta.type).getList({
              search: $scope.foreignKey,
              page_size: 10,
              page: currentPage
            }).then(function(items) {
              angular.forEach(items, function(item) {
                $scope.items.push(item);
              });
            }).finally(function() {
              $scope.loadingMore = false;
            });
          };

          let saving = false;

          $scope.drop = function(oldPriority, newPriority) {
            if (saving) {
              toastr.warning('Please wait until previous edit is saved.');
            } else {
              saving = true;
              let promises = [];
              errorHandling.customErrorMessage = true;

              newPriority = parseInt(newPriority);
              oldPriority = parseInt(oldPriority);

              angular.forEach($scope.items, function(item, i) {
                if (oldPriority < newPriority) {
                  if (item[$scope.meta.sort_attribute] === oldPriority) {
                    $scope.items[i][$scope.meta.sort_attribute] = newPriority;
                    promises.push($scope.items[i].save());
                  } else if (item[$scope.meta.sort_attribute] <= newPriority && item[$scope.meta.sort_attribute] > oldPriority) {
                    $scope.items[i][$scope.meta.sort_attribute]--;
                    promises.push($scope.items[i].save());
                  }
                } else if (oldPriority > newPriority) {
                  if (item[$scope.meta.sort_attribute] === oldPriority) {
                    $scope.items[i][$scope.meta.sort_attribute] = newPriority;
                    promises.push($scope.items[i].save());
                  } else if (item[$scope.meta.sort_attribute] >= newPriority && item[$scope.meta.sort_attribute] < oldPriority) {
                    $scope.items[i][$scope.meta.sort_attribute]++;
                    promises.push($scope.items[i].save());
                  }
                }
              });
              $q.all(promises).then(function() {
                toastr.success('Saved');
              }).catch(function() {
                toastr.error('Error encountered');
              }).finally(function() {
                errorHandling.customErrorMessage = false;
                saving = false;
                $scope.$apply();
              });
            }
          };
        }]
    };
  });
*/
