import {Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HelpDocsModalComponent} from './help-docs-modal/help-docs-modal.component';

@Component({
  selector: 'app-help-docs-links',
  templateUrl: './help-docs-links.component.html',
  styleUrls: ['./help-docs-links.component.css']
})
export class HelpDocsLinksComponent implements OnInit {
  @Input() recordType: string;
  @Input() color: string = 'white';
  @ViewChild('content', {static: true}) content: ElementRef;
  opened = false;
  helpContent: any;

  constructor(private http: HttpClient, private modalService: NgbModal,
              @Inject('environment') private environment) {
  }

  ngOnInit() {
  }

  public openHelp() {

    if (this.recordType === undefined) {
      const pathname = location.pathname.replace(/(%7B)?[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}(%7D)?\//i, '');
      this.http.get(`${this.environment.baseUrl}/help/`, {params: new HttpParams().set('url', pathname)}).subscribe((helpContent) => {
        this.helpContent = helpContent[0];
        this.openModal();

      });
    } else {
      this.http.get(`${this.environment.baseUrl}/help/${this.recordType}/`).subscribe((helpContent) => {
        this.helpContent = helpContent;
        this.openModal();

      });
    }

    return this.opened = false;

  }

  openModal() {
    if (this.opened) {
      return;
    }
    const modalInstance = this.modalService.open(HelpDocsModalComponent, {size: 'lg'});
    modalInstance.componentInstance.helpContent = this.helpContent;
  }

}
