import {Inject, Injectable} from '@angular/core';
import * as locator from "@arcgis/core/rest/locator";
import Point from '@arcgis/core/geometry/Point';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class GeocodingService {
  public defaultCenter = new Point({x: this.environment.default_map_center[0], y: this.environment.default_map_center[1]});

  constructor(@Inject('environment') private environment) { }

  public geocodeAddress(address, village, magicKey?): Observable<__esri.AddressCandidate[]> {
    return new Observable(obs => {
      village = village ? ' ' + village : '';

      const singleline = address + village;
      locator.addressToLocations(this.environment.geocoder_url, {
        address: {singleline},
        outFields: ['*'],
        countryCode: this.environment.countryCode,
        forStorage: false,
        magicKey: magicKey ? magicKey : ''
        // maxLocations: '1'
      }).then((response) => {
        // if (response.length > 0) {
        //   response.forEach((result, i) => {
        //     response[i].extent = new Extent(result.extent);
        //     // response[i].zoomToExtent = function () {
        //     //   console.log(this.extent);
        //     // };
        //   });

          // var extent = new vm.Extent(response[0].extent);
          obs.next(response);
        //   obs.complete();
        // } else {
        //   obs.error();
        // }
      }).catch(() => obs.next([]));
    });
  }

  public suggestAddress(address, village): Observable<__esri.SuggestionResult[]> {
    return new Observable(obs => {
      village = village ? ' ' + village : '';
      locator.suggestLocations(this.environment.geocoder_url, {text: address + village, location: this.defaultCenter})
        .then((response) => {
          obs.next(response);
        }).catch(() => obs.next([]));
    });
  }

}
