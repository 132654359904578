import {Component, OnInit} from '@angular/core';
import {ApplicationService} from "../services/application.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {LoginComponent} from '../modals/login/login.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  starting = false;
  application_type: string;
  title: string;
  text: string;

  constructor(public application: ApplicationService, private modalService: NgbModal, public router: Router,
              public http: HttpClient) {
  }

  ngOnInit(): void {
  }


  // public beginApplication(type) {
  //   var modalInstance = this.modalService.open({
  //     templateUrl: '/static/modals/standbyApplicationChoices.html',
  //     controller: function ($uibModalInstance, $scope) {
  //       $scope.close = function (startInfo) {
  //         $uibModalInstance.close(startInfo);
  //       };
  //     },
  //     backdrop: 'static'
  //   });
  //   modalInstance.result.then((startInfo) => {
  //     // disable popup... but leaving code in place just in case minds are changed.
  //     // var isMobile = mobileCheck();
  //     // if (!isMobile) $window.open('/help?item=' + type + '/load', 'help', 'height=900,width=450');
  //
  //     // todo: fix loading modal
  //     // LoadingModal.open('Loading Application');
  //
  //     this.application.startNew(type, startInfo).then(function () {
  //       this.application.isNew = true;
  //       this.router.navigate(this.application.pages[0].state);
  //     }).finally(function () {
  //       // todo: fix loading modal
  //       //   LoadingModal.close(1500);
  //     });
  //   });
  // };

  public openLoginModal(type, label) {
    return this.modalService.open(LoginComponent);
  }

  public updateApplication(type) {
    var modalInstance = this.openLoginModal(type, null);

    modalInstance.result.then(function (id) {
      this.application.updateApplication(type, id).then(function (state) {
        this.router.navigate(state);
      }).catch(function (error) {
        this.error = error;
      });
    });
  };

  public login(type, label) {
    var modalInstance = this.openLoginModal(type, label);

    modalInstance.result.then(() => {
      this.router.navigate(['start', type])
    });
  };


}
