import {Component, Inject, Input, OnInit, Output} from '@angular/core';
import {BaseService} from '../services/base.service';
import {ToastService} from "../services/toast.service";
import {HttpClient} from '@angular/common/http';
import {finalize, tap} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GenericModalComponent} from '../modals/generic-modal/generic-modal.component';

@Component({
  selector: 'app-results-list-v3',
  templateUrl: './results-list-v3.component.html',
  styleUrls: ['./results-list-v3.component.css']
})
export class ResultsListV3Component implements OnInit {
  @Input() displayFields: string[];
  @Input() nonfilterableFields: string[];
  @Input() type: string;
  @Input() inputType: string;
  @Input() showLink: boolean;
  @Input() match: any;
  // @Output() search: any;
  @Input() searchObj: any;
  @Input() loading: boolean;
  @Input() state: string;
  @Input() items: any[];
  @Input() rowButtons: any[];
  @Input() actionSelected;

  public filters = [];

  genericService: BaseService;
  public isAllSelected: any;
  public meta;
  public currentPage = 1;

  constructor(private http: HttpClient, private toastr: ToastService, private modalService: NgbModal,
              @Inject('environment') private environment) {
  }

  ngOnInit() {
    this.genericService = new BaseService(`${this.environment.baseUrl}/${this.type}`, this.http);
    this.search();
  }

  public buttonsPresent() {
    // return $transclude.isSlotFilled('rowButtons');
  }


  public search() {
    this.loading = true;
    this.searchObj.ordering = this.filters.join(',');
    this.genericService.getList(this.searchObj).pipe(tap(response => {
      this.items = response.results;
      this.meta = response.meta;
    }), finalize(() => {
      this.loading = false;
    })).subscribe();
  }

  switchPage(pageId) {
    this.searchObj.page = pageId;
    this.search();
  }

  selectAll() {
    const toggleStatus = this.isAllSelected;
    this.items.forEach(site => {
      site.selected = toggleStatus;
    });
  }

  selectionChange() {
    const selectedSites = this.items.filter(item => item.selected === true);
    const siteNames = [];
    selectedSites.forEach(site => {
      siteNames.push(site.primary_name);
    });

    if (this.actionSelected.value === 'delete') {
      if (selectedSites.length <= 0) {
        this.toastr.warning('You must select at least one site to delete.');
      } else {
        const confirmDialog = this.modalService.open(GenericModalComponent, {size: 'sm'});
        confirmDialog.componentInstance.title = 'Please confirm';
        confirmDialog.componentInstance.text = 'Are you sure you want to remove <b>' + siteNames.join(',') + '</b> from this site?';
        confirmDialog.result.then(() => {
          selectedSites.forEach(site => {
            this.genericService.delete(site.globalid).pipe(tap(() => {
              const index = this.items.indexOf(site);
              this.items.splice(index, 1);
            }));
          });
        });
      }
    } else if (this.actionSelected.value === 'merge') {
      if (selectedSites.length <= 1) {
        this.toastr.warning("You must select at least two sites to merge.");
      } else {
        // var mergeDialog = dialogs.create('/static/dialogs/merge.html', 'mergeDialogCtrl', selectedSites, 'lg');
      }
    }
    this.actionSelected = {value: 'default', label: 'Actions'};
  }

}
