import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'display'
})
export class DisplayPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (args[0] === 'boolean') {
      if (value === 1 || value === true) {
        return 'Yes';
      } else if (value === 0 || value === false) {
        return 'No';
      } else {
        return '';
      }
    } else if (value === undefined || value === '') {
      return '';
    } else if (args[0] !== undefined) {
      const displayObject = args[0].filter(a => a.value === value)[0];
      return (displayObject ? displayObject.display_name : value);
    }
    return value;
  }

}
