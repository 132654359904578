import {Component, Inject, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpXsrfTokenExtractor} from '@angular/common/http';
// @ts-ignore
const Resumable = require('resumablejs');


@Component({
  selector: 'app-attachment-modal',
  templateUrl: './attachment-modal.component.html',
  styleUrls: ['./attachment-modal.component.css']
})
export class AttachmentModalComponent implements OnInit {
  @Input() attachmentInfo;
  public saving = false;
  // r: Resumable;
  r;
  public progress = 0;
  public fileName: string;
  public files: { [key: string]: {filename: string, progress: number} } = {};
  private completedFiles = [];

  constructor(public activeModal: NgbActiveModal, private tokenExtractor: HttpXsrfTokenExtractor, @Inject('environment') private environment) {
  }

  ngOnInit() {
    this.r = new Resumable({
      target: `${this.environment.baseUrl}/${this.attachmentInfo.type}/upload/`,
      withCredentials: true,
      simultaneousUploads: 12,
      chunkSize: 2 * 1024 * 1024,
      headers: {
        'X-CSRFToken': this.tokenExtractor.getToken()
      },
      query: {
        rel_globalid: this.attachmentInfo.foreignKey
      }
    });

    this.r.assignBrowse(document.getElementById('file_button'));
    this.r.assignDrop(document.getElementById('dropTarget'));

    // resumable event listening
    this.r.on('fileAdded', file => {
      this.files[file.uniqueIdentifier] = {filename: file.fileName, progress: 0};
    });
    this.r.on('fileProgress', file => {
      this.files[file.uniqueIdentifier].progress = file.progress() * 100;
    });

    this.r.on('fileSuccess', (file, msg) => {
      if (msg !== 'chunk already exists') {
        // const item = Restangular.restangularizeElement(undefined, JSON.parse(msg), this.attachmentInfo.type);
        this.completedFiles.push(JSON.parse(msg));
      }
    });
    this.r.on('complete', () => {
      this.saving = false;
      this.activeModal.close(this.completedFiles)
    })
  }

  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  public submit() {
    this.saving = true;
    this.r.upload();
  }
}
