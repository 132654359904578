import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service'
import {ToastService} from './toast.service';
import {tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {environment} from "@ng-bootstrap/ng-bootstrap/environment";

@Injectable({providedIn: 'root'})
export class HistoryService {
  isVisible = false;
  index = 0;
  data = null;
  historyIndex: number;

  constructor(public http: HttpClient, public toastService: ToastService, @Inject('environment') private environment) {
  }

  getHistory(item, recordType) {
    const genericService = new BaseService(`${this.environment.baseUrl}/${recordType}`, this.http);
    if (this.isVisible === false) {
      // reset index to start at the end... its the best place to start history
      this.index = 0;

      return genericService.get<any>(`${item.globalid}/history`).pipe(tap(results => {
        this.historyIndex = 0;
        this.data = results;
        if (this.data.length > 0) {
          this.isVisible = true;
        } else {
          this.toastService.warning('No history available.');
        }
      }));
    } else {
      this.isVisible = false;
      return of();
    }
  }
}
