import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-contact-display',
  templateUrl: './contact-display.component.html',
  styleUrls: ['./contact-display.component.scss']
})
export class ContactDisplayComponent implements OnInit {
  @Input() item: any;
  @Input() meta: any;

  organization_display_fields = ['contact_contact_type', 'contact_org_name', 'contact_phone_number', 'contact_fax_number',
    'contact_address_line_1', 'contact_address_line_2', 'contact_city', 'contact_state', 'contact_zip_code',
    'contact_country'];
  contacts_display_fields = [
    'contact_contact_type', 'contact_first_name', 'contact_last_name', 'contact_title', 'contact_division_name',
    'contact_phone_number', 'contact_alternate_phone', 'contact_email_address', 'contact_address_line_1',
    'contact_address_line_2', 'contact_city', 'contact_state', 'contact_zip_code', 'contact_country'
  ];

  constructor() {
  }

  ngOnInit(): void {
  }
}
