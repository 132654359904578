import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit, Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NgbTimeStruct, NgbTypeahead, NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import Point from '@arcgis/core/geometry/Point';
import Map from '@arcgis/core/Map';
import VectorTileLayer from '@arcgis/core/layers/VectorTileLayer';
import MapView from "@arcgis/core/views/MapView";
import ProjectParameters from "@arcgis/core/rest/support/ProjectParameters";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import ZoomViewModel from "@arcgis/core/widgets/Zoom/ZoomViewModel";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import TileLayer from "@arcgis/core/layers/TileLayer";
import {project, load} from "@arcgis/core/geometry/projection";
import {from, merge, Observable, ReplaySubject, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap} from 'rxjs/operators';
import {AsYouTypeFormatter} from 'google-libphonenumber';
import {isPromise} from 'rxjs/internal-compatibility';
import {ToastService} from '../services/toast.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-dynamic-input',
  templateUrl: './dynamic-input.component.html',
  styleUrls: ['./dynamic-input.component.css']
})
export class DynamicInputComponent implements OnInit, OnChanges {
  @Input() meta;
  @Input() item;
  @Input() key;
  @Input() itemIndex;
  @Output() metaChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('instance', {static: true}) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  loadingChoices = false;
  displayValues = {};
  dates = {};
  times = {};
  phoneUtil = new AsYouTypeFormatter('US');
  inputType: ReplaySubject<string> = new ReplaySubject<string>();
  line = new SimpleLineSymbol({
    width: 0.5
  });
  symbol = new SimpleMarkerSymbol({
    color: [85, 255, 0, 1],
    size: 10,
    outline: this.line
  })
  name: string;

  public mapHelp: string;
  public mapIsCollapsed = true;
  public updatingLocation = false;
  private center = [144.8, 13.45];
  private map: Map;
  private view: MapView;
  private zoom: ZoomViewModel;
  private outSR = new SpatialReference({wkid: 4326});
  private params = new ProjectParameters();
  private sketch: SketchViewModel;
  private graphicsLayer: GraphicsLayer;
  // fieldMap = new Map<number, any>();
  private editPoint = new Graphic({symbol: this.symbol});

  constructor(
    @Inject('environment') private environment,
    public toastService: ToastService,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    if (this.meta.fields[this.key].type === 'datetime') {
      if (this.item[this.key] !== undefined && this.item[this.key] !== null) {
        this.dates[this.key] = new Date(this.item[this.key]);
        this.times[this.key] = this.parseTime(this.dates[this.key]);
      } else {
        this.dates[this.key] = null;
      }
    }

    if (this.meta.fields[this.key].default !== undefined && this.item.globalid === undefined) {
      this.item[this.key] = this.meta.fields[this.key].default;
    } else if (this.meta.fields[this.key].hasOwnProperty('style') && this.meta.fields[this.key].style.input_type === 'phone_number') {
      if (this.item[this.key]) {
        this.displayValues[this.key] = this.formatNumber(this.key);
      }
    }

    if (this.meta.fields[this.key].type === 'field' &&
      this.meta.fields[this.key].choices === undefined &&
      this.meta.fields[this.key].path !== undefined) {
      this.http.get<any[]>(`${this.environment.baseUrl}/${this.meta.fields[this.key].path}`).pipe(
        tap(choices => {
          this.meta.fields[this.key].choices = [];
          choices.forEach((choice, i) => {
            this.meta.fields[this.key].choices.push({
              display_name: choice.display_name,
              value: choice.globalid
            });
          });
        }));
    }

    if (this.meta.fields[this.key].type === 'choice' && this.meta.fields[this.key].get_choices !== undefined) {
      this.meta.fields[this.key].get_choices(this.item).then(choices => {
        this.meta.fields[this.key].choices = choices;
        this.setInputType();
      });
    }

    if (this.key === 'shape') {
      this.item.coordinates = {}
      load().then(() => {
        this.loadMap()
        // load map now?
      })
      // todo: this.helpPromise.then(function (response) {
      //   var mapHelp = $filter('filter')(response, {field: 'map'});
      //   if (mapHelp.length > 0) $scope.mapHelp = mapHelp[0].help_text;
      // });
    }
    this.setInputName();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setInputType();
  }

  setInputType() {
    if ((this.meta.fields[this.key].type === 'url' || this.meta.fields[this.key].type === 'string' ||
        this.meta.fields[this.key].type === 'email') &&
      (this.meta.fields[this.key].max_length < 2000 || this.meta.fields[this.key].max_length === undefined) &&
      !(this.meta.fields[this.key].style && this.meta.fields[this.key].style.input_type === 'phone_number') &&
      this.meta.fields[this.key].choices === undefined &&
      this.meta.fields[this.key].select_choice === undefined
    ) {
      this.inputType.next('text');
    } else if ((this.meta.fields[this.key].type === 'url' || this.meta.fields[this.key].type === 'string' ||
        this.meta.fields[this.key].type === 'email' || this.meta.fields[this.key].type === 'choice') &&
      this.meta.fields[this.key].max_length < 2000 &&
      !(this.meta.fields[this.key].style && this.meta.fields[this.key].style.input_type === 'phone_number') &&
      (this.meta.fields[this.key].choices !== undefined || this.meta.fields[this.key].get_choices !== undefined)) {
      this.inputType.next('typeahead');
    } else if (this.meta.fields[this.key].type === 'field' &&
      this.meta.fields[this.key].get_choices !== undefined) {
      this.inputType.next('simple_typeahead');
    } else if (this.meta.fields[this.key].max_length < 2000 &&
      (this.meta.fields[this.key].style && this.meta.fields[this.key].style.input_type === 'phone_number')) {
      this.inputType.next('phone_number');
    } else if ((this.meta.fields[this.key].type === 'string' ||
        (this.meta.fields[this.key].type === 'field' && this.key !== 'shape')) &&
      this.meta.fields[this.key].max_length >= 2000 &&
      this.meta.fields[this.key].get_choices !== undefined && this.meta.fields[this.key].select_choice !== undefined) {
      this.inputType.next('textarea_typeahead');
    } else if ((this.meta.fields[this.key].type === 'string' || this.meta.fields[this.key].type === 'field') &&
      this.meta.fields[this.key].max_length >= 2000 &&
      this.meta.fields[this.key].get_choices === undefined && this.meta.fields[this.key].select_choice === undefined) {
      this.inputType.next('textarea');
    } else if (this.meta.fields[this.key].type === 'decimal' || this.meta.fields[this.key].type === 'float') {
      this.inputType.next('number');
    } else if (this.meta.fields[this.key].type === 'integer') {
      this.inputType.next('integer');
    } else if (this.meta.fields[this.key].type === 'datetime') {
      this.inputType.next('datetime');
    } else if ((this.meta.fields[this.key].type === 'choice' || (this.meta.fields[this.key].type === 'field' &&
        !this.meta.fields[this.key].read_only)) && this.meta.fields[this.key].choices !== undefined &&
      this.meta.fields[this.key].choices.length > 4) {
      this.inputType.next('select');
    } else if ((this.meta.fields[this.key].type === 'choice' || (this.meta.fields[this.key].type === 'field' &&
      !this.meta.fields[this.key].read_only)) && (this.meta.fields[this.key].choices && this.meta.fields[this.key].choices.length <= 4)) {
      this.inputType.next('radio');
    } else if ((this.meta.fields[this.key].type === 'multiple')) {
      this.inputType.next('multi-select');
    } else if (this.meta.fields[this.key].type === 'boolean') {
      this.inputType.next('checkbox');
    } else if (this.meta.fields[this.key].type === 'field') {
      this.inputType.next('text');
    } else if (this.meta.fields[this.key].type === 'attachment') {
      this.inputType.next('attachment');
    }
  }

  formatChoices = (result: any) => {
    if (typeof result === 'string') {
      return result;
    }
    return result.display_name;
  }

  clearTypeahead(key) {
    this.item[this.key] = '';
    if (this.meta.fields[this.key].hasOwnProperty('clear_choice')) {
      this.meta.fields[this.key].clear_choice(this.meta);
    }
    this.meta.fields[this.key].get_choices(this.item).then(choices => {
      this.meta.fields[this.key].choices = choices;
      this.metaChange.emit(this.meta);
    });
    this.focus$.next(null);

  }

  selectTypeahead($event, item, key) {
    const promise = this.meta.fields[this.key].select_choice($event.item, item, this.meta);
    if (isPromise(promise)) {
      promise.then(() => this.metaChange.emit(this.meta));
    } else {
      this.metaChange.emit(this.meta);
    }
    this.item[this.key] = $event.item.display_name;
  }

  getChoices = (key: string) => (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$.pipe(filter(() => !this.item[this.key]));
    return merge(debouncedText$, inputFocus$).pipe(
      tap(() => this.loadingChoices = true),
      switchMap(text => from(this.meta.fields[this.key].get_choices(text))),
      finalize(() => this.loadingChoices = false)
    );
  }

  formatter = (result: string) => result.toUpperCase();


  setTime(time: NgbTimeStruct) {
    this.times[this.key].setHours(time.hour);
    this.times[this.key].setMinutes(time.minute);
  }

  parseTime(date) {
    return {hour: date.getHours(), minute: date.getMinutes()};
  }

  parseDate(date) {
    return {year: date.getFullYear(), month: date.getMonth(), day: date.getDate()};
  }

  formatNumber(key) {
    if (this.displayValues[key] !== undefined) {
      this.item[key] = this.displayValues[key].replace(/[^0-9.+]/g, '');
    }
    return this.phoneUtil.inputDigit(this.item[key]);
  }

// todo: share this between dynamicInput and dynamicModal or more widely?
  private _applyDateTime(key) {
    if (this.meta.fields[key].type === 'datetime') {
      if (this.dates[key]) {
        const tempDatetime = new Date(
          this.dates[key].year,
          this.dates[key].month,
          this.dates[key].day,
          this.times[key].hour,
          this.times[key].minute);
        // tempDatetime.setHours(this.times[key].hour);
        // tempDatetime.setMinutes(this.times[key].minute);
        // this.item[key] = tempDatetime.formattedDate() + 'T' + tempDatetime.formattedTime() + 'Z';
        this.item[key] = tempDatetime.toISOString();
      } else if (this.dates[key] === undefined) {
        this.toastService.warning('Potential date format error. Please check date inputs and try again.');
        // this.saving = false;
      }
    }
  }


  public applyDateTime(key) {
    this._applyDateTime(key);
  }

  setCurrentDatetime(key) {
    const now = new Date();
    this.dates[key] = this.parseDate(now);
    this.times[key] = this.parseTime(now);
    this._applyDateTime(key);
  }

  setManyToMany(e, key, value) {
    if (this.item[key] === undefined) {
      this.item[key] = [];
    }
    const index = this.item[key].indexOf(value);
    if (index > -1) {
      this.item[key].splice(index, 1);
    } else {
      this.item[key].push(value);
    }
  }


  add_many(key) {
    this.meta.fields[key].add_many(this.item.globalid).then(relatedGlobalid => {
      if (!this.item.hasOwnProperty(key)) {
        this.item[key] = [];
      }
      this.item[key].push(relatedGlobalid);
      this.meta.fields[key].get_choices(this.item[key]).then(choices => {
        this.meta.fields[key].choices = choices;
      });
    });
  }

  encodeFile(e, key) {
    const file: File = e.target.files[0];
    this.item[key] = {
      filename: file.name,
      filetype: file.type,
      filesize: file.size
    };
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', () => {
      this.item[key].base64 = (reader.result as string).split(',')[1];
    });
  }

  openFileSelect() {
    window.document.getElementById(this.name).click();
  }

  public zoomIn() {
    this.zoom.zoomIn()
  };

  public zoomOut() {
    this.zoom.zoomOut()
  };

  private locationError(error) {
    //error occurred so stop watchPosition
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("Location not provided");
        break;

      case error.POSITION_UNAVAILABLE:
        console.log("Current location not available");
        break;

      case error.TIMEOUT:
        console.log("Timeout");
        break;

      default:
        console.log("unknown error");
        break;
    }
  }

  private zoomToLocation(location) {
    var pt = new Point({y: location.coords.longitude, x: location.coords.latitude});
    this.view.goTo({target: pt, zoom: 18});
  }

  public zoomToCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.zoomToLocation, this.locationError);
    } else {
      console.log("Browser doesn't support Geolocation.");
    }
  };

  // todo: watch doesn't exist so refactor for ngchanges or other event?
  //   $scope.$watch('item.' + $scope.meta[$scope.key].label_field, function (newValue, oldValue) {
  //   graphicsService.remove(graphic);
  //   if (newValue !== undefined) {
  //     graphic.setAttributes({"label": newValue});
  //     graphicsService.add(graphic);
  //   } else graphic.setAttributes({"label": $scope.type.charAt(0).toUpperCase() + $scope.type.slice(1)});
  // });

  // private zoomToExtent(extent) {
  //   map.setExtent(extent);
  // }

  // $scope.$watch('item.streetaddress', function (newValue, oldValue) {
  //   if ($scope.item.village && newValue !== oldValue) {
  //     var village = $filter('filter')($scope.meta.village.choices, {value: $scope.item.village})[0].display_name;
  //     geocodingService.geocodeAddress(newValue, village).then(zoomToExtent);
  //   }
  // });

  // $scope.$watch('item.village', function (newValue, oldValue) {
  //   if (newValue !== oldValue) {
  //     var address = '',
  //       village = $filter('filter')($scope.meta.village.choices, {value: newValue})[0].display_name;
  //     if ($scope.item.streetaddress) address = $scope.item.streetaddress;
  //     geocodingService.geocodeAddress(address, village).then(zoomToExtent);
  //   }
  // });
  public goHome() {
    this.view.goTo({center: this.center, zoom: 10});
  };

  private onSketchCreate(evt) {
    if (evt.graphic !== null && evt.graphic.geometry !== undefined) {
      this.graphicsLayer.remove(evt.graphic);
      this.editPoint.geometry = evt.graphic.geometry;
      // this.graphicsLayer.add(this.editPoint);
      this.sketch.complete();
      this.updateLatLonText(evt.graphic.geometry as Point);
      this.sketch.update(this.editPoint, {tool: 'move'});
    }
  }

  private onSketchUpdate(evt) {
    if (evt.state === 'complete') {
      // keep sketch on
      this.sketch.update(this.editPoint, {tool: 'move'});
    }
    if (evt.toolEventInfo !== null && evt.toolEventInfo.type === 'move-stop') {
      this.updateLatLonText(evt.graphics[0].geometry as Point);
    }
  }

  // dataflow ... shape from is converted to coordinates (lat/lon),
  // when then coordinates update, the point on the map is updated
  // when the point on the map is updated shape is updated
  // click on the map or moving the point then updates the coordinates, which updates the shape
  // flow is linear in that shape only sets coordinates on load but then shape is updated from point on map
  public updatePointLocation() {
    console.log('lat/lon input change detected', this.item.coordinates.x, this.item.coordinates.y)
    this.updatingLocation = true;
    if (this.item.coordinates.x && this.item.coordinates.y) {
      const tempPoint = new Point({x: this.item.coordinates.x, y: this.item.coordinates.y});
      const outSR = new SpatialReference({wkid: 3857});
      const pt = project(tempPoint, outSR) as Point;
      this.item.shape.x = pt.x;
      this.item.shape.y = pt.y;
      // this.graphicsLayer.remove(this.editPoint);
      this.editPoint.geometry = pt;
      // this.graphicsLayer.add(this.editPoint);

      // featureLayer.graphics[0].setGeometry(pt);
      // editedPoint = featureLayer.graphics[0];
      // featureLayer.applyEdits(null, [featureLayer.graphics[0]], null, function () {
      this.view.goTo({target: pt, zoom: 17});
      this.updatingLocation = false;
      //     if (drawingEnabled) {
      //         drawingEnabled = false;
      //         drawEndConnector.remove();
      //         drawToolbar.deactivate();
      //         map.graphics.clear();
      //     }
      // updateLatLonText(pt);
      // });
    }
  };


  public updateLatLonText(point: Point) {

    const projectedPoint = project(point, new SpatialReference({wkid: 4326})) as Point;
    this.item.coordinates.y = projectedPoint.y;
    this.item.coordinates.x = projectedPoint.x;
    this.updatePointLocation();
  }

  private loadMap() {

    // todo: deal with lack of id var map = new Map('mapDiv' + this.id, {
    this.graphicsLayer = new GraphicsLayer({
      listMode: 'hide'
    });
    this.graphicsLayer.add(this.editPoint);
    let basemap = new TileLayer({url: this.environment.baseMapService});
    let references = new VectorTileLayer({url: this.environment.refrenceMapService});
    this.map = new Map({
      layers: [
        basemap,
        references,
        this.graphicsLayer
      ]
    });
    // this.map.layers.addMany([
    //   basemap,
    //   // references
    // ]);.

    this.view = new MapView({
      map: this.map,
      container: 'mapDiv',
      ui: {components: []},
      spatialReference: {wkid: 3857}
    });
    this.sketch = new SketchViewModel({
      view: this.view,
      layer: this.graphicsLayer,
      updateOnGraphicClick: false,
      defaultUpdateOptions: {
        toggleToolOnClick: false
      }
    });

    if (this.item.shape.y && this.item.shape.x) {
      const point = new Point({
        x: this.item.shape.x,
        y: this.item.shape.y,
        spatialReference: new SpatialReference({wkid: 3857})
      });
      this.updateLatLonText(point);
      this.sketch.update(this.editPoint);
    } else {
        this.sketch.create('point');
    }

    this.view.when(() => {
      this.view.goTo({
        center: this.center,
        zoom: 10
      });

        // this.graphicsLayer.remove(this.editPoint);



    })
    this.zoom = new ZoomViewModel({view: this.view});
    this.sketch.on('create', e => this.onSketchCreate(e));
    this.sketch.on('update', (evt) => this.onSketchUpdate(evt));
  }

  setInputName(): void {
    this.name = this.itemIndex ? `${this.key}_${this.itemIndex}` : this.key;
  }

  public allowBase64(srcString: string): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(srcString)
  }

}
