import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-help-docs-modal',
  templateUrl: './help-docs-modal.component.html',
  styleUrls: ['./help-docs-modal.component.css']
})
export class HelpDocsModalComponent {
  @Input() helpContent: any;
  // todo: fix help dialog to show content
  constructor(public activeModal: NgbActiveModal) { }
}
