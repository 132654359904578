import {HttpClient, HttpParams} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
// import {DataSource} from '@angular/cdk/collections';
import {Observable, BehaviorSubject, ReplaySubject, Subject, forkJoin, of, iif} from 'rxjs';
import {finalize, map, share, switchMap, tap} from 'rxjs/operators';
// import {LoadingService} from './loading.service';
import {merge} from 'lodash';

export interface SearchObject {
  search?: string;
  page?: number;
  page_size?: number | 'max';
  ordering?: string;

  [key: string]: any;
}

export interface Response<Any> {
  count: number;
  results: Any[];
  content_type?: number;
  meta: any;
}

interface FilterOptions {
  [key: string]: ReplaySubject<any>;
}

export class BaseService {
  item: any;
  meta: any = {};
  currentPage: number;
  count: number;
  dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  fullResponse: Subject<any> = new Subject<any>();
  filter: SearchObject = {};
  filterUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  // datasource: BaseDataSource;
  contentType = new ReplaySubject();
  filterOptions: FilterOptions = {};

  constructor(public baseUrl: string, public http: HttpClient, public dynamicFilterPaths: string[] = []) {
    // this.datasource = new BaseDataSource(this);
    // this.filter = {};
    this.setupAllFilterOptions();
    this.filterUpdated.pipe(
      switchMap(updateFilters => forkJoin(of(updateFilters), this._getItems())),
      switchMap(([updateFilters, results]) => iif(() => updateFilters, this.getAllFilterOptions())),
      // finalize(() => this.loadingService.setLoading(false))
    ).subscribe();
    // this.getAllFilterOptions();

  }

  get data(): any[] {
    return this.dataChange.value;
  }

  getList<Any>(searchObject: HttpParams | SearchObject = {}): Observable<Response<Any>> {
    let params;
    if (searchObject instanceof HttpParams) {
      params = searchObject
    } else {
      params = Object.entries(searchObject).reduce((p, [key, value]) => p.set(key, value), new HttpParams());
    }
    return this.http.get<Response<Any>>(`${this.baseUrl}/`,
      {
        params
      }
    ).pipe(
      map(response => {
        if (response.hasOwnProperty('content_type')) {
          this.contentType.next(response.content_type);
          this.contentType.complete();
        }
        this.fullResponse.next(response);
        return response;
      })
    );
  }

  get<Any>(id: string | number) {
    return this.http.get<Any | Response<Any>>(`${this.baseUrl}/${id}/`);
  }

  put<Any>(id: string | number, item: object): Observable<Any> {
    return this.http.put<Any>(`${this.baseUrl}/${id}/`, item);
  }

  customPOST<Any>(id: string | number, item: string | object): Observable<Any> {
    return this.http.post<Any>(`${this.baseUrl}/${id}/`, item);
  }

  post<Any>(item: object, httpOptions = {}): Observable<Any> {
    return this.http.post<Any>(`${this.baseUrl}/`, item, httpOptions).pipe(
      map(newItem => {
        const copiedData = this.data.slice();
        copiedData.push(newItem);
        this.dataChange.next(copiedData);
        return newItem;
      })
    );
  }

  options() {
    return this.http.options<any>(`${this.baseUrl}/`);
  }

  getItems(updateFilterOptions = true) {
    return this.filterUpdated.emit(updateFilterOptions);
  }

  private _getItems(): Observable<any[]> {
    // this.loadingService.setLoading(true);
    return this.getList(this.filter).pipe(
      map(response => {
        this.currentPage = this.filter.page;
        this.count = response.count;
        this.dataChange.next(response.results);
        // this.loadingService.setLoading(false);
        return response.results;
      })
    );
  }

  getPage(event) {
    // this.loadingService.setLoading(true);
    this.filter.page = event.pageIndex + 1;
    this.filter.page_size = event.pageSize;
    this.getItems(false);
  }

  runSearch() {
    // this.loadingService.setLoading(true);
    this.filter.page = 1;
    this.getItems();
  }

  clearSearch() {
    // this.loadingService.setLoading(true);
    this.filter.search = '';
    this.getItems();
  }

  sortTable(event) {
    // this.loadingService.setLoading(true);
    this.filter.page = 1;
    const direction = event.direction === 'desc' ? '-' : '';
    this.filter.ordering = event.direction ? `${direction}${event.active}` : '';
    this.getItems();
  }

  delete(id: string | number) {
    return this.http.delete(`${this.baseUrl}/${id}/`)
      .pipe(
        map(() => {
          const copiedData = this.data.slice();
          const filteredData = copiedData.filter(item => item.id !== id);
          this.dataChange.next(filteredData);
        })
      );
  }

  // accepts a path to query endpoint inside current baseUrl to get dynamic filter options
  // see mines/views.py trusts function; results available at /api/mine/sites/trusts in R9-NAUM project
  getFilterOptions(path: string) {
    return this.http.get<any[]>(`${this.baseUrl}/${path}`, {
      params: Object.entries(this.filter).reduce((params, [key, value]) => params.set(key, value), new HttpParams())
    }).pipe(tap(results => this.filterOptions[path].next(results)));
  }

  setupAllFilterOptions() {
    for (const path of this.dynamicFilterPaths) {
      this.filterOptions[path] = new ReplaySubject<any>();
    }
  }

  getAllFilterOptions() {
    const obs = [];
    for (const path of this.dynamicFilterPaths) {
      obs.push(this.getFilterOptions(path));
    }
    return forkJoin(obs);
  }

  clearFilter(field) {
    delete this.filter[field];
    this.getAllFilterOptions();
  }

  clearAllFilters() {
    for (const key in this.filter) {
      if (key !== 'page' && key !== 'page_size' && key !== 'search') {
        delete this.filter[key];
      }
    }
    this.getItems();
  }

  getAction<Any>(url: string, id?: number) {
    return this.http.get<Any>(`${this.baseUrl}${id ? `/${id}` : ''}/${url}`);
  }

  taskstatus(taskId) {
    console.log('test');
    const url = `${this.baseUrl}/task_status`;
    return this.http.get<any>(url,
      {
        params: {id: taskId}
      }
    )
      .pipe(
        map(response => response),
        share()
      );
  }

  loadData(search: any): Observable<any> {
    throw new Error('Not Implemented');
  }

  loadSubmission(searchParams: any): Observable<any> {
    throw new Error('Not Implemented');
  }

  loadMetadata(force = false): Observable<any> {
    return iif(() => this.meta.loaded !== undefined && !force,
      of(this.meta),
      this.get<any>('metadata')
        .pipe(tap(response => {
          this.meta.loaded = true;
          merge(this.meta, response)
        })));
  }

  save(item) {
    return iif(() => item.hasOwnProperty('globalid'),
      this.put(item.globalid, item),
      this.post(item)
    );
  }
}

// export class BaseDataSource extends DataSource<any> {
//   constructor(private _projectDatabase: BaseService) {
//     super();
//   }
//
//   connect(): Observable<any[]> {
//     return this._projectDatabase.dataChange;
//   }
//
//   disconnect() {
//   }
// }
