import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BehaviorSubject, from, Observable, pipe, ReplaySubject} from 'rxjs';
import {map, share, switchMap, tap} from 'rxjs/operators';
import {fromPromise} from 'rxjs/internal-compatibility';
import * as moment from 'moment';
import {cloneDeep} from 'lodash';
import {BaseService} from './base.service';
import {DynamicModalComponent} from '../modals/dynamic-modal/dynamic-modal.component';

@Injectable({providedIn: 'root'})
export class PaymentsService extends BaseService {
  private _meta = {
    display_fields: ['payment_amount', 'fee_paid_datetime', 'fee_payment_method', 'gepa_receipt_number',
      'check_number', 'tps_receipt_number', 'credit_card_conf_number', 'fee_received_by', 'fee_proof'],
    type: 'payments',
  };
  isLoading = true;
  private paymentsService: BaseService;
  private parentItemService: BaseService;
  public meta: Observable<any>;

  constructor(public http: HttpClient, public modalService: NgbModal, @Inject('environment') private environment) {
    super(`${environment.baseUrl}/payments`, http);
    this.meta = this.get<any>('metadata').pipe(
      map(m => {
        return {...this._meta, ...m}
      }),
      share())
  }

  // get_meta() {
  //   if (!this.isLoading) {
  //     this.isLoading = true;
  //     this.paymentsService.get<any>('metadata').pipe(tap(metadata => {
  //         const meta = {...this._meta, ...metadata};
  //         this.meta.next(meta);
  //       })
  //     );
  //   }
  // }

  public open_modal(item, parentItem, content_type, parentItemService: BaseService) {
    return this.meta.pipe(
      switchMap(meta => {

        if (!item) {
          item = {record_id: parentItem.globalid, content_type};
        } else {
          item.record_id = parentItem.globalid;
          item.content_type = content_type;
        }

        item.fee_amount = parentItem.fee;
        item.fee_balance = parentItem.fee_balance;

        const modalMeta = cloneDeep(meta);
        modalMeta.display_fields.unshift('fee_balance');

        const modalInstance = this.modalService.open(DynamicModalComponent, {size: 'lg', backdrop: 'static'});
        modalInstance.componentInstance.item = item;
        modalInstance.componentInstance.meta = modalMeta;

        return fromPromise(modalInstance.result);
      }),
      switchMap(result => parentItemService.get<any>(parentItem.globalid)),
      tap(response => parentItem.fee_balance = response.result.fee_balance));
  }
}
