import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.css'],
})
export class HelpButtonComponent {
  @ViewChild('content') content;
  @Input() title?;
  @Input() helpText;
  @Input() helpPromise: Promise<any>;
  constructor(private modalService: NgbModal) {}

  open(content) {
    console.log('helpPromise', this.helpPromise)
    this.modalService.open(content, {ariaLabelledBy: 'help-modal-title', centered: true});
  }
}
