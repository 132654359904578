import {Component, OnInit} from '@angular/core';
import {StandbyApplicationComponent} from "../modals/standby-application/standby-application.component";
import {forkJoin, from, iif, of} from "rxjs";
import {finalize, switchMap, tap} from "rxjs/operators";
import {LoadingComponent} from "../modals/loading/loading.component";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ApplicationService} from "../services/application.service";
import {ResumeComponent} from "../modals/resume/resume.component";

@Component({
  selector: 'app-load-application',
  templateUrl: './load-application.component.html',
  styleUrls: ['./load-application.component.scss']
})
export class LoadApplicationComponent implements OnInit {

  constructor(private route: ActivatedRoute, private modalService: NgbModal,
              public applications: ApplicationService, private router: Router) {
  }

  ngOnInit() {
    if (Object.keys(this.applications.attributes).length === 0) {
      this.applications.startApplication(this.route.snapshot.params.type)
    }
  }
}
