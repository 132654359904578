import {Component, Inject, Input, OnInit} from '@angular/core';
import {BaseService} from '../services/base.service';
import {ToastService} from '../services/toast.service';
import {ErrorHandlingService} from '../services/error-handling.service';
import {GenericModalComponent} from '../modals/generic-modal/generic-modal.component';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import TileLayer from '@arcgis/core/layers/TileLayer';
import {load, project} from '@arcgis/core/geometry/projection';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import ZoomViewModel from '@arcgis/core/widgets/Zoom/ZoomViewModel';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import Point from '@arcgis/core/geometry/Point';
import MapImageLayer from '@arcgis/core/layers/MapImageLayer';
import LayerList from '@arcgis/core/widgets/LayerList';
import VectorTileLayer from '@arcgis/core/layers/VectorTileLayer';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import {ParcelService} from '../services/parcel.service';
import {GeocodingService} from '../services/geocoding.service';
import {HttpClient} from '@angular/common/http';
import {MapService} from '../services/map.service';
import {HistoryService} from '../services/history.service';
import _ from 'lodash';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StateService} from '@uirouter/core';
import {from, iif, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged, filter,
  finalize,
  first,
  map,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import LabelClass from '@arcgis/core/layers/support/LabelClass';
import Basemap from '@arcgis/core/Basemap';
import WebMap from '@arcgis/core/WebMap';
import ProjectParameters from "@arcgis/core/rest/support/ProjectParameters";
import TextSymbolProperties = __esri.TextSymbolProperties;
import TextSymbol from '@arcgis/core/symbols/TextSymbol';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';

@Component({
  selector: 'app-map-with-input',
  templateUrl: './map-with-input.component.html',
  styleUrls: ['./map-with-input.component.css']
})
export class MapWithInputComponent implements OnInit {
  @Input() recordType: string;
  @Input() id: string;
  @Input() fields: any[];
  @Input() showStatusInput: boolean;
  // todo: make sure map promise is dead?
  public item: ReplaySubject<any> = new ReplaySubject<any>();
  public loading = true;
  public map_loading = true;
  public coordinates: any = {};
  public history = history;
  public map_locked = true;
  private new_parcel_number = null;
  public useGeocoder: boolean;
  public isNameLoading: boolean;
  public noNameResults: boolean;
  public isAddressLoading: boolean;
  public noAddressResults: boolean;
  public lotIsLoading: boolean;
  public noLotResults: boolean;

  // mapDeferred = $q.defer();
  // public mapPromise = mapDeferred.promise;
  public current_host = window.location.protocol + '://' + window.location.host + (window.location.port !== '80' && window.location.port !== '443' ? ':' + window.location.port : '') + '/s/';
  public host_no_port = window.location.protocol + '://' + window.location.host + '/s/';
  private parcelLabelSymbol = {
    color: 'white',
    backgroundColor: null,
    borderLineColor: null,
    borderLineSize: null,
    verticalAlignment: 'bottom',
    horizontalAlignment: 'center',
    angle: 0,
    xoffset: 0,
    yoffset: 0,
    kerning: true,
    haloColor: 'black',
    haloSize: 1,
    font: {
      family: 'Arial',
      size: 11,
      style: 'normal',
      weight: 'normal',
      decoration: 'none'
    }
  } as TextSymbolProperties;

  private parcelLabel = new LabelClass({
    labelPlacement: 'always-horizontal',
    where: null,
    labelExpressionInfo: {expression: '$feature.Parcel_Sea'},
    useCodedValues: true,
    symbol: new TextSymbol(this.parcelLabelSymbol),
    minScale: 5000,
    maxScale: 0
  });

  private parcelSymbol = new SimpleFillSymbol({
    color: [0, 0, 0, 0],
    style: 'solid',
    outline: {
      color: [133, 133, 133, 255],
      width: 1
    }
  });

  private parcelRenderer = new SimpleRenderer({
    symbol: this.parcelSymbol
  });

  // private basemap = new Basemap({
  //   baseLayers: [
  //     new TileLayer({
  //       url: this.environment.baseMapService,
  //       listMode: 'hide'
  //     })
  //   ]
  // })
  //
  // private references = new VectorTileLayer({
  //   url: 'https://www.arcgis.com/sharing/rest/content/items/af6063d6906c4eb589dfe03819610660/resources/styles/root.json',
  //   listMode: 'hide'
  // });
  private graphicsLayer = new GraphicsLayer({
    listMode: 'hide'
  });
  private featureLayer = new FeatureLayer({
    url: this.environment.sitesService,
    visible: false,
    title: 'Sites'
  });
  private parcelLayer = new FeatureLayer({
    // url: 'http://gis.guam.gov/arcgis/rest/services/EnvPro/EnvPro_LandParcels/MapServer/13',
    url: this.environment.parcelLayerService,
    visible: true,
    minScale: 6000,
    outFields: ['*'],
    labelingInfo: [this.parcelLabel],
    title: 'Parcels',
    renderer: this.parcelRenderer
  });

  private mapDefault = {center: this.environment.default_map_center, zoom: 10};

  // private nhdLayer = new MapImageLayer({
  //   url: 'https://hydro.nationalmap.gov/arcgis/rest/services/nhd/MapServer',
  //   visible: true,
  //   title: 'National Hydrography Dataset'
  // });
  //
  // private wetlands = new MapImageLayer({
  //   url: 'https://www.fws.gov/wetlandsmapservice/rest/services/Wetlands/MapServer',
  //   visible: true
  // })
  //
  // private fema = new MapImageLayer({
  //   url: 'https://hazards.fema.gov/gis/nfhl/rest/services/public/NFHL/MapServer',
  //   visible: true,
  //   // sublayers: [
  //   //   {id: 31, visible: false},
  //   //   {id: 30, visible: false},
  //   //   {id: 26, visible: false},
  //   //   {id: 13, visible: false},
  //   //   {id: 12, visible: false},
  //   //   {id: 11, visible: false},
  //   //   {id: 10, visible: false},
  //   //   {id: 9, visible: false},
  //   //   {id: 8, visible: false},
  //   //   {id: 6, visible: false},
  //   //   {id: 4, visible: false},
  //   //   {id: 25, visible: false},
  //   //   {id: 28, visible: false},
  //   //   {id: 27, visible: false},
  //   //   {id: 19, visible: false},
  //   //   {id: 5, visible: false},
  //   //   {id: 32, visible: false},
  //   //   {id: 7, visible: false},
  //   //   {id: 24, visible: false},
  //   //   {id: 18, visible: false},
  //   //   {id: 15, visible: false},
  //   //   {id: 29, visible: false},
  //   //   {id: 23, visible: false},
  //   //   {id: 16, visible: false},
  //   //   {id: 14, visible: false},
  //   //   {id: 20, visible: false},
  //   //   {id: 17, visible: false},
  //   //   {id: 22, visible: false},
  //   //   {id: 34, visible: false},
  //   //   {id: 1, visible: false},
  //   //   {id: 3, visible: false},
  //   //   {id: 0, visible: false}
  //   // ]
  // })

  private gwa = new MapImageLayer({
    url: this.environment.gwaService,
    visible: true
  });
  // private gpz = new MapImageLayer({url: 'https://gis.epa.guam.gov/arcgis/rest/services/Public/GroundwaterProtectionZone/MapServer'})

  private map: WebMap;
  private view: MapView;
  private zoom: ZoomViewModel;
  private outSR = new SpatialReference({wkid: 4326});
  private params = new ProjectParameters();
  private sketch: SketchViewModel;

  private drawingEnabled;
  private drawEndConnector;
  private editPoint: __esri.Graphic = new Graphic();
  private saveCanceled = false;
  private originalObject;
  private originalCoords;
  private pointX;
  private pointY;
  private symbol;
  private navigationListener = [];
  private navigationDisabled = true;
  public viewMode = false;
  public isCollapsed = true;

  siteService: BaseService;

  constructor(public toast: ToastService, public parcelService: ParcelService, public geocodingService: GeocodingService,
              public errorHandling: ErrorHandlingService, public http: HttpClient, private mapService: MapService,
              public historyService: HistoryService, private modalService: NgbModal, private $state: StateService,
              @Inject('environment') private environment) {

  }

  ngOnInit() {
    this.useGeocoder = this.environment.geocoder_url !== null;
    this.mapService.setGraphicsLayer(this.graphicsLayer);

    this.map = new WebMap({portalItem: {id: '4808044fb5404c5f977eb4aad2abc98f'}})
    this.map.when(() => {
      this.map.addMany([
        this.parcelLayer,
        this.gwa,
        this.featureLayer,
        this.graphicsLayer
      ])
    });

    this.view = new MapView({
      map: this.map,
      container: 'mapDiv',
      ui: {components: []},
      spatialReference: {wkid: 3857}
    });

    this.zoom = new ZoomViewModel({view: this.view});
    this.siteService = new BaseService(`${this.environment.baseUrl}/${this.recordType}`, this.http);
    this.mapService.setMap(this.map);

    // tslint:disable-next-line:no-unused-expression
    new LayerList({
      view: this.view,
      container: 'layerList'
    });

    this.sketch = new SketchViewModel({
      view: this.view,
      layer: this.graphicsLayer,
      updateOnGraphicClick: false,
      defaultUpdateOptions: {
        toggleToolOnClick: false
      }
    });

    if (this.id !== 'new') {
      this.siteService.get(this.id).pipe(
        tap((item) => this.item.next(item)),
        finalize(() => this.loading = false)
      ).subscribe();
    } else {
      this.viewMode = !this.viewMode;
      this.siteService.loadMetadata().pipe(
        tap((meta) => {
          const item = {meta, result: {shape: {}}};
          this.item.next(item);
        }),
        finalize(() => this.loading = false)).subscribe();
    }
    this.view.when(() => {
      this.view.goTo({
        center: this.environment.default_map_center,
        zoom: 10
      });
      const el = document.querySelector('.esri-view-surface > canvas');
      el.addEventListener('wheel', (e) => {
        if (this.navigationDisabled) {
          e.stopPropagation();
        }
        if (this.navigationDisabled) {
          e.stopPropagation();
        }
      });
    });
    this.sketch.on('create', e => {
      this.graphicsLayer.remove(e.graphic);
      this.addToMap(e)
    });
    this.sketch.on('update', (evt) => {
      if (evt.state === 'complete' && this.viewMode) {
        this.sketch.update(this.editPoint, {tool: 'move'});
      }
      if (evt.toolEventInfo !== null && evt.toolEventInfo.type === 'move-stop') {
        this.updateLatLonText();
      }
    });

    this.view.when(() => {
      if (this.id !== 'new') {
        this.disableMapNavigation();
      } else {
        this.sketch.create('point');
        this.drawingEnabled = true;
      }


      this.map_loading = false;

    });

    this.featureLayer.when(() => {
      const renderer = this.featureLayer.renderer as __esri.SimpleRenderer;
      this.symbol = renderer.symbol;
      this.editPoint = new Graphic();
      this.editPoint.symbol = this.symbol;
      this.item.pipe(first(),
        tap(item => {
          if (item.result.shape.x !== undefined || item.result.shape.y !== undefined) {
            this.editPoint.geometry = new Point({
              x: item.result.shape.x,
              y: item.result.shape.y,
              spatialReference: {wkid: 3857}
            });
            this.graphicsLayer.add(this.editPoint);
            this.centerMapAndZoom();
            this.updateLatLonText();
          }
        })).subscribe();
    });

    // @ts-ignore
    this.featureLayer.on('graphic-add', (evt) => {
      this.editPoint = evt.graphic;

      if (this.editPoint.geometry !== null) {
        this.centerMapAndZoom();
        this.updateLatLonText();
      }
    });

  }

  public copySuccess() {
    this.toast.success('Link copied!');
  }

  public copyError() {
    this.toast.error('Error copying link!');
  }


  private addToMap(evt) {
    if (evt.graphic !== null && evt.graphic.geometry !== undefined) {
      this.editPoint.geometry = evt.graphic.geometry;
      this.graphicsLayer.add(this.editPoint);
      this.sketch.complete();
      this.updateLatLonText();
      this.sketch.update(this.editPoint, {tool: 'move'});
      this.drawingEnabled = false;
    }
  }

  private updatePointLocation(item) {
    return from(load().then(() => {
      console.log('updatePointLocation', item);
      if (Object.keys(this.coordinates).length !== 0) {

        const tempPoint = new Point({x: this.coordinates.longitude, y: this.coordinates.latitude});
        const outSR = new SpatialReference({wkid: 3857});
        this.params.geometries = [tempPoint];
        this.params.outSpatialReference = outSR;
        const pt = project(tempPoint, {wkid: 3857}) as __esri.Point;
        this.editPoint.geometry = pt as __esri.Geometry;
        if (this.drawingEnabled) {
          this.graphicsLayer.add(this.editPoint);
          this.sketch.complete();
          this.sketch.update(this.editPoint, {tool: 'move'});
          this.drawingEnabled = false;
        }
        item.result.shape = {x: pt.x, y: pt.y};
      }
    }));
  }

  private centerMapAndZoom() {
    if (this.editPoint === undefined) {
      this.view.goTo({
        target: this.mapDefault.center,
        zoom: this.mapDefault.zoom
      });
    } else {
      this.view.goTo({
        target: this.editPoint,
        zoom: 17
      });
    }
  }


  private disableMapNavigation() {
    this.navigationDisabled = true;
    this.navigationListener.push(this.view.on('drag', function (event) {
      event.stopPropagation();
    }));
    this.navigationListener.push(this.view.on('key-down', function (event) {
      const prohibitedKeys = ['+', '-', 'Shift', '_', '='];
      const keyPressed = event.key;
      if (prohibitedKeys.indexOf(keyPressed) !== -1) {
        event.stopPropagation();
      }
    }));
    this.navigationListener.push(this.view.on('double-click', function (event) {
      event.stopPropagation();
    }));
  }

  private enableMapNavigation() {
    this.navigationDisabled = false;
    this.navigationListener.forEach(listener => {
      listener.remove();
    });
    this.navigationListener.length = 0;
  }

  private updateParcelNumber(geometry) {
    this.lotIsLoading = true;
    this.item.pipe(first(),
      switchMap(item =>
        this.parcelService.queryParcelLayer(undefined, geometry).then((results) => {
          if (results.length !== 0 && item.result.parcel_id !== results[0].attributes.Parcel_Sea) {
            const confirmDialog = this.modalService.open(GenericModalComponent);
            confirmDialog.componentInstance.title = 'Please Confirm';
            confirmDialog.componentInstance.text = 'Do you want to update the parcel number based on the location of this site?';
            confirmDialog.result.then(() => {
              item.result.parcel_id = results[0].attributes.Parcel_Sea;
              if (!this.viewMode) {
                this.siteService.put(item.result.globalid, item).pipe(catchError(error => {
                  this.viewMode = true;
                  return this.errorHandling.fieldError(error, item.meta);
                }));
                // this.item.save().catch((error) => {
                //   this.viewMode = true;
                //   this.errorHandling.fieldError(error, this.item.meta);
                // });
              }
            }).catch(e => {
            });
          }
          // this is causing it to always update.
          // else {
          //   if (results.length > 0) {
          //     this.item.parcel_id = results[0].attributes.Parcel_Search_Field;
          //     if (!this.viewMode) this.save();
          //   }
          // }
          this.lotIsLoading = false
        }))).subscribe();
  }

  private updateLatLonText() {
    this.mapService.setCurrentLocation(this.editPoint.geometry);
    this.mapService.setView(this.view);
    if (this.viewMode) this.updateParcelNumber(this.editPoint.geometry);
    this.params.geometries = [this.editPoint.geometry];
    this.params.outSpatialReference = this.outSR;
    load().then(() => {
      const pt = project(this.editPoint.geometry, {wkid: 4326}) as __esri.Point;
      this.coordinates = {latitude: pt.y.toFixed(6), longitude: pt.x.toFixed(6)};
    });
  }


  public save(item) {

    this.errorHandling.clearError(item.meta);
    return this.updatePointLocation(item).pipe(switchMap(() => {
      if (Object.keys(item.result.shape).length === 0 || item.result.shape === undefined) {
        this.toast.warning('Please add a location before saving.');
        return of();
      } else {
        return iif(() => item.result.globalid === undefined,
          this.siteService.post<any>(item.result).pipe(tap(response => this.$state.go('root.site.view', {id: response.globalid}))),
          this.siteService.put<any>(item.result.globalid, item.result).pipe(tap(i => {
            if (i.site_id) {
              item.result.site_id = i.site_id;
            }
            this.viewMode = false;
            this.sketch.complete();
            // this.toggleMapLock(item)
            this.map_locked = true;
            this.disableMapNavigation();
            this.centerMapAndZoom();
            this.historyService.isVisible = false;
            item.result.last_edited_date = i.last_edited_date;
            // this.item.next(item);
          }))).pipe(catchError(error => this.errorHandling.fieldError(error, item.meta)));
      }
    })).subscribe();
  }

  public cancel(item) {
    if (this.id === 'new') {
      this.$state.go('root.site.list');
      // window.location = '/site/list';
    } else {
      item.result = this.originalObject.result;
      this.coordinates = _.clone(this.originalCoords);
      this.updatePointLocation(item).pipe(tap(() => {
        this.centerMapAndZoom();
      })).subscribe();
      this.viewMode = false;
      this.sketch.cancel();
      this.disableMapNavigation();
      this.map_locked = true;
      this.errorHandling.clearError(item.meta);
    }
  }

  public edit(item) {
    this.enableMapNavigation();
    this.map_locked = false;
    this.originalObject = _.merge({}, item);
    this.originalCoords = _.clone(this.coordinates);

    if (this.editPoint.geometry !== undefined && this.editPoint.geometry !== null) {
      this.sketch.update(this.editPoint, {tool: 'move'});
    } else {
      this.sketch.create('point');
    }
    this.viewMode = true;
  }


  public zoomIn() {
    this.zoom.zoomIn();
  }

  public zoomOut() {
    this.zoom.zoomOut();
  }

  public toggleMapLock(item) {
    if (!this.map_locked) {
      this.map_locked = true;
      if (item.result.shape.x !== undefined || item.result.shape.y !== undefined) {
        this.editPoint.geometry = new Point({
          x: item.result.shape.x,
          y: item.result.shape.y,
          spatialReference: {wkid: 3857}
        });
        this.graphicsLayer.add(this.editPoint);
        this.centerMapAndZoom();
        this.updateLatLonText();
      }
      this.disableMapNavigation();
    } else {
      this.map_locked = false;
      this.enableMapNavigation();
    }
  }

  public scrollToRelated() {
    document.querySelector('#expandCollapse').scrollIntoView();
  }

  public updateParcel(item, $event) {
    if (Object.keys(this.coordinates).length === 0) {
      this.coordinates.latitude = $event.item.geometry.centroid.y;
      this.coordinates.longitude = $event.item.geometry.centroid.x;
      this.updatePointLocation(item).pipe(tap(() => {
        this.centerMapAndZoom();
      })).subscribe();
    } else {
      const confirmDialog = this.modalService.open(GenericModalComponent);
      confirmDialog.componentInstance.title = 'Please Confirm';
      confirmDialog.componentInstance.text = 'Do you want to update the site location to the center of this parcel?';
      confirmDialog.result.then(() => {
        this.coordinates.latitude = $event.item.geometry.centroid.y;
        this.coordinates.longitude = $event.item.geometry.centroid.x;
        item.result.parcel_id = $event.item.display_name;
        this.updatePointLocation(item).pipe(tap(() => {
          this.centerMapAndZoom();
        })).subscribe();
      }).catch(e => {
      });
    }
  }


  public selectAddress(item, $event) {
    console.log('select address', item);
    // item.result.streetaddress = $event.item.attributes.StAddr;
    const village = item.meta.fields.village.choices.find(c => c.display_name.includes($event.item.attributes.City));
    if (village) {
      item.result.village = village.value;
    }
    // this.item.next(item);
    const confirmDialog = this.modalService.open(GenericModalComponent);
    confirmDialog.componentInstance.title = 'Please confirm';
    confirmDialog.componentInstance.text = 'Do you want to update the site location to this address';
    return from(confirmDialog.result).pipe(
      tap(() => {
        this.coordinates.latitude = $event.item.location.y;
        this.coordinates.longitude = $event.item.location.x;
        item.result.horizontal_collection_method = 'urban-address-match';
        item.result.streetaddress = $event.item.address;
      }),
      switchMap(() => this.updatePointLocation(item)),
      tap(() => this.centerMapAndZoom())).subscribe();
  }

  public selectName(item, $event) {
    const results = $event.item.text.split(', ');
    item.result.name = results[0];

    const village = item.meta.fields.village.choices.find(c => c.display_value === results.reverse()[2]);
    if (village) {
      item.result.village = village[0].value;
    }

    if (results.length >= 5) {
      item.result.streetaddress = results[3];
    }

    return this.geocodingService.geocodeAddress($event.item.text, null, $event.item.magicKey).pipe(
      switchMap((r) => {
        const confirmDialog = this.modalService.open(GenericModalComponent);
        confirmDialog.componentInstance.title = 'Please confirm';
        confirmDialog.componentInstance.text = 'Do you want to update the site location to this address';
        return from(confirmDialog.result);
      }),
      tap(r => {
        this.coordinates.latitude = r[0].location.y;
        this.coordinates.longitude = r[0].location.x;
        item.result.horizontal_collection_method = 'urban-address-match';
      }),
      switchMap(() => this.updatePointLocation(item)),
      tap(() => this.centerMapAndZoom()));

  }

  // todo: deal with loading indicator
  // geocodeAddress = (key: string) => (text$: Observable<string>) => {
  //   this.item.pipe(first(),
  //     switchMap(item => {
  //     const debouncedText$ = text$.pipe(
  //       debounceTime(200), distinctUntilChanged(), switchMap(() => this.item)
  //     );
  //     return this.geocodingService.geocodeAddress(debouncedText$, item.village);
  //   }));
  // }

  public nameLookupFormatter = (result: any) => typeof result === 'string' ? result : result.text;

  public nameSearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(300),
    tap(() => {
      this.isNameLoading = true;
      this.noNameResults = false;
    }),
    switchMap(() => this.item.pipe(first())),
    filter(i => {
      if (i.result.name) {
        return true;
      }
      this.isNameLoading = false;
      return false;
    }),
    switchMap(i => this.geocodingService.suggestAddress(i.result.name, i.result.village)),
    tap(r => {
      this.noNameResults = r.length === 0;
      this.isNameLoading = false;
    })
  )

  public addressFormatter = (result: any) => typeof result === 'string' ? result : result.address

  public addressInputFormatter = (result: any) => typeof result === 'string' ? result : result.attributes.StAddr

  public findAddress = (address: Observable<string>) => address.pipe(
    debounceTime(300),
    tap(() => {
      this.isAddressLoading = true;
      this.noAddressResults = false;
    }),
    switchMap(() => this.item.pipe(first())),
    filter(i => {
      if (i.result.streetaddress) {
        return true;
      }
      this.isAddressLoading = false;
      return false;
    }),
    switchMap(item => {
      let village;
      if (item.result.village) {
        village = item.meta.fields.village.choices.find(c => c.value === item.result.village).display_name;
      }
      return this.geocodingService.geocodeAddress(item.result.streetaddress, village);
      // .then(function (response) {
      // mapService.zoomToExtent(response[0].extent);
      // });
    }),
    tap(r => {
      this.noNameResults = r.length === 0;
      this.isAddressLoading = false;
    })
  )

  public parcelLookupFormatter = (result: any) => typeof result === 'string' ? result : result.attributes.Parcel_Sea;

  public parcelSearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(300),
    tap(() => {
      this.lotIsLoading = true;
      this.noLotResults = false;
    }),
    switchMap(() => this.item.pipe(first())),
    filter(i => {
      if (i.result.parcel_id) {
        return true;
      }
      this.lotIsLoading = false;
      return false;
    }),
    switchMap(i => this.parcelService.search(i.result.parcel_id)),
    tap(r => {
      this.noLotResults = r.length === 0;
      this.lotIsLoading = false;
    })
  )

  copyToClipboard(url: string) {
    try {
      navigator.clipboard.writeText(url)
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
  }
}
