import {Component, Inject, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BehaviorSubject, forkJoin, throwError, timer} from 'rxjs';
import {catchError, filter, finalize, flatMap, switchMap, take, tap} from 'rxjs/operators';
import merge from 'lodash/merge';
import {BaseService} from '../../services/base.service';
import {ToastService} from '../../services/toast.service';
import {ErrorHandlingService} from '../../services/error-handling.service';
import {GenericModalComponent} from '../../modals/generic-modal/generic-modal.component';
import {DynamicModalComponent} from '../../modals/dynamic-modal/dynamic-modal.component';
import {NotesModalComponent} from '../../modals/notes-modal/notes-modal.component';
import {ContactsModalComponent} from '../../modals/contacts-modal/contacts-modal.component';


@Component({
  selector: 'app-nested-inline',
  templateUrl: './nested-inline.component.html',
  styleUrls: ['./nested-inline.component.css']
})
export class NestedInlineComponent implements OnInit {
  public IsCollapsed = true;
  public itemLoading: any[] = [];
  public _meta: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private hadError = false;
  private saving = false;
  private task;
  public surveyLoading = false;

  @Input() set meta(value) {
    this._meta.next(value);
  }
  get meta() {
    return this._meta.getValue();
  }
  private groupsService: BaseService;
  public genericService: BaseService;
  @Input() items: { [key: string]: any; }[];
  @Input() parentItem: { [key: string]: any; };
  public loading: boolean;
  @Input() foreignKey: string;
  @Input() sorting: string;
  private showLocationEditButton: boolean;
  private initialPageSize: number;
  public loadingMore: boolean;
  private currentPage = 1;
  private samplingGroups: any[];

  constructor(public http: HttpClient, private modalService: NgbModal, public toastr: ToastService,
              private errorHandling: ErrorHandlingService, @Inject('environment') public environment) {
  }

  ngOnInit() {
    this.genericService = new BaseService(`${this.environment.baseUrl}/${this.meta.type}`, this.http);
    if (this.meta !== null) {
      if (!this.meta.isLoading) {
        this.meta.isLoading = true;
        this.genericService.loadMetadata().pipe(
          tap(metadata => {
            merge(this.meta, metadata);
            this.meta.isLoading = false;
          })).subscribe();
      }
      if (this.meta.featureService !== undefined) {
        this.getItems();
      }
    }
  }

  getItems(force = false) {
    if (this.items === undefined || force) {
      this.loading = true;

      if (this.items !== undefined && this.foreignKey === undefined) {
        this.foreignKey = '';
      }

      if (this.sorting === 'Unassigned') {
        this.genericService.getList({page_size: 1000}).pipe(
          tap(response => {
            let item: any;
            item = response.results;
            this.items = item.filter(value => value.group === null || value.sampling_group === 'Unassigned'
              || value.sampling_group === null);
            if (this.items.length > 0 && this.items[0].hasOwnProperty('shape')) {
              this.showLocationEditButton = true;
              // this.displayShapes(this.items);
            } else if (!force) {
              this.IsCollapsed = !this.IsCollapsed;
            }
            this.initialPageSize = this.items.length;
            this.meta = merge(this.meta, response.meta);

          }),
          finalize(() => this.loading = false)).subscribe();
      } else if (this.meta.locations) {
          this.genericService.getList({search: this.foreignKey}).pipe(
            tap(response => {
              this.items = response.results;
              // @ts-ignore
              const index = response.results.findIndex(x => x.groupname === 'Unassigned');
              if (index === -1) {
                this.items.push({groupname: 'Unassigned'});
              }
              if (this.items.length > 0 && this.items[0].hasOwnProperty('shape')) {
                this.showLocationEditButton = true;
                // this.displayShapes(this.items);
              } else if (!force) {
                this.IsCollapsed = !this.IsCollapsed;
              }
              this.initialPageSize = this.items.length;
              this.meta = merge(this.meta, response.meta);
            }),
            finalize(() => this.loading = false)).subscribe();
        } else {
          this.genericService.getList({search: this.foreignKey}).pipe(
            tap(response => {
              this.items = response.results;

              if (this.items.length > 0 && this.items[0].hasOwnProperty('shape')) {
                this.showLocationEditButton = true;
            // this.displayShapes(this.items);
              } else if (!force) {
                this.IsCollapsed = !this.IsCollapsed;
              }
              this.initialPageSize = this.items.length;

              this.meta = merge(this.meta, response.meta);
            }),
            finalize(() => this.loading = false)).subscribe();
        }
    } else {
      this.IsCollapsed = !this.IsCollapsed;
    }
  }

  open(foreignKey, item, event?) {
    // this.meta.display_fields.forEach(field => {
    //   if (this.meta.fields.hasOwnProperty(field) && this.meta.fields[field].hasOwnProperty('get_choices')) {
    //     this.meta.fields[field].get_choices(item).then(choices => {
    //       this.meta.fields[field].choices = choices;
    //     });
    //   }
    // });
    if (event) {
      event.stopPropagation();
    }
    const originalLocation = {...item.shape};
    if (this.items === undefined) {
      this.getItems();
    } else {
      this.IsCollapsed = false;
    }

    let modalInstance;
    if (this.meta.type.indexOf('note') > -1 || this.meta.type.indexOf('comments') > -1) {
      modalInstance = this.modalService.open(NotesModalComponent, {backdrop: 'static', size: 'lg'})
    } else if (this.meta.type.indexOf('contact') > -1) {
      modalInstance = this.modalService.open(ContactsModalComponent, {backdrop: 'static', size: 'lg'})
    } else {
      modalInstance = this.modalService.open(DynamicModalComponent, {backdrop: 'static', size: 'lg'})
    }

    item[this.meta.foreign_key_field] = this.foreignKey;
    modalInstance.componentInstance.item = item;
    modalInstance.componentInstance.meta = this.meta;
    modalInstance.result.then(response => {
      this.getItems(true);
      if (response !== undefined && response.newItem !== undefined) {

        // this.items.forEach((thisItem, i) => {
        //   if (thisItem.globalid === response.newItem.globalid) {
        //     this.items.splice(i, 1);
        //   }
        // });
        // this.items.unshift(response.newItem);
        const reusedItem = Object.assign({}, response.newItem);
        if (response.addAnother !== undefined && response.addAnother === true) {
          delete reusedItem.globalid;
          this.meta.add_another_reset.forEach(field => {
            delete reusedItem[field];
          });
          this.open(foreignKey, reusedItem);
        }
      }
      if (this.meta.hasOwnProperty('refreshProperties')) {
        this.meta.refreshProperties(this.parentItem);
      }


      // if (stringify(originalLocation) !== stringify(item.shape)) {
      //   mapService.replaceGraphic(item.globalid, item.shape);
      // }
    }).catch(() => null);
  }

  delete(item) {
    const modalInstance = this.modalService.open(GenericModalComponent);
    modalInstance.componentInstance.text = this.meta.deleteText;
    modalInstance.componentInstance.title = 'Please confirm';
    modalInstance.result.then(result => {
      this.genericService.delete(item.globalid).subscribe(() => {
        const index = this.items.indexOf(item);
        this.items.splice(index, 1);
        this.meta.count--;
        if (this.meta.hasOwnProperty('refreshProperties')) {
          this.meta.refreshProperties(this.parentItem);
        }
      });
    });
  }

  get(item, index) {
    this.itemLoading[index] = true;
    const promise = this.meta.print.get(item);
    if (promise !== undefined) {
      promise.catch(() => {
        this.itemLoading[index] = false;
      }).finally(() => {
        this.itemLoading[index] = false;
      });
    }
  }

  loadMore() {
    this.loadingMore = true;
    this.currentPage += 1;
    this.genericService.getList({
      search: this.foreignKey,
      page_size: this.initialPageSize,
      page: this.currentPage
    }).pipe(tap(response => {
        response.results.forEach(item => {
          this.items.push(item);
        });
      }),
      finalize(() => {
        this.loadingMore = false;
      })).subscribe();
  }

  public drop(oldPriority, newPriority, items, event: CdkDragDrop<string[]>) {
    if (this.saving) {
      this.toastr.warning('Please wait until previous edit is saved.');
    } else {
      this.saving = true;
      const promises = [];
      this.errorHandling.customErrorMessage = true;
      if (event.previousContainer.id === event.container.id) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        items.forEach((item) => {
          if (event.item.data.globalid === item.globalid) {
            item[this.meta.sort_attribute] = newPriority;
            promises.push(this.genericService.put(item.globalid, item).pipe(catchError((error: any) => {
              if (error) {
                this.hadError = true;
                return throwError(error);
              } else {
                this.hadError = false;
              }
            })));
          } else if (item[this.meta.sort_attribute] <= newPriority && item[this.meta.sort_attribute] > oldPriority) {
            item[this.meta.sort_attribute]--;
            promises.push(this.genericService.put(item.globalid, item).pipe(catchError((error: any) => {
              if (error) {
                this.hadError = true;
                return throwError(error);
              } else {
                this.hadError = false;
              }
            })));
          } else if (item[this.meta.sort_attribute] >= newPriority && item[this.meta.sort_attribute] < oldPriority) {
            item[this.meta.sort_attribute]++;
            promises.push(this.genericService.put(item.globalid, item).pipe(catchError((error: any) => {
              if (error) {
                this.hadError = true;
                return throwError(error);
              } else {
                this.hadError = false;
              }
            })));
          }
        });
      } else {
        event.item.data.group = event.container.id;
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
        items.forEach((item) => {
          if (event.item.data.globalid === item.globalid) {
            item[this.meta.sort_attribute] = newPriority;
            promises.push(this.genericService.put(item.globalid, item).pipe(catchError((error: any) => {
              if (error) {
                this.hadError = true;
                return throwError(error);
              } else {
                this.hadError = false;
              }
            })));
          } else if (item[this.meta.sort_attribute] === newPriority || item[this.meta.sort_attribute] > newPriority) {
            item[this.meta.sort_attribute]++;
            promises.push(this.genericService.put(item.globalid, item).pipe(catchError((error: any) => {
              if (error) {
                this.hadError = true;
                return throwError(error);
              } else {
                this.hadError = false;
              }
            })));
          }
        });
        const prevItems = [];
        event.previousContainer.data.forEach((item) => {
          prevItems.push(item);
        });
        prevItems.forEach((item) => {
          if (item[this.meta.sort_attribute] === oldPriority || item[this.meta.sort_attribute] > oldPriority) {
            item[this.meta.sort_attribute]--;
            promises.push(this.genericService.put(item.globalid, item).pipe(catchError((error: any) => {
              if (error) {
                this.hadError = true;
                return throwError(error);
              } else {
                this.hadError = false;
              }
            })));
          }
        });
      }
      forkJoin(promises).subscribe(() => {
          this.toastr.success('Saved');
          this.saving = false;
          this.errorHandling.customErrorMessage = false;
        },
        () => {
          this.toastr.error('Error encountered');
          this.saving = false;
          this.errorHandling.customErrorMessage = false;
        });
    }
  }
    submitSurveyFeatures(surveyItems) {
    if (surveyItems !== undefined) {
      this.genericService.customPOST('submit_survey', surveyItems).pipe(tap((response) => {
          this.task = response;
          this.surveyLoading = true;
        }),
        flatMap(() => this.getTaskStatus(this.task))).subscribe();
    } else {
      this.toastr.warning('Error encountered'), this.loading = false;
    }
  }
  getTaskStatus(taskId) {
      // const taskURL = this.taskService.taskstatus(this.task);
      return timer(0, 1000).pipe(
        switchMap(_ => this.genericService.customPOST('task_status', {id: taskId.response.id})),
        filter(response => response[0] === true),
        take(1),
        catchError(((error: any) => {
          if (error) {
            this.hadError = true;
            return throwError(error);
          } else {
            this.hadError = false;
          }
        })),
        finalize(() => {this.toastr.success('Successfully sent survey templates.'),  this.surveyLoading = false; }));
  }

  // private displayShapes(items) {
  //   loadModules(['esri/geometry/SpatialReference', 'esri/Graphic', 'esri/geometry/Point', 'esri/symbols/SimpleMarkerSymbol',
  //     'esri/symbols/SimpleLineSymbol', 'esri/Color', 'esri/layers/FeatureLayer', 'esri/symbols/TextSymbol',
  //     'esri/symbols/Font', 'esri/layers/support/LabelClass']).then(
  //     ([SpatialReference, Graphic, Point, SimpleMarkerSymbol, SimpleLineSymbol, Color, FeatureLayer, TextSymbol, Font, LabelClass]) => {
  //
  //       mapService.getMap().then(function (map) {
  //
  //         // this.applications.meta.fields.generators.featureService = 'https://gis.epa.guam.gov/arcgis/rest/services/StandbyGeneratorsApplications/FeatureServer/0';
  //         const featureLayer = new FeatureLayer(this.meta.featureService, {
  //           outFields: ['*'],
  //           showLabels: true
  //         });
  //         featureLayer.on('load', function () {
  //
  //           const font = new Font();
  //           font.setWeight(Font.WEIGHT_BOLD);
  //           font.setSize(16);
  //
  //           items.forEach((item, i) => {
  //             const textSym = new TextSymbol();
  //             textSym.setKerning(true);
  //             textSym.setOffset(5, 1);
  //             textSym.setVerticalAlignment('middle');
  //             textSym.setHorizontalAlignment('justify');
  //             textSym.setColor(new Color(this.meta.label_color));
  //             textSym.setFont(font);
  //             this.meta.symbol = featureLayer.renderer.symbol;
  //             const geometry = new Point(item.shape.x, item.shape.y, new SpatialReference(3857));
  //             const graphic = new Graphic(geometry, featureLayer.renderer.symbol, {globalid: item.globalid});
  //             // this.applications.item.generators[i].graphic = graphic;
  //             // item.graphic = graphic;
  //             map.graphics.add(graphic);
  //
  //             textSym.setText(item[this.meta.label_field]);
  //             let labelGraphic = new Graphic(geometry, textSym, {globalid: item.globalid});
  //             map.graphics.add(labelGraphic);
  //           });
  //         });
  //
  //         // var json = {
  //         //     "labelExpressionInfo": {"value": "Gen: {unit_identification}"},
  //         //     "useCodedValues": false,
  //         //     "labelPlacement": "above-right"
  //         // };
  //         //
  //         //
  //         // var lc = new LabelClass(json);
  //         // lc.symbol = textSym;
  //         //
  //         // featureLayer.setLabelingInfo([lc]);
  //
  //         // var defExpressions = '';
  //         // angular.forEach(items, function (item, i) {
  //         //     defExpressions += "GlobalID = '" + item.globalid + "'";
  //         //     if (i + 1 !== items.length) {
  //         //         defExpressions += " And ";
  //         //     }
  //         // });
  //         //
  //         // featureLayer.setDefinitionExpression(defExpressions);
  //         // map.addLayer(featureLayer);
  //       });
  //     });
  // }

  getDisplayFields() {
    return this.meta.display_fields.filter(key => {
      return this.meta.fields[key].type !== 'field' || (this.meta.fields[key].type === 'field' &&
        this.meta.fields[key].get_choices !== undefined) ||
      (this.meta.fields[key].type === 'field' &&
        this.meta.fields[key].read_only === true);
    });

  }
}
