import {Component, Inject, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {finalize, tap} from 'rxjs/operators';
import {Observable, ReplaySubject} from 'rxjs';
import {AttachmentModalComponent} from '../attachment-modal/attachment-modal.component';
import {BaseService} from '../../services/base.service';
// import {HelpDocsModalComponent} from '../help-docs-links/help-docs-modal/help-docs-modal.component';
import {GenericModalComponent} from '../../modals/generic-modal/generic-modal.component';

@Component({
  selector: 'app-attachments-base',
  template: ''
})
export class AttachmentsBaseComponent implements OnInit {
  public attachments: any[];
  public attachmentsLoaded = false;
  public loadingMore = false;
  loading = false;
  attachService: BaseService;
  @Input() type: string;
  @Input() foreignKey: string;
  @Input() disableEditing: boolean;
  @Input() useVerboseName: boolean;
  attachIsCollapsed = true;
  meta: ReplaySubject<any> = new ReplaySubject<any>();
  currentPage = 1;

  constructor(public http: HttpClient, public modalService: NgbModal, @Inject('environment') public environment) { }

  ngOnInit() {
    this.attachService = new BaseService(`${this.environment.baseUrl}/${this.type}`, this.http);
    this.attachService.loadMetadata().subscribe(m => this.meta.next(m));
  }

  public getItems() {
    if (!this.loading && !this.attachmentsLoaded) {
      this.loading = true;
      this.attachService.getList({search: this.foreignKey}).pipe(
        tap(response => {
          this.meta.next(response.meta);
          this.attachments = response.results;
          this.attachIsCollapsed = false;
        }),
        finalize(() => {
          this.loading = false;
          this.attachmentsLoaded = true;
        })
      ).subscribe();
    } else {
      this.attachIsCollapsed = !this.attachIsCollapsed;
    }
  }

  public viewImage(attachment) {
    const binary = atob(attachment.base64.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    const file = new Blob([view], {type: attachment.filetype});
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, attachment.filename);
    } else {
      const fileURL = window.URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    }
  }

  public open($event) {
    if (this.attachments === undefined) {
      this.getItems();
    } else if ($event !== undefined) {
      this.attachIsCollapsed = false;
      $event.stopPropagation();
    }

    const modalInstance = this.modalService.open(AttachmentModalComponent);
    modalInstance.componentInstance.attachmentInfo = {type: this.type, foreignKey: this.foreignKey};
    modalInstance.result.then(attachments => {
      if (attachments !== undefined) {
        this.attachments = this.attachments.concat(attachments);
      }
    });
  }

  public delete(item) {
    const modalInstance = this.modalService.open(GenericModalComponent);
    modalInstance.componentInstance.text = 'Are you sure you want to remove <b>' + item.filename + '</b> from this site?';
    modalInstance.componentInstance.title = 'Please confirm';
    modalInstance.result.then(result => {
      this.attachService.delete(item.globalid).subscribe(() => {
        const index = this.attachments.indexOf(item);
        this.attachments.splice(index, 1);
        this.attachIsCollapsed = false;
      });
    });
  }

  public getDownloadUrl(attachment) {
    return `${this.environment.baseUrl}/${this.type}/${attachment.globalid}/download/`;
  }

  loadMore() {
    this.loadingMore = true;
    this.currentPage += 1;
    this.attachService.getList({
      search: this.foreignKey,
      page_size: 25,
      page: this.currentPage
    }).pipe(tap(response => {
        response.results.forEach(item => {
          this.attachments.push(item);
        });
      }),
      finalize(() => {
        this.loadingMore = false;
      })).subscribe();
  }
}
