import {Component, Input, OnInit} from '@angular/core';
import {BaseService} from '../../services/base.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-summary-inline',
  templateUrl: './summary-inline.component.html',
  styleUrls: ['./summary-inline.component.css']
})
export class SummaryInlineComponent implements OnInit {
  @Input() service: BaseService;
  @Input() id: string;
  public show: boolean;

  constructor() { }

  ngOnInit(): void {
    this.service.loadData({search: this.id.replace('{', '').replace('}', '')})
      .pipe(finalize(() => this.show = Object.keys(this.service.item).length > 0)).subscribe();
  }

}
