import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {switchMap, tap} from 'rxjs/operators';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email!: string;
  email_submitted = false;
  access_key!: string;
  permit_id!: string;
  reporting_year!: string;

  constructor(public http: HttpClient, private activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  requestKey() {
    this.http.post(
      `${environment.baseUrl}/applications/auth/request_key/`,
      {email_address: this.email}
    ).pipe(
      tap(() => this.email_submitted = true)
    ).subscribe();
  }

  start() {
    this.activeModal.close({
      number: this.permit_id,
      token: this.access_key,
      email_address: this.email,
      reporting_year: this.reporting_year
    })
  }
}
