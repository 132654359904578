import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {switchMap, tap, map} from 'rxjs/operators';
import ZoomViewModel from '@arcgis/core/widgets/Zoom/ZoomViewModel';
import MapView from '@arcgis/core/views/MapView';
import Map from '@arcgis/core/Map';
import VectorTileLayer from '@arcgis/core/layers/VectorTileLayer';
import TileLayer from '@arcgis/core/layers/TileLayer';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import MapImageLayer from '@arcgis/core/layers/MapImageLayer';
import Graphic from '@arcgis/core/Graphic';
import Point from '@arcgis/core/geometry/Point';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import Fullscreen from '@arcgis/core/widgets/Fullscreen';
import LayerList from '@arcgis/core/widgets/LayerList';
import LabelClass from '@arcgis/core/layers/support/LabelClass';
import RendererProperties = __esri.RendererProperties;
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import TextSymbolProperties = __esri.TextSymbolProperties;
import LabelClassLabelExpressionInfo = __esri.LabelClassLabelExpressionInfo;
import TextSymbol from "@arcgis/core/symbols/TextSymbol";
import {BaseService} from '../../services/base.service';
// import {ContactsService} from '../../../../../walters/src/app/contacts/contacts.service';
// import {GeneratorsService} from '../../../../../walters/src/app/standby-generators/service/generators.service';

@Component({
  selector: 'app-summary-modal',
  templateUrl: './summary-modal.component.html',
  styleUrls: ['./summary-modal.component.css']
})
export class SummaryModalComponent implements OnInit, OnDestroy {
  public generators: any[];
  public navigationDisabled: boolean;
  private navigationListener = [];
  @Input() item: any;
  @Input() items: any[];

  @Output() accept: EventEmitter<any> = new EventEmitter<any>();
  @Output() reject: EventEmitter<any> = new EventEmitter<any>();

  // generatorsService: GeneratorsService;
  // todo: add review modal button
  // review: ReviewModalService;
  siteService: BaseService;
  layersIsCollapsed = true;
  constructor(private http: HttpClient, private modalService: NgbModal, private datePipe: DatePipe,
              private activeModal: NgbActiveModal, @Inject('environment') public environment) {
    // this.generatorsService = new GeneratorsService(http, datePipe, modalService);
    this.siteService = new BaseService(`${this.environment.baseUrl}/sites`, http);
  }


  // item.tonnage = StandbyGeneratorService.item.tonnage;


  // contactService: BaseService;
  // permitService: BaseService;
  // applications: BaseService;
  // organization_display_fields = ['contact_display_name', 'contact_phone_number', 'contact_fax_number',
  //   'contact_address_line_1', 'contact_address_line_2', 'contact_city', 'contact_state',
  //   'contact_zip_code', 'contact_country'];
  // contacts_display_fields = [
  //   'contact_display_name', 'contact_title', 'contact_division_name', 'contact_phone_number',
  //   'contact_alternate_phone', 'contact_email_address', 'contact_address_line_1', 'contact_address_line_2',
  //   'contact_city', 'contact_state', 'contact_zip_code', 'contact_country'
  // ];
  public map: Map;
  public view: MapView;
  public showMap: boolean;
  private zoom: ZoomViewModel;
  public site_response: Observable<any>;
  public map_locked: boolean;

  // $q.all(promises).then(function () {
  //   Restangular.all('standbygenerator/permits').getList({search: $scope.item.globalid}).then(function (response) {
  //     $scope.permit = response[0];
  //     $scope.permit.meta = response.meta;
  //     $scope.permit.meta.fields.responsible_party.choices = $scope.contacts.map(function (item) {
  //       return {display_name: item.first_name + ' ' + item.last_name, value: item.globalid};
  //     });
  //     $scope.permit.meta.fields.permittee.choices = [{
  //       display_name: $scope.organization.name,
  //       value: $scope.organization.globalid
  //     }];
  // $scope.organization = response[0].permittee;
  // $scope.organization.meta = response.meta.fields.permittee.children;
  //   });
  // });
  // $scope.loadingPromise = (StandbyGeneratorService).loadData({search: item.site_id}).then(function() {
  //   angular.extend($scope.item, StandbyGeneratorService.item);
  //   $scope.item.meta = StandbyGeneratorService.meta.fields.permits;
  //   angular.extend($scope.item.tonnage, StandbyGeneratorService.item.tonnage);
  //
  //   Restangular.all(StandbyGeneratorService.meta.fields.permits.type).getList({search: $scope.item.globalid}).then(function(response) {
  //     angular.extend($scope.item, response[0]);
  //     $scope.permits = response[0];
  //   });
  //   Restangular.all(StandbyGeneratorService.meta.fields.insignificant_sources.type).getList({search: $scope.item.globalid}).then(function(response) {
  //     $scope.insignificant_sources = response;
  //   });
  //   Restangular.all(StandbyGeneratorService.meta.fields.generators.type).getList({search: $scope.item.globalid}).then(function(response) {
  //     $scope.generators = response;
  //     $scope.generators.meta.featureService = 'https://gis.epa.guam.gov/arcgis/rest/services/StandbyGeneratorsApplications/FeatureServer/0';
  //     // $scope.permit_display_fields = ApplicationsService.meta.fields.permits.display_fields;
  //     // $scope.review_meta = StandbyGeneratorService.meta.fields.permits.review;
  //     $uibModalInstance.rendered.then(function() {
  //       let map, graphicsLayer, zoom, navigationListener = [], navigationDisabled = true;
  //
  //
  //     });
  //   });
  // });

  ngOnInit() {
    this.site_response = this.siteService.get(this.item.site_id).pipe(
      tap(response => this.loadMap(response.result))
    );
  }

  ngOnDestroy() {
    this.view.destroy();
  }

  toggleMapLock() {
    if (!this.map_locked) {
      this.map_locked = true;
      this.disableMapNavigation();
    } else {
      this.map_locked = false;
      this.enableMapNavigation();
    }
  }

  close() {
    this.activeModal.dismiss();
  }

  get_generator_display_fields(item) {
    let display_fields = [
      'date_of_manufacture', 'unit_identification', 'description', 'useAP42', 'total_unburned_hydrocarbons',
      'toc_units', 'particulate_matter', 'pm_units', 'sulfur_dioxide', 'so2_units',
      'carbon_monoxide', 'co_units', 'nitrogen_oxide', 'no_units'];

    if (item.fuel_type === 'butane' || item.fuel_type === 'propane') {
      display_fields = display_fields.concat([
        'nitrous_oxide', 'nitrous_oxide_units', 'carbon_dioxide', 'carbon_dioxide_units', 'methane', 'methane_units'
      ]);
    }

    display_fields = display_fields.concat(['fuel_type', 'fuel_consumption',
      'tank_type', 'tank_location', 'internal_tank_size', 'external_tank_size', 'brake_horsepower',
      'brake_kilowatts', 'generator_make', 'generator_model', 'generator_serial_number',
      'engine_make', 'engine_model', 'engine_serial_number', 'engine_hours']);

    return display_fields;
  }

  private disableMapNavigation() {
    this.navigationDisabled = true;
    this.navigationListener.push(this.view.on('drag', function(event) {
      event.stopPropagation();
    }));
    this.navigationListener.push(this.view.on('key-down', function(event) {
      const prohibitedKeys = ['+', '-', 'Shift', '_', '='];
      const keyPressed = event.key;
      if (prohibitedKeys.indexOf(keyPressed) !== -1) {
        event.stopPropagation();
      }
    }));
    this.navigationListener.push(this.view.on('double-click', function(event) {
      event.stopPropagation();
    }));
    this.navigationListener.push(this.view.on('mouse-wheel', function(event) {
      event.stopPropagation();
    }));
  }

  private enableMapNavigation() {
    this.navigationDisabled = false;
    this.navigationListener.forEach(listener => {
      listener.remove();
    });
  }

  public zoomIn() {
    this.zoom.zoomIn();
  }

  public zoomOut() {
    this.zoom.zoomOut();
  }

  private loadMap(site) {

      const referencees = new VectorTileLayer({
        url: 'https://www.arcgis.com/sharing/rest/content/items/af6063d6906c4eb589dfe03819610660/resources/styles/root.json',
        listMode: 'hide'
      });

      const nhdLayer = new MapImageLayer({
        url: 'https://hydro.nationalmap.gov/arcgis/rest/services/nhd/MapServer',
        visible: false,
        title: 'National Hydrography Dataset'
      });

      const waltersSites = new MapImageLayer({
        url: 'https://gis.epa.guam.gov/arcgis/rest/services/All_Sites/MapServer',
        visible: false,
        title: 'WALTERS Sites'
      });
      const basemap = new TileLayer({
        url: this.environment.baseMapService,
        listMode: 'hide'
      });
      const siteLayer = new FeatureLayer({url: this.environment.sitesService});
      const graphicsLayer = new GraphicsLayer({
        listMode: 'hide'
      });
      this.map = new Map({
        layers: [
          basemap,
          nhdLayer,
          referencees,
          waltersSites,
          // siteLayer,
          graphicsLayer
        ]
      });
      let gwa;
      if (this.environment.gwaService) {
        gwa = new MapImageLayer({
          url: this.environment.gwaService,
          visible: false
        });
        this.map.layers.add(gwa);
      }
      this.view = new MapView({
        map: this.map,
        container: 'mapDiv',
        ui: {components: []},
        spatialReference: {wkid: 3857}
      });
      this.zoom = new ZoomViewModel({view: this.view});
      const fullscreen = new Fullscreen({view: this.view});
      this.view.ui.add(fullscreen, 'bottom-right');

      siteLayer.load();
      siteLayer.when(() => {
        const symbol = (siteLayer.renderer as SimpleRenderer).symbol;
        // this.site.meta.symbol = siteLayer.renderer.symbol;
        // symbol = waltersSites.renderer.symbol;

        if (site.shape.x !== undefined || site.shape.y !== undefined) {
          const sitePoint = new Graphic();
          const geometry = new Point({x: site.shape.x, y: site.shape.y, spatialReference: new SpatialReference({wkid:3857})});
          sitePoint.geometry = geometry;
          sitePoint.symbol = symbol;
          graphicsLayer.add(sitePoint);
          this.view.goTo({
            target: sitePoint,
            zoom: 17
          });
        }
        this.disableMapNavigation();
        this.showMap = true;
        const el = document.querySelector('.esri-view-surface > canvas');
        el.addEventListener('wheel', (e) => {
          if (this.navigationDisabled) { e.stopPropagation(); }
          if (this.navigationDisabled) { e.stopPropagation(); }
        });
      });

      // const generatorLayer = new FeatureLayer(this.generatorsService.meta.featureService);
      // generatorLayer.load();
      // generatorLayer.when(() => {
      //   let symbol = generatorLayer.renderer.symbol;
      //   this.generatorsService.meta.symbol = symbol;
      //
      //   // todo: fix this
      //   this.generatorsService.forEach(generator => {
      //     if (generator.shape.x !== undefined || generator.shape.y !== undefined) {
      //       const genPoint = new Graphic();
      //       const geometry = new Point(generator.shape.x, generator.shape.y, new SpatialReference(3857));
      //       genPoint.geometry = geometry;
      //       genPoint.symbol = symbol;
      //       graphicsLayer.add(genPoint);
      //     }
      //   });
      // });
      new LayerList({
        view: this.view,
        container: 'layerList',
        listItemCreatedFunction(event) {
          const item = event.item;
          if (item.layer.type !== 'group') {
            // don't show legend twice
            item.panel = {
              content: 'legend',
              open: false
            };
          }
        }
      });

      const parcelLabelSymbol = {
        color: 'white',
        backgroundColor: null,
        borderLineColor: null,
        borderLineSize: null,
        verticalAlignment: 'bottom',
        horizontalAlignment: 'center',
        rightToLeft: false,
        angle: 0,
        xoffset: 0,
        yoffset: 0,
        kerning: true,
        haloColor: 'black',
        haloSize: 1,
        font: {
          family: 'Arial',
          size: 12,
          style: 'normal',
          weight: 'normal',
          decoration: 'none'
        }
      } as TextSymbolProperties;

      const parcelLabel = new LabelClass({
        labelPlacement: 'always-horizontal',
        where: null,
        labelExpressionInfo: {expression: '{Parcel_Search_Field}'},
        useCodedValues: true,
        symbol: new TextSymbol(parcelLabelSymbol),
        minScale: 2500,
        maxScale: 0
      });
      const parcelInfo = new FeatureLayer({
        url: 'http://gis.guam.gov/arcgis/rest/services/EnvPro/EnvPro_LandParcels/MapServer/13',
        visible: false,
        outFields: ['*'],
        labelingInfo: [parcelLabel],
        title: 'Parcels'
      });
      this.map.layers.add(parcelInfo);

      this.map_locked = true;
  }

}
