import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {environment} from '../environments/environment';
import {SharedModule, ErrorHandlingService} from 'shared';
import {ApplicationService} from './services/application.service';
import {FileUploadServiceService} from './services/file-upload-service.service';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {StartComponent} from './start/start.component';
import {NavigationComponent} from './navigation/navigation.component';
import {HomeComponent} from './home/home.component';
import {LoadingComponent} from './modals/loading/loading.component';
import {ResumeComponent} from './modals/resume/resume.component';
import {StandbyApplicationComponent} from './modals/standby-application/standby-application.component';
import {DynamicInputFormComponent} from './dynamic-input-form/dynamic-input-form.component';
import {HeaderPanelComponent} from './header-panel/header-panel.component';
import {LoadApplicationComponent} from './load-application/load-application.component';
import {ReviewComponent} from './review/review.component';
import {ContactDisplayComponent} from './contact-display/contact-display.component';
import {CompleteComponent} from './complete/complete.component';
import { LoginComponent } from './modals/login/login.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    HomeComponent,
    LoadingComponent,
    StartComponent,
    StandbyApplicationComponent,
    DynamicInputFormComponent,
    HeaderPanelComponent,
    LoadApplicationComponent,
    ResumeComponent,
    ReviewComponent,
    ContactDisplayComponent,
    CompleteComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    SharedModule
  ],
  providers: [
    ApplicationService,
    ErrorHandlingService,
    FileUploadServiceService,
    {provide: 'environment', useValue: environment},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
