import {Component, Inject, OnInit} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, ActivatedRouteSnapshot} from "@angular/router";
import {ApplicationService} from "../services/application.service";
import {StandbyApplicationComponent} from "../modals/standby-application/standby-application.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {
  title: string;
  text: string;
  application_type: string;

  constructor(private http: HttpClient, private route: ActivatedRoute, public application: ApplicationService,
              private modalService: NgbModal, ) {
  }

  ngOnInit(): void {
    this.application_type = this.route.snapshot.params.type;
    this.loadHelp(this.route.snapshot.params.type);
  }


  public loadHelp(type) {
    this.http.get<any>(`${environment.baseUrl}/applications/help/${type}/load/`).subscribe((response) => {
      this.title = response.help_label;
      this.text = response.help_text;
    });
  };

  public print() {
    window.print();
  };

  public openModal(component) {
    this.modalService.open(component);
  }

}
