import { Pipe, PipeTransform } from '@angular/core';
import {PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber';
@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {
  phoneUtil = new PhoneNumberUtil();

  transform(value: any, ...args: any[]): any {
    try {
      const phoneNumber = this.phoneUtil.parse(value, 'US')
      return this.phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);
    } catch {
        return value;
    }
  }
}
