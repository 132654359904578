import {Component, Inject, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {BaseService} from '../../services/base.service'
import {DynamicModalComponent} from '../../modals/dynamic-modal/dynamic-modal.component';
import {GenericModalComponent} from '../../modals/generic-modal/generic-modal.component';

@Component({
  selector: 'app-reverse-inline',
  templateUrl: './reverse-inline.component.html',
  styleUrls: ['./reverse-inline.component.css']
})
export class ReverseInlineComponent implements OnInit {
  @Input() foreignKey: string;
  @Input() type: string;
  @Input() displayFields: string[];
  @Input() featureService: string;
  @Input() subItems: any[];
  @Input() foreignKeyField: string;
  @Input() deleteText: string;
  @Input() parentType: string;
  @Input() parentFkField: string;
  @Input() autocomplete: any;

  genericService: BaseService;
  item: any;
  meta: any;
  loading = true;
  isCollapsed = {main: true};

  constructor(private http: HttpClient, private modalService: NgbModal,
              @Inject('environment') private environment) {
  }

  ngOnInit(): void {
    this.genericService = new BaseService(`${this.environment.baseUrl}/${this.type}`, this.http);
    // this.foreignKey = key;
    this.genericService.getList<any>({search: this.foreignKey}).pipe(
      tap(item => {
        this.item = item.results[0];
        this.meta = item.meta;
        this.meta.display_fields = this.displayFields;
        this.meta.type = this.type;
        this.meta.featureService = this.featureService;
        if (this.subItems !== undefined) {
            this.meta.subItems = [];
            this.subItems.forEach(subItem => {
                this.meta.subItems.push(subItem.name);
                this.meta.fields[subItem.name] = subItem;

                // Restangular.one(subItem.type, 'metadata').get().then(function (meta) {
                //     angular.merge(this.meta.fields[subItem.name], meta);
                // });
            });
        }


      }),
      finalize(() => this.loading = false)
    ).subscribe();
  }

  // $rootScope.$watch('allCollapsed', function (newValue) {
  //     angular.forEach(this.IsCollapsed, function (value, key) {
  //         if (!angular.isArray(this.IsCollapsed[key])) {
  //             this.IsCollapsed[key] = !newValue;
  //         } else {
  //             angular.forEach(this.IsCollapsed[key], function (value, index) {
  //                 this.IsCollapsed[key][index] = !newValue;
  //             });
  //         }
  //
  //     });
  // });


  public openReverse(item) {
    if (item === undefined) {
      let item = {};
      item[this.foreignKeyField] = this.foreignKey;
      this.meta.autocomplete = this.autocomplete;
    } else if (item[this.foreignKeyField] === undefined) {
      item[this.foreignKeyField] = this.foreignKey;
    }
    const modalInstance = this.modalService.open(DynamicModalComponent, {size: 'lg', backdrop: 'static'});
    modalInstance.componentInstance.item = item;
    modalInstance.componentInstance.meta = this.meta;
    modalInstance.result.then(i => {
      if (i !== undefined) {
        this.item = i;
      }
    });
  }

  public deleteReverse() {
    const confirmDialog = this.modalService.open(GenericModalComponent);
    confirmDialog.componentInstance.title = 'Please Confirm';
    confirmDialog.componentInstance.text = this.deleteText;
    confirmDialog.result.then(() => {
      const service = new BaseService(`${this.environment.baseUrl}/${this.parentType}`, this.http);
      service.get<any>(this.foreignKey).pipe(switchMap(i => {
        i[this.parentFkField] = null
        this.item = undefined;
        return service.put(i.globalid, i);
      })).subscribe();
    });
  }

//                     this.$watch('meta.subItems', function (newValue, oldValue) {
//                         if (newValue !== oldValue) {
//                             this.open = {};
//                             this.delete = {};
//                             angular.forEach(this.meta.subItems, function (subItem, i) {
//                                 this.open[subItem] = function (foreignKey, item) {
//                                     item[this.meta.fields[subItem].foreign_key_field] = foreignKey;
//                                     var modalInstance = $uibModal.open({
//                                         templateUrl: 'appjs/modals/genericV4.html',
//                                         controller: 'dynamicModal',
//                                         backdrop: 'static',
//                                         size: 'lg',
//                                         resolve: {
//                                             item: function () {
//                                                 return item;
//                                             },
//                                             meta: function () {
//                                                 return this.meta.fields[subItem];
//                                             }
//                                         }
//                                     });
//                                     modalInstance.result.then(function (item) {
//                                         if (item !== undefined) {
//                                             var existingSubItem = $filter('filter')(this.item[subItem], {globalid: item.globalid})[0];
//                                             if (existingSubItem === undefined) {
//                                                 this.item[subItem].push(item);
//                                             }
//                                         }
//                                     });
//                                 };
//
//                                 this.delete[subItem] = function (item) {
//                                     var confirmDialog = dialogs.confirm('Please confirm', this.meta.fields[subItem].deleteText);
//                                     confirmDialog.result.then(function () {
//                                         item = Restangular.restangularizeElement(undefined, item, this.meta.fields[subItem].type);
//                                         item.remove().then(function () {
// //                                            var itemIndex = this.item[subItem].indexOf($filter('filter')(this.items,
// //                                                {globalid: item[this.meta.fields[subItem].foreign_key_field]})[0]);
//                                             var subIndex = this.item[subItem].indexOf(item);
//                                             this.item[subItem].splice(subIndex, 1);
//                                         });
//                                     });
//                                 };
//                             });
//                         }
//                     });
}
