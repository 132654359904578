import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  NgbModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbTimepickerModule,
  NgbTooltipModule,
  NgbTypeaheadModule
} from '@ng-bootstrap/ng-bootstrap';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {EditorModule} from '@tinymce/tinymce-angular';
import {UIRouterModule} from '@uirouter/angular';

// import {SharedComponent} from './shared.component';

import {AttachmentsBaseComponent} from './attachments/attachments-base/attachments-base.component';
import {AttachmentModalComponent} from './attachments/attachment-modal/attachment-modal.component';
import {AttachmentsComponent} from './attachments/attachments.component';
import {DisplayFieldComponent} from './display-field/display-field.component';
import {DynamicInputComponent} from './dynamic-input/dynamic-input.component';
import {EditorComponent} from './editor/editor.component';
import {HelpDocsLinksComponent} from './help-docs-links/help-docs-links.component';
import {MapWithInputComponent} from './map-with-input/map-with-input.component';
import {ResultsListV3Component} from './results-list-v3/results-list-v3.component';
import {ResultsListComponent} from './results-list/results-list.component';
import {SearchComponent} from './search/search.component';
import {StatusInputComponent} from './status-input/status-input.component';
import {SummaryPanelComponent} from './summary-panel/summary-panel.component';
import {TableSortOrderComponent} from './table-sort-order/table-sort-order.component';
import {TableSortOrderV2Component} from './table-sort-order-v2/table-sort-order-v2.component';
import {ToastComponent} from './toast/toast.component';

import {BaseModalComponent} from './modals/base-modal/base-modal.component';
import {DynamicModalComponent} from './modals/dynamic-modal/dynamic-modal.component';
import {ContactsModalComponent} from './modals/contacts-modal/contacts-modal.component';
import {GenericModalComponent} from './modals/generic-modal/generic-modal.component';
import {HelpDocsModalComponent} from './help-docs-links/help-docs-modal/help-docs-modal.component';
import {NotesModalComponent} from './modals/notes-modal/notes-modal.component';
import {NotificationModalComponent} from './modals/notification-modal/notification-modal.component';
import {SummaryModalComponent} from './modals/summary-modal/summary-modal.component';

import {DisplayPipe} from './pipes/display.pipe';
import {FirstPipe} from './pipes/first.pipe';
import {IsArrayPipe} from './pipes/is-array.pipe';
import {IsEmptyPipe} from './pipes/is-empty.pipe';
import {IsImagePipe} from './pipes/is-image.pipe';
import {LimitToPipe} from './pipes/limit-to.pipe';
import {PhoneNumberPipe} from './pipes/phone-number.pipe';
import {ToStringPipe} from './pipes/to-string.pipe';
import {ToTrustedPipe} from './pipes/to-trusted.pipe';

import {ErrorHandlingService} from './services/error-handling.service';
import {GeocodingService} from './services/geocoding.service';
import {HistoryService} from './services/history.service';
import {MapService} from './services/map.service';
import {ParcelService} from './services/parcel.service';
import {PaymentsService} from './services/payments.service';
import {SitesService} from './services/sites.service';
import {ToastService} from './services/toast.service';

import {AttachmentsInlineComponent} from './attachments/attachments-inline/attachments-inline.component';
import {GenericInlineComponent} from './inlines/generic/generic.component';
import {InterestInlineComponent} from './inlines/interest-inline/interest-inline.component';
import {NestedInlineComponent} from './inlines/nested/nested-inline.component';
import {PaymentsInlineComponent} from './inlines/payments-inline/payments-inline.component';
import {ReverseInlineComponent} from './inlines/reverse-inline/reverse-inline.component';
import {SummaryInlineComponent} from './inlines/summary-inline/summary-inline.component';
import {HelpButtonComponent} from './help-button/help-button.component';


@NgModule({
  declarations: [
    // SharedComponent,

    AttachmentsBaseComponent,
    AttachmentModalComponent,
    AttachmentsComponent,
    DisplayFieldComponent,
    DynamicInputComponent,
    EditorComponent,
    HelpButtonComponent,
    HelpDocsLinksComponent,
    MapWithInputComponent,
    ResultsListComponent,
    ResultsListV3Component,
    SearchComponent,
    StatusInputComponent,
    SummaryPanelComponent,
    TableSortOrderComponent,
    TableSortOrderV2Component,
    ToastComponent,

    BaseModalComponent,
    ContactsModalComponent,
    DynamicModalComponent,
    GenericModalComponent,
    HelpDocsModalComponent,
    NotesModalComponent,
    NotificationModalComponent,
    SummaryModalComponent,

    AttachmentsInlineComponent,
    GenericInlineComponent,
    InterestInlineComponent,
    NestedInlineComponent,
    PaymentsInlineComponent,
    ReverseInlineComponent,
    SummaryInlineComponent,

    // RoutingReviewComponent,
    // ReviewButtonsComponent,
    // ReviewDashboardComponent,
    // ReviewQueueComponent,

    // GroupReviewModalComponent,
    // SummaryModalComponent,

    DisplayPipe,
    IsArrayPipe,
    IsImagePipe,
    IsEmptyPipe,
    FirstPipe,
    LimitToPipe,
    PhoneNumberPipe,
    ToStringPipe,
    ToTrustedPipe,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    EditorModule,
    FormsModule,
    InfiniteScrollModule,
    NgbModule,
    NgbTypeaheadModule,
    NgbTooltipModule,
    NgbTimepickerModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
    // StandbyGeneratorsModule,
    UIRouterModule,
    // RoutingReviewModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    AttachmentsBaseComponent,
    AttachmentsComponent,
    DisplayFieldComponent,
    DynamicInputComponent,
    EditorComponent,
    HelpButtonComponent,
    HelpDocsLinksComponent,
    MapWithInputComponent,
    ResultsListComponent,
    ResultsListV3Component,
    SearchComponent,
    StatusInputComponent,
    SummaryPanelComponent,
    TableSortOrderComponent,
    TableSortOrderV2Component,
    ToastComponent,

    AttachmentModalComponent,
    BaseModalComponent,
    ContactsModalComponent,
    DynamicModalComponent,
    GenericModalComponent,
    HelpDocsModalComponent,
    NotesModalComponent,
    NotificationModalComponent,
    SummaryModalComponent,

    AttachmentsInlineComponent,
    GenericInlineComponent,
    InterestInlineComponent,
    NestedInlineComponent,
    PaymentsInlineComponent,
    ReverseInlineComponent,
    SummaryInlineComponent,

    // RoutingReviewComponent,
    // ReviewButtonsComponent,
    // ReviewDashboardComponent,
    // ReviewQueueComponent,

    // GroupReviewModalComponent,
    // SummaryModalComponent,

    DisplayPipe,
    IsArrayPipe,
    IsImagePipe,
    IsEmptyPipe,
    FirstPipe,
    LimitToPipe,
    PhoneNumberPipe,
    ToStringPipe,
    ToTrustedPipe
  ],
  providers: [
    // BaseService,
    ErrorHandlingService,
    GeocodingService,
    HistoryService,
    MapService,
    ParcelService,
    PaymentsService,
    SitesService,
    ToastService,

    // DisplayPipe,
  ]
})
export class SharedModule { }
