import {Injectable} from '@angular/core';
import Point from '@arcgis/core/geometry/Point';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import {ReplaySubject, Subject} from 'rxjs';
import MapView from '@arcgis/core/views/MapView';

@Injectable({providedIn: 'root'})
export class MapService {
  private _graphicsLayer;
  private _map;
  private _view;
  public currentLocation: Subject<any> = new Subject<any>();

  constructor() {}

  public getMap() {
    return this._map;
  }
  public getView() {
    return this._view
  }

  public getGraphicsLayer() {
    return this._graphicsLayer;
  }

  public setMap(map) {
    this._map = map;
  }

  public setView(view) {
    this._view = view;
  }

  public setGraphicsLayer(graphicsLayer) {
    this._graphicsLayer = graphicsLayer;
  }

  public replaceGraphic(globalid, shape) {
    this._graphicsLayer.graphics.map((graphic) => {
      if (graphic.attributes !== undefined && graphic.attributes.globalid === globalid) {
        const geometry = new Point({x: shape.x, y: shape.y, spatialReference: {wkid: 3857}});
        graphic.setGeometry(geometry);
      }
    });
  }

  public setCurrentLocation(geometry) {
    this.currentLocation.next(geometry);
  }

  public zoomToExtent(extent) {
    this.getMap().then((map) => {
      map.setExtent(extent);
    });
  }
}
