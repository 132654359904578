import {Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {BaseService} from '../../services/base.service';
import {ToastService} from '../../services/toast.service';
import {ErrorHandlingService} from '../../services/error-handling.service';
import {HistoryService} from '../../services/history.service';
import {MapService} from '../../services/map.service';
import {HttpClient} from '@angular/common/http';
import {
  catchError,
  finalize,
  tap
} from 'rxjs/operators';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import cloneDeep from 'lodash/cloneDeep';
import VectorTileLayer from '@arcgis/core/layers/VectorTileLayer';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import MapView from '@arcgis/core/views/MapView';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import Point from '@arcgis/core/geometry/Point';
import Map from '@arcgis/core/Map';
import Graphic from '@arcgis/core/Graphic';
import Basemap from '@arcgis/core/Basemap';
import TileLayer from '@arcgis/core/layers/TileLayer';
import {load, project} from '@arcgis/core/geometry/projection';
import ZoomViewModel from '@arcgis/core/widgets/Zoom/ZoomViewModel';
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';
import WebMap from '@arcgis/core/WebMap';

@Component({
  selector: 'app-base-modal',
  template: '',
})
export class BaseModalComponent implements OnInit, OnChanges {
  @Input() item: { [key: string]: any; } = {};
  @Input() meta;
  @Input() disableSave = false;
  dates = {};
  times = {};
  saving = false;
  mapLoading = true;
  displayValues = {};
  genericService: BaseService;
  open = {};
  mapTools: { [key: string]: any; } = {};
  saveCanceled = false;
  loadingAutocompleteChoices = false;
  coordinates: any = {};
  editPoint: Graphic;
  loadingChoices = false;
  map_loading = false;
  choices = {};


  private coordsChanged: boolean;
  public showCoordinateInput: boolean;
  public zoom: ZoomViewModel;

  private featureLayer: FeatureLayer;
  private graphicsLayer = new GraphicsLayer();
  private map: WebMap;
  private view: MapView;

  constructor(private http: HttpClient, private toastService: ToastService, private errorHandling: ErrorHandlingService,
              private activeModal: NgbActiveModal, private mapService: MapService, public history: HistoryService,
              @Inject('environment') private environment) {
  }

  private sketch: SketchViewModel;

  ngOnInit() {
    this.meta = cloneDeep(this.meta);
    this.item = {...this.item};
    this.genericService = new BaseService(`${this.environment.baseUrl}/${this.meta.type}`, this.http);


    if (this.meta.featureService !== undefined) {


      this.featureLayer = new FeatureLayer({
        url: this.meta.featureService
      });

      this.map = new WebMap({portalItem: {id: '4808044fb5404c5f977eb4aad2abc98f'}})
      this.map.when(() => {
        this.map.addMany([
          this.featureLayer,
          this.graphicsLayer
        ])
      });

      this.view = new MapView({
        map: this.map,
        container: 'waltersModalMapDiv',
        ui: {components: []}
      });

      this.zoom = new ZoomViewModel({view: this.view});

      this.view.when(() => {
        this.view.goTo({
          center: [144.8, 13.45],
          zoom: 10
        });
        this.mapService.getGraphicsLayer().graphics.forEach(graphic => {
          if (graphic.attributes === null || graphic.attributes.globalid !== this.item.globalid) {
            const _graphic = Graphic.fromJSON(graphic.toJSON());
            this.graphicsLayer.add(_graphic);
          }
        });
        this.mapLoading = false;
      });

      // var graphics = [];
      // angular.extend(graphics, mainMap.graphics.graphics);

      let drawingEnabled;
      let drawEndConnector;
      this.coordsChanged = false;
      this.sketch = new SketchViewModel({
        view: this.view, layer: this.graphicsLayer,
        updateOnGraphicClick: false,
        defaultUpdateOptions: {
          toggleToolOnClick: false
        }
      });
      this.sketch.on('create', e => {
        this.graphicsLayer.remove(e.graphic);
        const geom = e.graphic.geometry as Point;
        this.addShapeToMap({x: geom.x, y: geom.y})
      });
      this.sketch.on('update', evt => {
        if (evt.state === 'complete') {
          this.sketch.update(this.editPoint, {tool: 'move'});
        }
        if (evt.toolEventInfo !== null && evt.toolEventInfo.type === 'move-stop') {
          this.updateLatLonText(evt.graphics[0].geometry);
        }
      });

//       this.featureLayer.on('edits', evt => {
//         // this.editPoint = evt.graphic;
//
//         if (this.editPoint.geometry !== null) {
//           if (this.editPoint.geometry.type === 'point') {
//             // setPointLatLon(this.editPoint.geometry);
//             // @ts-ignore
//             const x = this.editPoint.geometry.x;
//             // @ts-ignore
//             const y = this.editPoint.geometry.y;
//
//             // var centerX = x - x * .00003;
//             // var centerY = y + y * .00008;
//
//             const centerPoint = new Point({x, y, spatialReference: {wkid: 102100}});
//             this.view.goTo({
//               target: centerPoint,
//               zoom: 17
//             });
//
//             this.updateLatLonText(this.editPoint.geometry);
//             this.sketch.update(this.editPoint, {tool: 'move'});
//           } else if (this.editPoint.geometry.type === 'polyline') {
//             const centerPoint = this.editPoint.geometry.extent.center;
//             this.view.goTo({
//               target: centerPoint,
//               zoom: 15
//             });
//
// //                                    this.editToolbar.activate(Edit.EDIT_VERTICES, this.editPoint);
//           }
//
//         }
//         // else if (this.editPoint.geometry.type === 'point') {
//         //   this.sketch.create('point');
//         //   // drawingEnabled = true;
//         //   drawEndConnector = this.sketch.on('create', this.addToMap);
//         // }
// //                            else if (this.editPoint.geometry.type === 'polyline') {
// //                                drawToolbar.activate(Draw.POLYLINE);
// //                                //drawingEnabled = true;
// //                                drawEndConnector = drawToolbar.on("draw-end", addToMap);
// //                            }
//       });


      // this.editToolbar.on('graphic-move-stop', function (evt) {
      //   updateLatLonText(evt.graphic.geometry);
      // });
      //
      // this.editToolbar.on("vertex-move-stop", function (evt) {
      //   updateLatLonText(evt.graphic.geometry);
      // });

      this.featureLayer.when(evt => {
        this.editPoint = new Graphic();
        if (this.item.globalid !== undefined) {
          if (this.item.shape === undefined) {
            const queryString = 'GlobalID = \'' + this.item.globalid + '\'';
            this.featureLayer.definitionExpression = queryString;
            this.featureLayer.visible = true;
            this.featureLayer.queryExtent().then(e => this.view.extent = e.extent);
          } else if (this.item.graphic !== undefined) {
            this.showCoordinateInput = true;
            const _graphic = new Graphic(this.item.graphic.toJson());
            this.graphicsLayer.add(_graphic);
            this.sketch.update(_graphic, {tool: 'move'});
            this.view.goTo({
              target: this.item.graphic.geometry,
              zoom: 16
            });
            this.updateLatLonText(this.item.graphic.geometry);
          } else if (this.item.shape.x !== undefined || this.item.shape.y !== undefined) {
            this.addShapeToMap(this.item.shape);
          }
        } else if (this.featureLayer.geometryType === 'point') {
          this.showCoordinateInput = true;
          this.sketch.create('point');
          drawingEnabled = true;
          // drawEndConnector = this.sketch.on('create', this.addToMap);
        }
//                            else if (this.featureLayer.geometryType === 'esriGeometryPolyline') {
//                                drawToolbar.activate(Draw.POLYLINE);
//                                drawingEnabled = true;
//                                drawEndConnector = drawToolbar.on("draw-end", addToMap);
//                            }
      });


      // todo: figure out why
      // this.$watch('item.globalid', function (newValue, oldValue) {
      //   if (newValue !== oldValue) {
      //     if (newValue !== undefined) {
      //       const queryString = 'GlobalID = \'' + this.item.globalid + '\'';
      //       this.featureLayer.setDefinitionExpression(queryString);
      //       this.featureLayer.visible = true;
      //     } else {
      //       this.sketch.create('point');
      //       drawingEnabled = true;
      //       drawEndConnector = this.sketch.on('create', this.addToMap);
      //     }
      //   }
      // });


      // });
      // })

      // });
      // }


    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
  }

  // updatePointLocation() {
  //       var fakeDeferred = $q.defer();
  //       fakeDeferred.resolve();
  //       return fakeDeferred.promise;
  // }

  cancel() {
    this.errorHandling.clearError(this.meta);
    // if (this.meta.featureService !== undefined) {
    //   this.saveCanceled = true;
    //   // this.sketch.complete();
    //   this.sketch.destroy();
    //   this.map.destroy();
    // }
    this.history.isVisible = false;
    this.activeModal.dismiss('cancel');
  }

  ok() {
    this.activeModal.close(this.item);
  }

  openCalendar(d, date) {
    d.toggle();
    this.open[date] = true;
  }

  updatePointLocation() {
    return new Promise((resolve, reject) => {
      if (this.coordinates && this.coordinates.latitude && this.coordinates.longitude) {
        const tempPoint = new Point({longitude: this.coordinates.longitude, latitude: this.coordinates.latitude});
        load().then(() => {
          const pt = project(tempPoint, {wkid: 3857}) as Point;
          this.editPoint.geometry = pt;
          this.item.shape = {x: pt.x, y: pt.y};
          resolve();
        });
      } else if (this.item.shape === undefined && Object.keys(this.coordinates).length === 0) {
        resolve();
      } else {
        reject();
      }
    });
  }


  // handle autcomplete lookup
  // itemSelected(event: NgbTypeaheadSelectItemEvent) {
  //   if (event.item !== undefined) {
  //     if (typeof event.item === 'string') {
  //       this.item[this.meta.autocomplete.field] = event.item;
  //     } else {
  //       if (this.item.hasOwnProperty(this.meta.autocomplete.foreign_key) &&
  //       !Array.isArray(this.item[this.meta.autocomplete.foreign_key])) {
  //         if (Array.isArray(event.item[this.meta.autocomplete.foreign_key])) {
  //           event.item[this.meta.autocomplete.foreign_key].push(this.item[this.meta.autocomplete.foreign_key]);
  //         } else {
  //           event.item[this.meta.autocomplete.foreign_key] = this.item[this.meta.autocomplete.foreign_key];
  //         }
  //       }
  //       event.item.forEach((value, key) => {
  //         // if (key === 'display_name') {
  //         //   event.item[key] = event.item[this.meta.autocomplete.field];
  //         // } else {
  //         if (this.meta.fields.hasOwnProperty(key)) {
  //           this.item[key] = value;
  //           if (this.meta.fields[key].type === 'phone_number' && value) {
  //             this.displayValues[key] = this.formatNumber(key);
  //           }
  //         }
  //         // }
  //       });
  //
  //       if (this.meta.featureService !== undefined && this.item.shape !== undefined &&
  //         stringify({x: null, y: null}) === stringify(this.item.shape)) {
  //         this.addShapeToMap(this.item.shape);
  //       }
  //     }
  //   }
  // }


  returnValue(selected) {
    return selected.value;
  }

  // private _applyDateTime(key) {
  //   if (this.meta.fields[key].type === 'datetime') {
  //     if (this.dates[key]) {
  //       const tempDatetime = this.dates[key];
  //       tempDatetime.setHours(this.times[key].hour);
  //       tempDatetime.setMinutes(this.times[key].minute);
  //       this.item[key] = tempDatetime.formattedDate() + 'T' + tempDatetime.formattedTime() + 'Z';
  //     } else if (this.dates[key] === undefined) {
  //       this.toastService.warning('Potential date format error. Please check date inputs and try again.');
  //       // this.saving = false;
  //     }
  //   }
  // }

  submit(addAnother) {
    // clear existing errors
    this.saving = true;
    this.errorHandling.clearError(this.meta);


    // for (const key in this.meta.fields) {
    //   if (this.meta.fields.hasOwnProperty(key)) {
    //     if (this.meta.fields[key].type === 'datetime' && key !== 'created_date' && key !== 'last_edited_date') {
    //       this._applyDateTime(key);
    //     }
    //   }
    // }

    this.item = {...this.item, ...this.meta.additional_fields};

    this.updatePointLocation().then(() => {
      if (this.item.hasOwnProperty('globalid')) {
        this.genericService.put(this.item.globalid, this.item).pipe(
          tap(item => {
            this.history.isVisible = false;
            this.meta.display_fields.forEach(key => {
              this.item[key] = item[key];
            });

            // @ts-ignore
            this.item.last_edited_date = item.last_edited_date;
            // @ts-ignore
            this.item.last_edited_user = item.last_edited_user;

            if (this.sketch !== undefined) {
              this.sketch.complete();
            }
            if (this.meta.autocomplete !== undefined) {
              this.activeModal.close({newItem: this.item, addAnother});
            } else {
              this.activeModal.close(this.item);
            }
            this.errorHandling.clearError(this.meta);
          }),
          catchError(error => this.errorHandling.fieldError(error, this.meta)),
          finalize(() => this.saving = false)).subscribe();
      } else {
        this.genericService.post(this.item).pipe(
          tap(item => {
            if (this.sketch !== undefined) {
              this.sketch.complete();
            }
            this.activeModal.close({newItem: item, addAnother});

            this.errorHandling.clearError(this.meta);
          }),
          catchError(error => this.errorHandling.fieldError(error, this.meta)),
          finalize(() => this.saving = false)).subscribe();
      }
    }).catch(() => {
      this.toastService.warning('Please add location before saving.');
    }).finally(() => {
      this.saving = false;
    });
  }


  addToMap(evt) {
    const symbol = (this.featureLayer.renderer as SimpleRenderer).symbol;

    if (evt.geometry !== undefined) {
      this.editPoint = new Graphic();
      this.editPoint.geometry = evt.geometry;
      this.editPoint.symbol = symbol;
      this.graphicsLayer.add(this.editPoint);
      // this.editPoint = this.featureLayer.graphics[0];
      this.sketch.complete();
      this.updateLatLonText(this.editPoint.geometry);
      if (evt.geometry.type === 'point') {
        this.sketch.update(this.editPoint, {tool: 'move'});
      }
    }
  }

  updateLatLonText(geometry) {
    if (geometry.type === 'point') {

      this.item.shape = {x: geometry.x, y: geometry.y};
      this.coordsChanged = false;
      load().then(() => {
        const pt = project(geometry, {wkid: 4326}) as Point;
        this.coordinates.latitude = pt.y.toFixed(6);
        this.coordinates.longitude = pt.x.toFixed(6);
      });
    }
    // else if (geometry.type === 'polyline') {
    //     this.item.shape = [];
    //     angular.forEach(geometry.paths[0], function (point, i) {
    //         this.item.shape.push({x: point[0], y: point[1]});
    //     });
    //     params.geometries = [geometry];
    //     params.outSR = outSR;
    //     geometryService.project(params, function (projected) {
    //         this.coordinates = {
    //             startlatitude: projected[0].paths[0][0][1].toFixed(6),
    //             startlongitude: projected[0].paths[0][0][0].toFixed(6),
    //             endlatitude: projected[0].paths[0][projected[0].paths[0].length - 1][1].toFixed(6),
    //             endlongitude: projected[0].paths[0][projected[0].paths[0].length - 1][0].toFixed(6)
    //         };
    //     });
    // }
  }

  addShapeToMap(shape) {
    this.showCoordinateInput = true;
    const symbol = (this.featureLayer.renderer as SimpleRenderer).symbol;
    const geometry = {x: shape.x, y: shape.y, spatialReference: {wkid: 3857}, type: 'point'} as Point;
    this.editPoint.geometry = geometry;
    this.editPoint.symbol = symbol;
    this.graphicsLayer.add(this.editPoint);
    // this.graphicsLayer.graphics = [this.editPoint];
    this.sketch.update(this.editPoint, {tool: 'move'});
    this.view.goTo({
      target: this.editPoint,
      zoom: 16
    });
    this.updateLatLonText(geometry);
    this.sketch.complete();
  }

  zoomIn() {
    this.zoom.zoomIn();
  }

  zoomOut() {
    this.zoom.zoomOut();
  }

  // autocompleteSearch = (text$: Observable<string>) => text$.pipe(
  //   tap(() => this.loadingAutocompleteChoices = true),
  //   debounceTime(300),
  //   distinctUntilChanged(),
  //   switchMap(term => this.genericService.getList({search: term})),
  //   map(response => response.results),
  //   finalize(() => this.loadingAutocompleteChoices = false)
  // )


  resetMeta(meta, key) {
    this.meta[key] = cloneDeep(meta);
  }

}
