import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {StartComponent} from "./start/start.component";
import {DynamicInputFormComponent} from "./dynamic-input-form/dynamic-input-form.component";
import {LoadApplicationComponent} from "./load-application/load-application.component";
import {ReviewComponent} from './review/review.component';
import {CompleteComponent} from './complete/complete.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'start/:type', component: StartComponent},
  {path: 'application/:type', component: LoadApplicationComponent, children: [
      {path: 'review', component: ReviewComponent},
      {path: 'complete', component: CompleteComponent},
      {path: ':route', component: DynamicInputFormComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
