import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {BaseService} from '../services/base.service';
import {StateService} from '@uirouter/core';
import {finalize, tap} from 'rxjs/operators';
import {merge} from 'lodash';

@Component({
  selector: 'app-results-list',
  templateUrl: './results-list.component.html',
  styleUrls: ['./results-list.component.css']
})
export class ResultsListComponent implements OnInit, OnChanges {
  @Input() displayFields: string[]
  @Input() service: BaseService;
  @Input() searchObj: any;
  @Output() searchObjChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() metaCountChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() loadingResults: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() rowButtons: any[] = [];
  @Input() rowIcons: any[] = [];
  @Input() state: string
  @Input() disableLink: boolean;
  @Input() items: any[];
  @Output() itemsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input() showLink = true;
  @Input() inputType: string;
  // @Input() type: string;
  @Input() disableSort: string[];
  @Input() columnIcons: any = {};
  @Input() getExtraStyling: (item) => any = () => {};
  public filters = [];
  public currentPage = 1;
  public loading = false;
  public filteredDisplayFields: string[];
  public isAllSelected = false;
  public match: any;

  constructor(public $state: StateService) {
  }

  ngOnInit(): void {
    // this.searchObj = this.$state.params;
    this._search();
    this.service.loadMetadata().subscribe(() => {
      this.filteredDisplayFields = this.displayFields.filter(f => this.service.meta.fields[f] !== undefined);
    })
  }

  // orderingChange(event) {
  //     this.searchObj.ordering = event;
  // }
  ngOnChanges(changes: SimpleChanges) {

    // this.searchObjChange.emit(this.searchObj);
    // if (changes.searchObj.currentValue !== changes.searchObj.previousValue) {
    //   this.search();
    // }
    // need transitionTo here for search param v
    this.$state.transitionTo(this.$state.current.name, this.searchObj, {notify: false, reload: false});
  }

  public search() {
    this.$state.transitionTo(this.$state.current.name, this.searchObj, {notify: false, reload: false});
  }

  public _search() {
    // merge(this.searchObj, this.$state.params);
    this.loading = true;
    this.loadingResults.emit(this.loading);
    // this.searchObj.ordering = this.filters.join(',');
    this.service.getList(this.searchObj).pipe(
      tap(response => {
        this.items = response.results;
        this.itemsChange.emit(this.items);
        this.metaCountChange.emit(response.meta.count);
        this.currentPage = response.meta.page;
        merge(this.service.meta, response.meta);
      }),
      finalize(() => {
        this.loading = false
        this.loadingResults.emit(this.loading);
      })
    ).subscribe();
  }

  public switchPage(pageId) {
    this.searchObj.page = pageId;
    this.search();
  }

  public selectAll() {
    const toggleStatus = this.isAllSelected;
    this.items.forEach(site => {
      site.selected = toggleStatus;
    });
  };

  // todo: refactor list actions into more generic
  // public selectionChange() {
  //   var selectedSites = $filter('filter')(this.items, {selected: true});
  //   var siteNames = [];
  //   angular.forEach(selectedSites, function (site) {
  //     siteNames.push(site.primary_name);
  //   });
  //
  //   if (this.actionSelected.value === 'delete') {
  //     if (selectedSites.length <= 0) {
  //       toastr.warning("You must select at least one site to delete.");
  //     } else {
  //       var confirmDialog = dialogs.confirm('Please confirm', 'Are you sure you want to remove <b>' + siteNames.join(',') + '</b> from this site?', 'sm');
  //       confirmDialog.result.then(function () {
  //         angular.forEach(selectedSites, function (site) {
  //           site.remove().then(function () {
  //             var index = this.items.indexOf(site);
  //             this.items.splice(index, 1);
  //           });
  //         });
  //       });
  //     }
  //   } else if (this.actionSelected.value === 'merge') {
  //     if (selectedSites.length <= 1) {
  //       toastr.warning("You must select at least two sites to merge.");
  //     } else {
  //       var mergeDialog = dialogs.create('/static/dialogs/merge.html', 'mergeDialogCtrl', selectedSites, 'lg');
  //     }
  //   }
  //   this.actionSelected = {value: 'default', label: 'Actions'};
  // };
  public hideExpressionEval(item, expression: string) {
    // tslint:disable-next-line:no-eval
    return eval(expression);
    //return Function(`"use strict"; return ${expression}`);
  }

}
